import { OverlayTrigger, Tooltip } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import { compose, setDisplayName } from 'recompose';
import withHandlers from 'recompose/withHandlers';

import Title from '../Title';
import Styles from './Heat.module.scss';
import ScorePicker from './ScorePicker';
import SubDimensionCell from './SubDimensionCell';

const HeatRangeMap = {
  'range-1': Styles.Heat1,
  'range-2': Styles.Heat2,
  'range-3': Styles.Heat3,
  'range-4': Styles.Heat4,
};

export const heatRange = (heat) => {
  if (!heat || heat === 0) return 'range-1';
  else if (heat <= 0.25) return 'range-2';
  else if (heat <= 0.5) return 'range-3';
  else return 'range-4';
};

const heatmapClass = (range) => {
  // When there are no evaluations we return heat1 by default
  return HeatRangeMap[range] || Styles.Heat1;
};

const Footer = ({ score, labelClassName, ...rest }) => {
  return (
    <tfoot>
      <tr>
        <td style={{ position: 'relative' }}>
          <label className={labelClassName}>
            {rest.isQualitativeMode ? 'My Rating' : 'My score'}
          </label>
        </td>
        <td colSpan={4}>
          <ScorePicker value={score} {...rest} />
        </td>
      </tr>
    </tfoot>
  );
};

const enhance = compose(
  setDisplayName('Criterion'),
  withHandlers({
    handleClick: (props) => () => {
      props.onChangeCriteria && props.onChangeCriteria(props.subdimensionId, props.criteriaId);
    },
  }),
);

const Criterion = enhance((props) => {
  const { text, handleClick, className, heatCount, isHeatmap } = props;
  let hitsText = '';
  if (isHeatmap) {
    hitsText = `${heatCount} click`;
    if (heatCount !== 1) hitsText += 's';
  }

  const td = (
    <td className={className} onClick={handleClick} dangerouslySetInnerHTML={{ __html: text }} />
  );

  return isHeatmap ? (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          <strong>{hitsText}</strong>
        </Tooltip>
      }
    >
      {td}
    </OverlayTrigger>
  ) : (
    td
  );
});
const RubricDimension = ({
  dimension,
  grades,
  coloredBackground,
  clickedCriteria,
  onChangeCriteria,
  onChangeScore,
  score,
  validScores,
  heatmap,
  showHeatmap,
  selectedByTeam,
  heatCount,
  readOnly,
  isQualitativeMode,
  qualitativeMethodology,
}) => {
  const name = dimension.get('name');
  const subDimensions = dimension.get('subdimensions');
  const showFooter = !showHeatmap;
  return (
    <div
      data-test-id={`dimension-container-${dimension.get('id')}`}
      className={cx({
        [Styles.dimension]: true,
        [Styles.colored]: coloredBackground,
        [Styles.heatmap]: showHeatmap,
      })}
    >
      <Title component="h2" className={Styles.title}>
        {name}
      </Title>
      <table>
        <thead>
          <tr className={Styles.columns}>
            <th />
            {grades.map((grade) => (
              <th key={grade.get('id')} className={cx(Styles.grade, Styles.innerCell)}>
                {grade.get('name')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {subDimensions.map((subD) => {
            const subdimensionId = subD.get('id');

            // Debounced by subdimension
            const debouncedOnChangeCriteria = onChangeCriteria
              ? debounce(onChangeCriteria, 300, {
                  leading: false,
                  trailing: true,
                })
              : onChangeCriteria;

            return (
              <tr className={Styles.trClass} key={`subD-${subdimensionId}`}>
                <SubDimensionCell appPrompt={subD.get('appPrompt')} name={subD.get('name')} />
                {subD.get('criteria').map((criterion) => {
                  const heat = heatmap && heatmap(criterion.get('id'));
                  const hits = heatCount && heatCount(criterion.get('id'));
                  const isSelectedByTeam =
                    selectedByTeam &&
                    selectedByTeam(dimension.get('id'), subdimensionId, criterion.get('id'));
                  return (
                    <Criterion
                      key={criterion.get('id')}
                      onChangeCriteria={debouncedOnChangeCriteria}
                      criteriaId={criterion.get('id')}
                      subdimensionId={subdimensionId}
                      text={criterion.get('description')}
                      heatCount={hits}
                      isHeatmap={showHeatmap}
                      className={cx(
                        {
                          [Styles.innerCell]: true,
                          [Styles['is-selected-by-team']]: isSelectedByTeam,
                          [Styles['read-only']]: readOnly,
                          [Styles.selected]:
                            clickedCriteria && clickedCriteria.includes(criterion.get('id')),
                        },
                        heatmap && heatmapClass(heatRange(heat)),
                        Styles.criteria,
                        Styles['criteria-color'],
                      )}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {showFooter && (
          <Footer
            score={score}
            grades={grades}
            validScores={validScores}
            onChange={onChangeScore}
            labelClassName={Styles.scoreTitle}
            readOnly={readOnly}
            isQualitativeMode={isQualitativeMode}
            qualitativeMethodology={qualitativeMethodology}
          />
        )}
      </table>
    </div>
  );
};

RubricDimension.displayName = 'RubricDimension';
export default RubricDimension;
