import { Button } from '@valid-eval/shared-react-components';
import { useEffect, useRef } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { error as showError } from 'data/actions/notifications';
import { getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import AssignmentsAutoMatchModal from './AssignmentsAutoMatchModal';
import AssignmentsClearMatchesModal from './AssignmentsClearMatchesModal';
import AssignmentsPanelMatchModal from './AssignmentsPanelMatchModal';

type OwnProps = {
  reload(): void;
};

const connector = connect(
  (state, ownProps) => ({ phase: getSelectedPhase(state, ownProps)?.toJS?.() }),
  {
    showError,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type TeamsAssigmentsToolbarProps = PropsFromRedux & OwnProps;

const TeamsAssigmentsToolbar = ({ reload, phase }: TeamsAssigmentsToolbarProps) => {
  const [showClearModal, openClearModal, closeClearModal] = useBooleanFlag(false);
  const [showAutoMatchModal, openAutoMatchModal, closeAutoMatchModal] = useBooleanFlag(false);
  const [showPanelMatchModal, openPanelMatchModal, closePanelMatchModal] = useBooleanFlag(false);
  const wasAutomatchRunning = useRef(false);

  const isPanelMatching = phase?.matching_mode === 'panel_matching';

  // Reload the table if automatch was running and completed
  useEffect(() => {
    if (wasAutomatchRunning.current && !phase?.automatchRunning) reload();
    wasAutomatchRunning.current = phase?.automatchRunning;
  }, [phase?.automatchRunning]);

  return (
    <>
      <AssignmentsAutoMatchModal show={showAutoMatchModal} close={closeAutoMatchModal} />
      <AssignmentsClearMatchesModal show={showClearModal} close={closeClearModal} reload={reload} />
      <AssignmentsPanelMatchModal
        show={showPanelMatchModal}
        close={closePanelMatchModal}
        reload={reload}
      />
      <div className="text-end flex-grow-1">
        <Button
          variant="link-danger me-2"
          onClick={openClearModal}
          disabled={phase?.automatchRunning}
          id="clear-all-matches"
        >
          Clear All Matches
        </Button>

        <Button
          variant="primary"
          onClick={isPanelMatching ? openPanelMatchModal : openAutoMatchModal}
          disabled={phase?.automatchRunning}
          id={isPanelMatching ? 'panel-match' : 'auto-match'}
        >
          {phase?.automatchRunning && <i className="fa-duotone fa-spinner fa-spin" />}
          {isPanelMatching ? 'Panel Match' : 'Auto-Match'}
        </Button>
      </div>
    </>
  );
};

export default withRouter(connector(TeamsAssigmentsToolbar));
