import { Button } from '@valid-eval/shared-react-components';
import { useTranslation } from 'react-i18next';

import { DisabledStep } from './signUpWizardSteps';
import { Step } from './types';

type SignUpWizardTitleProps = {
  activeStep: Step;
  onNextStep: () => void;
  onPrevStep: () => void;
  hasNextStep: boolean;
  hasPrevStep: boolean;
};

const SignUpWizardTitle = ({
  activeStep,
  onNextStep,
  onPrevStep,
  hasNextStep,
  hasPrevStep,
}: SignUpWizardTitleProps) => {
  const { t } = useTranslation();

  return activeStep.key === DisabledStep.key ? null : (
    <>
      <div className="d-flex justify-content-between mb-2">
        <div>
          <h1 className="mb-0">{t(activeStep.title)}</h1>
          {!!activeStep.info?.stateText && (
            <small className="fw-normal">({activeStep.info?.stateText})</small>
          )}
        </div>
        {activeStep.navigation && (
          <div>
            <Button
              variant="link"
              className="py-2 px-3"
              disabled={!hasPrevStep}
              onClick={onPrevStep}
            >
              <span className="fa-regular fa-lg fa-arrow-left m-0" />
            </Button>
            <Button
              variant="link"
              className="py-2 px-3"
              disabled={!hasNextStep}
              onClick={onNextStep}
            >
              <span className="fa-regular fa-lg fa-arrow-right m-0" />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default SignUpWizardTitle;
