import { combineActions, handleActions } from 'redux-actions';

import { actions } from 'data/actions/notes';
import { createSelector } from 'reselect';
import { getRawIntros } from 'data/reducers/intros';
import { useSelector } from 'react-redux';

// State and reducers
const initialState = {
  saving: false,
  loading: false,
  viewed: true,
  notes: [],
  notesByTeam: {},
};

const reducers = {
  [actions.NOTES_RESET]: () => ({ ...initialState }),
  [actions.LOAD_NOTES_PENDING]: (state) => ({ ...state, loading: true }),
  [actions.LOAD_NOTES_REJECTED]: (state) => ({ ...state, loading: false }),
  [actions.LOAD_NOTES_FULFILLED]: (state, { payload, meta }) => {
    return {
      ...state,
      loading: false,
      notes: payload.notes,
      notesByTeam: {
        ...state.notesByTeam,
        [meta.teamId]: payload.notes,
      },
      viewed: payload.viewed,
    };
  },
  [combineActions(
    actions.CREATE_NOTE_PENDING,
    actions.UPDATE_NOTE_PENDING,
    actions.DELETE_NOTE_PENDING,
    actions.VOTE_NOTE_PENDING,
  )]: (state) => ({
    ...state,
    saving: true,
  }),
  [combineActions(
    actions.CREATE_NOTE_REJECTED,
    actions.UPDATE_NOTE_REJECTED,
    actions.DELETE_NOTE_REJECTED,
    actions.VOTE_NOTE_REJECTED,
  )]: (state) => ({
    ...state,
    saving: false,
  }),
  [actions.CREATE_NOTE_FULFILLED]: (state, { payload }) => ({
    ...state,
    saving: false,
    notes: [payload.note, ...state.notes],
  }),
  [combineActions(actions.UPDATE_NOTE_FULFILLED, actions.VOTE_NOTE_FULFILLED)]: (
    state,
    { payload },
  ) => ({
    ...state,
    saving: false,
    notes: state.notes.map((note) => (note.id === payload.note.id ? payload.note : note)),
  }),
  [actions.DELETE_NOTE_FULFILLED]: (state, { payload }) => {
    const note = state.notes.find((note) => note.id === payload.id);
    if (!note) return state;

    const teamNotes = state.notesByTeam[note.teamId].filter((note) => note.id !== payload.id);
    return {
      ...state,
      saving: false,
      notes: state.notes.filter((note) => note.id !== payload.id),
      notesByTeam: { ...state.notesByTeam, [note.teamId]: teamNotes },
    };
  },
  'events/EXPORT_PDF_START': (state, action) => {
    return action?.payload?.includes('redacted')
      ? {
          ...state,
          viewed: true,
        }
      : state;
  },
};

const _sortByVotes = (a, b) => {
  const aNetVotes = a.netVotes || 0;
  const bNetVotes = b.netVotes || 0;

  const netVotesDifference = bNetVotes - aNetVotes;
  const dateDifference = b.updatedAt - a.updatedAt;

  // THEN SORT BY updated_at AND netVotes
  return netVotesDifference === 0 ? dateDifference : netVotesDifference;
};

const noteIsInternal = (n) => n.type === 'internal';
const noteIsSharedByJudge = (n) => n.type === 'shared' && !n.writerIsOrganizer;
const noteIsSharedByorganizer = (n) => n.type === 'shared' && n.writerIsOrganizer;

const sortNotes = (notes, intros, { judgeId }) => {
  const internal = notes.filter(noteIsInternal).sort(_sortByVotes);
  const sharedByOrganizers = notes.filter(noteIsSharedByorganizer).sort(_sortByVotes);
  let sharedByJudges = notes.filter(noteIsSharedByJudge).sort(_sortByVotes);
  intros = intros.sort(_sortByVotes);

  let writers = {},
    index = 1;
  sharedByJudges = sharedByJudges.map((note) => {
    if (!writers[note.writerId]) {
      writers[note.writerId] = index;
      index++;
    }
    return { ...note, writerDisplay: `Judge #${writers[note.writerId]}` };
  });

  let records = [...internal, ...sharedByOrganizers, ...sharedByJudges, ...intros];
  if (judgeId) records = records.filter((n) => n.writerId === judgeId);
  return records;
};

// Selectors
export const getNotesState = (state) => state.get('notes');
export const getLoading = createSelector(getNotesState, (state) => state.loading);
export const getSaving = createSelector(getNotesState, (state) => state.saving);
export const getViewed = createSelector(getNotesState, (state) => state.viewed);
export const getRawNotes = createSelector(getNotesState, (state) => state.notes);
export const getRawNotesByTeam = createSelector(
  getNotesState,
  (_, teamId) => teamId,
  (state, teamId) => state.notesByTeam[teamId] || [],
);

export const getNotesSorted = createSelector(
  getRawNotes,
  getRawIntros,
  (_, { judgeId, isTeam }) => ({ judgeId, isTeam }),
  sortNotes,
);

export const getNotesByTeamSorted = createSelector(
  getRawNotesByTeam,
  () => [], // No intros
  () => ({ judgeId: null, isTeam: false }), // No judgeId
  sortNotes,
);

export const getFirstNoteInternal = createSelector(getNotesSorted, (notes) =>
  notes.find((note) => note.type === 'internal'),
);

export const getNotesWithFirstInternal = createSelector(
  getNotesSorted,
  getFirstNoteInternal,
  (notes, firstNoteInternal) =>
    notes.map((note) => ({ ...note, isFirstInternal: note.id === firstNoteInternal?.id })),
);

// Selectors hooks
export const useLoading = () => useSelector(getLoading);
export const useSaving = () => useSelector(getSaving);
export const useNotes = ({ judgeId, isTeam }) =>
  useSelector((state) => getNotesWithFirstInternal(state, { judgeId, isTeam }));
export const useViewed = () => useSelector(getViewed);

export default handleActions(reducers, initialState);
