import { Button } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import NoteDeleteControl from './NoteDeleteControl';

import { Toggle } from 'components/FormFields';
import { isGovEnv } from 'config';
import { useSaving } from 'data/reducers/notes';
import { isNavigator } from 'utils';
import Styles from './Notes.module.scss';

const NoteFormControls = ({
  id,
  phaseId,
  teamId,
  classes,
  valid = false,
  edit = false,
  onCancel,
  isOrganizer,
  judgeId,
  viewed,
  canDelete,
}) => {
  const saving = useSaving();
  const showToggle = !viewed && !isNavigator();
  return (
    <div className={Styles.controls}>
      {edit && canDelete && (
        <div>
          <NoteDeleteControl
            disabled={saving}
            id={id}
            phaseId={phaseId}
            teamId={teamId}
            isOrganizer={isOrganizer}
            judgeId={judgeId}
          />
        </div>
      )}
      <div
        className={cx(
          'text-end d-flex align-items-center justify-content-end',
          Styles.toggleContainer,
        )}
      >
        {showToggle && (
          <>
            <span className={Styles.toggleLabel}>Feedback to applicant</span>
            <Toggle name="internal" lg />
          </>
        )}

        <span className={Styles.toggleLabel}>
          {isGovEnv() ? 'Government-only comment' : 'Selection committee comment'}
        </span>
      </div>
      <div>
        {edit && onCancel && (
          <Button variant="link" onClick={onCancel} className="ps-4 pe-4">
            Cancel
          </Button>
        )}
        <Button variant="success" type={'submit'} disabled={saving} className="ps-4 pe-4">
          {edit ? 'Save' : 'Add a Comment'}
        </Button>
      </div>
    </div>
  );
};

NoteFormControls.displayName = 'NoteFormControls';
export default NoteFormControls;
