import { ReactElement, cloneElement } from 'react';

import FileInput from 'components/Form/FileInput';
import { ArtifactItem } from 'data/features/artifactItemsTypes';
import { Artifact } from 'data/features/eventsTypes';

import Input from './Input';
import { url } from '../FormFields/validations';

const ArtifactsFields = ({
  artifactItems,
  artifacts,
  disabled,
  fieldWrapper = <></>,
  onDelete,
  onFinishUpload,
  onNoSession,
  signingUrlQueryParams,
}: {
  artifactItems: Record<number, ArtifactItem>;
  artifacts: Artifact[];
  disabled?: boolean;
  fieldWrapper: ReactElement;
  onDelete(artifactItem: ArtifactItem): void;
  onFinishUpload(name: string, url: string): void;
  onNoSession(): void;
  signingUrlQueryParams: Record<string, any>;
}) => {
  return artifacts.map((artifact) => {
    if ((artifact.type !== 'url' && artifact.type !== 'file') || !artifact.active) return null;

    const artifactItem = artifactItems?.[artifact.id];

    const component =
      artifact.type === 'url' ? (
        <Input
          name={String(artifact.id)}
          label={artifact.teams_and_organizers_name}
          helpText={artifact.instructions || ''}
          rules={{ validate: { url } }}
          required={artifact.required}
          disabled={disabled}
        />
      ) : (
        <FileInput
          value={artifactItem}
          acceptedFileType={artifact?.file_type || ''}
          name={String(artifact.id)}
          label={artifact.teams_and_organizers_name}
          helpText={artifact.instructions || ''}
          onDelete={onDelete}
          onFinishUpload={onFinishUpload}
          onNoSession={onNoSession}
          signingUrlQueryParams={signingUrlQueryParams}
          required={artifact.required}
          disabled={disabled}
        />
      );

    return cloneElement(fieldWrapper, { key: artifact.id, children: component });
  });
};

export default ArtifactsFields;
