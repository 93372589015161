import { Button, Spinner } from '@valid-eval/shared-react-components';
import GenericModal from 'components/GenericModal';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';
import useSaveTeamDraft from './WizardSteps/useSaveTeamDraft';
import { TeamDraft } from 'data/features/teamDraftTypes';
import { Event } from 'data/features/eventsTypes';
import { useNavigate } from 'react-router';
import { teamNav } from 'utils/urls';
import { useTranslation } from 'react-i18next';
import { load } from 'data/actions/users';
import { ConnectedProps, connect } from 'react-redux';

type SignUpSubmitButtonProps = {
  progress: Record<string, any>;
  draft?: TeamDraft;
  event?: Event;
  onNoSession: () => void;
  saving: boolean;
  savingArtifactItems: boolean;
  isTeamMemberEditor?: boolean;
};

const mapDispatchToProps = { load };

const connector = connect(null, mapDispatchToProps);

const SignUpSubmitButton = ({
  draft,
  event,
  progress,
  onNoSession,
  saving,
  savingArtifactItems,
  load,
  isTeamMemberEditor,
}: SignUpSubmitButtonProps & ConnectedProps<typeof connector>) => {
  const { t } = useTranslation();
  const [showConfirmModal, openConfirmModal, closeConfirmModal] = useBooleanFlag();
  const [loading, startLoading, finishLoading] = useBooleanFlag();
  const navigate = useNavigate();
  const save = useSaveTeamDraft({ eventId: event?.id || '', onNoSession });

  const handleConfirm = () => {
    if (!draft || !event) return;

    startLoading();
    save({ id: draft.id, name: draft.name }, false, async (team) => {
      if (!team) return finishLoading();

      await load('me');
      closeConfirmModal();
      finishLoading();
      navigate(teamNav.confirmation(event.id, team.id));
    });
  };

  return isTeamMemberEditor ? (
    <>
      <GenericModal
        body={
          <div
            dangerouslySetInnerHTML={{
              __html: t('auth.teams_sign_up.confirm_submit', { eventName: event?.name }),
            }}
          />
        }
        name={'signup-confirmation'}
        onCancel={closeConfirmModal}
        onConfirm={handleConfirm}
        show={showConfirmModal}
        size="md"
        title={t('common.confirmation')}
        isButtonDisabled={saving || loading}
        isCancelButtonDisabled={saving || loading}
      />
      <div className="d-grid mt-4 mb-2">
        <Button
          id={'submit-application'}
          onClick={openConfirmModal}
          disabled={progress.percentage < 100 || saving || savingArtifactItems}
        >
          {(saving || savingArtifactItems) && (
            <Spinner animation="border" size="sm" className="me-2" />
          )}
          {t('auth.teams_sign_up.submit')}
        </Button>
      </div>
    </>
  ) : null;
};

export default connector(SignUpSubmitButton);
