import { isNavigator, isTeamLead } from 'utils';

const getRoleSegment = () => {
  if (isNavigator()) return 'navigator/';
  if (isTeamLead()) return 'team_lead/';
  return '';
};

export const organizerNav = {
  status: (eventId, role) => {
    const roleSegment = role ? `${role}/` : getRoleSegment();
    return `/app/${roleSegment}events/${eventId}/status`;
  },
  settings: (eventId) => `/app/${getRoleSegment()}events/${eventId}/settings`,
  reports: (eventId) => `/app/${getRoleSegment()}events/${eventId}/reports`,
  help: (eventId, releaseNotes) =>
    `/app/${getRoleSegment()}events/${eventId}/help${releaseNotes ? '?release-notes' : ''}`,
  results: (eventId, role) => {
    const roleSegment = role ? `${role}/` : getRoleSegment();
    return `/app/${roleSegment}events/${eventId}/results`;
  },
  judgeInvitations: (eventId) => `/app/${getRoleSegment()}events/${eventId}/judges/invite`,
  judgeAssignments: (eventId) => `/app/${getRoleSegment()}events/${eventId}/judges/assign`,
  judgeManage: (eventId) => `/app/${getRoleSegment()}events/${eventId}/judges/manage`,
  teamAssignments: (eventId) => `/app/${getRoleSegment()}events/${eventId}/teams`,
  teamsAdd: (eventId, add = false) =>
    `/app/${getRoleSegment()}events/${eventId}/teams/add${add ? '?showAddModal=true' : ''}`,
  teamManage: (eventId) => `/app/${getRoleSegment()}events/${eventId}/teams/manage`,
  teamDraft: (eventId) => `/app/${getRoleSegment()}events/${eventId}/teams/draft`,
  score: (eventId, evalId, scoreId) =>
    `/app/${getRoleSegment()}events/${eventId}/results/${evalId}/score/${scoreId}?unredacted=1`,
  scores: (eventId, evalId) =>
    `/app/${getRoleSegment()}events/${eventId}/results/${evalId}?unredacted=1`,
  heatmap: (eventId, evalId) =>
    `/app/${getRoleSegment()}events/${eventId}/results/${evalId}/heatmap?unredacted=1`,
  profile: (eventId, role, roleId) =>
    `/app/${getRoleSegment()}events/${eventId}/${role}/${roleId}/profile`,
  members: (eventId, role, roleId) =>
    `/app/${getRoleSegment()}events/${eventId}/${role}/${roleId}/members`,
  editAssignments: (eventId, role, roleId) =>
    `/app/${getRoleSegment()}events/${eventId}/${role}/${roleId}/assignments`,
  printScoresAndHeatmap: (
    eventId,
    evalId,
    isUnredacted,
    displayDetails,
    isJudgePanel,
    isSinglePDF,
  ) => {
    const query = new URLSearchParams();
    if (isUnredacted) query.append('unredacted', '1');
    if (isJudgePanel) query.append('judge_panel', '1');
    if (isSinglePDF) query.append('single_pdf', '1');
    if (displayDetails) query.append('display_details', '1');

    return `/app/${getRoleSegment()}events/${eventId}/results/${evalId}/print?${query.toString()}`;
  },
  compareTeams: (eventId, teamIds, isExpanded) =>
    `/app/${getRoleSegment()}events/${eventId}/results/${teamIds}/compare?unredacted=1&isExpanded=${isExpanded}`,
  rubric: (eventId, phaseId) => `/app/rubrics/events/${eventId}/phases/${phaseId}`,
  teamProfile: (eventId, teamId) =>
    `/app/${getRoleSegment()}events/${eventId}/teams/${teamId}/profile`,
};

export const teamNav = {
  results: (eventId, teamId) => `/app/events/${eventId}/teams/${teamId}/results`,
  selfEvaluation: (eventId, teamId) => `/app/events/${eventId}/teams/${teamId}/self_evaluation`,
  profile: (eventId, teamId) => `/app/events/${eventId}/teams/${teamId}/my_profile`,
  members: (eventId, teamId) => `/app/events/${eventId}/teams/${teamId}/my_profile/members`,
  help: (eventId, teamId) => `/app/events/${eventId}/teams/${teamId}/help`,
  score: (eventId, teamId, scoreId) =>
    `/app/events/${eventId}/teams/${teamId}/results/score/${scoreId}`,
  scores: (eventId, teamId) => `/app/events/${eventId}/teams/${teamId}/results/`,
  heatmap: (eventId, teamId) => `/app/events/${eventId}/teams/${teamId}/results/heatmap`,
  confirmation: (eventId, teamId) => `/app/events/${eventId}/teams/${teamId}/confirmation`,
  printScoresAndHeatmap: (eventId, teamId, evalId) =>
    `/app/events/${eventId}/teams/${teamId}/results/${evalId}/print`,
  survey: (eventId, phaseId, teamId) =>
    `/survey/events/${eventId}/phases/${phaseId}/roles/${teamId}/type/team`,
};

export const judgeNav = {
  scoring: (eventId, judgeId, scoreId) =>
    `/app/events/${eventId}/judges/${judgeId}/scores/${scoreId}`,
  evaluations: (eventId, judgeId) => `/app/events/${eventId}/judges/${judgeId}/evaluations`,
  confirmation: (eventId, judgeId) => `/app/events/${eventId}/judges/${judgeId}/confirmation`,
  profile: (eventId, judgeId) => `/app/events/${eventId}/judges/${judgeId}/my_profile`,
  help: (eventId, judgeId) => `/app/events/${eventId}/judges/${judgeId}/help`,
  survey: (eventId, phaseId, judgeId) =>
    `/survey/events/${eventId}/phases/${phaseId}/roles/${judgeId}/type/judge`,
};

export const signInURL = (params) => (params ? `/?${params}` : `/`);
export const judgeConfirmationURL = (eventId) => `/app/events/${eventId}/judges/confirmation`;
export const existingJudgeSignUpURL = (notification_id, eventId, email, invitation_id) =>
  `/app/events/${eventId}/judges/signup?email=${email}&another_profile=true&invitation_id=${invitation_id}&notification_id=${notification_id}`;
export const signUpConfirmationURL = (eventId, teamId) =>
  `/app/events/${eventId}/teams/confirmation`;
export const teamSignUp = (slug) => `/teams/${slug}/signup`;
export const existingTeamSignUp = (slug) => `/teams/${slug}/signup?returning_user=1`;
export const alreadyRegisteredTeamContactURL = (slug) =>
  `/?alreadyExistingTeamContact=1&slug=${slug}`;

export const dashboardUrlFromUserRole = (role, event) => {
  const eventId = role.event_id;
  const roleId = role.role_specific_id;
  switch (role.name) {
    case 'Judge':
      return judgeNav.evaluations(eventId, roleId);
    case 'Team':
      return teamNav.results(eventId, roleId);
    case 'TeamDraft':
      return teamSignUp(event.get('slug'));
    case 'Organizer':
      return organizerNav.status(eventId);
    case 'Navigator':
      return organizerNav.status(eventId, 'navigator');
    case 'TeamLead':
      return organizerNav.status(eventId, 'team_lead');
    default:
      return '/';
  }
};

export const showRolesAsDropdown = (roles) => {
  // if there are more than 2 roles, show as dropdown
  if (roles.length > 2) return true;
  // if any role name is too long, show as dropdown
  if (roles.some((r) => r.role_specific_name.length > 44)) return true;

  // If there are only 2 roles and not all are Team or TeamDraft, show as dropdown
  const areAllTeamOrDraft = roles.every((r) => r.name === 'Team');
  return roles.length === 2 && !areAllTeamOrDraft;
};

export function getEventIdOrSlugFromPathname(pathname) {
  let eventIdOrSlug;
  if (pathname.includes('/events/'))
    [, eventIdOrSlug] = pathname.match(/events\/([^\s/]+)\//) || [];
  if (pathname.includes('/teams/') && pathname.includes('/signup'))
    [, eventIdOrSlug] = pathname.match(/teams\/([^\s/]+)\//) || [];
  return eventIdOrSlug;
}
