import { Tab, TabsGroup } from '@valid-eval/shared-react-components';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { initialize, reset } from 'redux-form/immutable';

import { load as loadEvent, save as saveEvent } from 'data/actions/events';
import { loadForEvent as loadPhases, update as savePhase } from 'data/actions/phases';
import { getEvent, getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import { organizerNav } from 'utils/urls';

import GlobalSettingsForm from './components/Settings/GlobalSettingsForm';
import PhaseSettingsForm from './components/Settings/PhaseSettingsForm';
import TeamFeedbackAvailableModal from './components/Settings/TeamFeedbackAvailableModal';

type SettingsProps = {
  event: Record<string, any>;
  eventId: string;
  loadEvent: (eventId: string) => Promise<void>;
  loadPhases: (eventId: string) => Promise<void>;
  phase: Record<string, any>;
  saveEvent: (eventId: string, values: any) => Promise<void>;
  savePhase: (phaseId: string, values: any) => Promise<void>;
  navigate: (url: string) => void;
  reset: (formName: string) => void;
  initialize: (formName: string, values: any) => void;
};

const Settings = ({
  event,
  eventId,
  loadEvent,
  loadPhases,
  phase,
  saveEvent,
  savePhase,
  navigate,
  reset,
  initialize,
}: SettingsProps) => {
  const [showFeedbackAvailableModal, setShowFeedbackAvailableModal] = useState(false);
  const [newPhase, setNewPhase] = useState<any | null>(null);

  // On mount load event and phases
  useEffect(() => {
    loadEvent(eventId);
    loadPhases(eventId);
  }, []);

  useEffect(() => {
    if (phase?.id) initialize('phaseSettingsForm', phase);
  }, [phase?.id]);

  // Handle submit global settings
  function handleSubmitGlobalSettings(values: any) {
    saveEvent(eventId, values);
  }

  // Handle submit phase settings
  function handleSubmitPhaseSettings(values: any) {
    const newPhase = values.toJS();
    const feedbackEnabled = newPhase.feedback_enabled;
    const prevFeedbackEnabled = phase.feedback_enabled;

    if (feedbackEnabled && !prevFeedbackEnabled) {
      setNewPhase(newPhase);
      setShowFeedbackAvailableModal(true);
    } else {
      savePhase(newPhase.id, newPhase);
    }
  }

  // Handle confirm feedback available modal
  async function handleConfirmFeedbackAvailableModal(sendNotification: boolean) {
    await savePhase(newPhase.id, newPhase);
    setShowFeedbackAvailableModal(false);
    setNewPhase(null);
    if (sendNotification) navigate(organizerNav.teamManage(eventId));
  }

  // Handle cancel feedback available modal
  function handleCancelFeedbackAvailableModal() {
    reset('phaseSettingsForm');
    setShowFeedbackAvailableModal(false);
    setNewPhase(null);
  }

  return (
    <>
      <TabsGroup className="mb-4" mountOnEnter unmountOnExit>
        <Tab eventKey="global" title="Global Settings">
          {event && (
            <GlobalSettingsForm initialValues={event} onSubmit={handleSubmitGlobalSettings} />
          )}
        </Tab>
        <Tab eventKey="phase" title="Phase Settings">
          {phase && (
            <PhaseSettingsForm initialValues={phase} onSubmit={handleSubmitPhaseSettings} />
          )}
        </Tab>
      </TabsGroup>
      <TeamFeedbackAvailableModal
        show={showFeedbackAvailableModal}
        onConfirm={handleConfirmFeedbackAvailableModal}
        onCancel={handleCancelFeedbackAvailableModal}
      />
    </>
  );
};

export default compose(
  withRouter,
  connect(
    (state, props: { params: { event_id: string } }) => {
      const eventId = props.params.event_id;
      const event = getEvent(state, eventId)?.toJS();
      const phase = getSelectedPhase(state, props)?.toJS();

      return {
        eventId,
        event,
        phase,
      };
    },
    {
      initialize,
      loadEvent,
      loadPhases,
      reset,
      saveEvent,
      savePhase,
    },
  ),
)(Settings);
