import GenericModal from 'components/GenericModal';
import { ArtifactItem } from 'data/features/artifactItemsTypes';
import { useTranslation } from 'react-i18next';

type ConfirmDeleteArtifactItemModalProps = {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  artifactItem: ArtifactItem | null;
};

const ConfirmDeleteArtifactItemModal = ({
  show,
  onClose,
  onConfirm,
  artifactItem,
}: ConfirmDeleteArtifactItemModalProps) => {
  const { t } = useTranslation();

  return (
    <GenericModal
      show={show}
      onCancel={onClose}
      onConfirm={onConfirm}
      name="confirm-delete-artifact-item"
      title={t('auth.teams_sign_up.delete_artifact_item_title')}
      body={t('auth.teams_sign_up.delete_artifact_item_body', { name: artifactItem?.file_name })}
      size="md"
      centered
    />
  );
};

export default ConfirmDeleteArtifactItemModal;
