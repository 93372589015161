import EvaluationQuartile, { formatJudges } from './EvaluationQuartile';
import { useEffect } from 'react';

import EvaluationDetails from './EvaluationDetails';
import IndividualEvaluation from './IndividualEvaluation';
import Styles from './Scores.module.scss';
import cx from 'classnames';
import { spinnerWhileLoading } from '../Loading';
import { useEvaluationViewed } from 'data/actions/notes';

const evaluationQuartile = (evaluation, phaseSummary) => {
  const overallScore = evaluation.overall_score;
  const maxIn2nd = phaseSummary.get('max_score_in_second_quartile');
  const maxIn3rd = phaseSummary.get('max_score_in_third_quartile');
  const maxIn4th = phaseSummary.get('max_score_in_fourth_quartile');

  if (overallScore > maxIn2nd) return 1;
  else if (overallScore > maxIn3rd) return 2;
  else if (overallScore > maxIn4th) return 3;
  else return 4;
};

const Scores = (props) => {
  let {
    rubric,
    evaluation,
    selfScore,
    phaseSummary,
    selectedScore,
    isTeam,
    isOrganizer,
    phasePerformanceEnabled,
    displayDetails,
  } = props;

  evaluation = evaluation?.toJSON?.();

  const showIndividualEvaluation = !!selectedScore;
  const title = showIndividualEvaluation ? "Individual judge's evaluation" : rubric.get('name');
  const evaluationViewed = useEvaluationViewed();

  useEffect(() => {
    if (isTeam) evaluationViewed(evaluation.id);
  }, [isTeam]);

  return (
    <article>
      <header className={'d-flex justify-content-between flex-wrap'}>
        <div className={cx(Styles.TitleWrapper, 'mt-4 mb-4')}>
          <h4 className={cx(Styles.Title, 'd-print-none')}>{title}</h4>
        </div>
        {!isTeam || (isTeam && phasePerformanceEnabled) ? (
          <div className={cx('d-flex mt-4 mb-4 justify-content-end', Styles.Stats)}>
            <div className="me-5">
              {(isOrganizer || displayDetails) && (
                <>
                  Valid Rank&trade;:{' '}
                  <span className={cx(Styles.StatValue)}>{evaluation?.valid_score_valid_rank}</span>
                </>
              )}
            </div>
            <div className="d-flex flex-column me-5">
              {(isOrganizer || displayDetails) && (
                <div className="d-flex flex-row align-items-center">
                  <div
                    style={{
                      width: 40,
                    }}
                    className="me-2 text-end"
                  >
                    <span className={cx(Styles.StatValue)}>
                      {evaluation?.valid_score_valid_z?.toFixed?.(2) || 'N/A'}
                    </span>{' '}
                  </div>
                  <div>Valid Sigmas&trade;</div>
                </div>
              )}
              {(isOrganizer || displayDetails) && (
                <div className="d-flex flex-row align-items-center">
                  <div
                    style={{
                      width: 40,
                    }}
                    className="me-2 text-end"
                  >
                    <span className={cx(Styles.StatValueSmall)}>
                      {evaluation?.valid_score?.toFixed?.(2)}
                    </span>{' '}
                  </div>
                  <div>Valid Score&trade;</div>
                </div>
              )}
            </div>
            <EvaluationQuartile
              judges={evaluation.judges_count}
              quartile={evaluationQuartile(evaluation, phaseSummary)}
            />
          </div>
        ) : (
          <p className={Styles.judgeCount}>{formatJudges(evaluation.judges_count)}</p>
        )}
      </header>
      {showIndividualEvaluation ? (
        <IndividualEvaluation rubric={rubric} selectedScore={selectedScore} />
      ) : (
        <EvaluationDetails
          isTeam={isTeam}
          rubric={rubric}
          evaluation={evaluation}
          selfScore={selfScore}
          phaseSummary={phaseSummary}
        />
      )}
    </article>
  );
};

Scores.displayName = 'Scores';

export default spinnerWhileLoading(
  (props) => props.rubric && props.evaluation && props.phaseSummary,
)(Scores);
