import Loading from 'components/Loading';
import RubricDimension from 'components/RubricDimension/ReadOnly';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { getIsLoadingRubric, getRubric } from 'data/reducers';
import { Component } from 'react';
import { connect } from 'react-redux';

class ShowReadOnly extends Component {
  render() {
    const { loading, rubric } = this.props;
    if (loading) return <Loading />;

    return (
      <>
        <h1>{rubric.get('name')}</h1>
        <RubricDimension rubric={rubric} />
      </>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const { selectedPhaseId } = ownProps;

    const isLoadingRubric = getIsLoadingRubric(state);
    const rubric = selectedPhaseId && getRubric(state, selectedPhaseId); // TODO: use memoized selector

    return {
      loading: !rubric || isLoadingRubric,
      rubric,
    };
  },
  {
    notifyError,
    notifySuccess,
  },
)(ShowReadOnly);
