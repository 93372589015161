import { apiHeaders, apiURL } from '../services/config';

export const toggleSeen = (id) => ({
  method: 'PUT',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/notifications/${id}/toggle_seen`),
});

export const markAllAsSeen = (id) => ({
  method: 'PUT',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/users/${id}/mark_notifications_as_seen`),
});
