import { Col, ListGroup, Row } from '@valid-eval/shared-react-components';
import { ConnectedProps, connect } from 'react-redux';

import { createAsyncReport } from 'data/actions/reports';
import { getEvent, getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import PDFReports from './PDFReports';
import ReportsListItem from './ReportsListItem';
import { isTeamLead } from 'utils';

type OwnProps = {
  params: {
    event_id: string;
  };
};

const connector = connect(
  (state: any, ownProps: OwnProps) => ({
    eventId: ownProps.params.event_id,
    event: getEvent(state, ownProps.params.event_id)?.toJS?.(),
    phase: getSelectedPhase(state, ownProps)?.toJS?.(),
  }),
  {
    createAsyncReport,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PhaseReportsProps = PropsFromRedux & OwnProps;

const PhaseReports = ({ createAsyncReport, eventId, event, phase }: PhaseReportsProps) => {
  const isPanelMatching = phase?.matching_mode === 'panel_matching';
  const isQualitativeOnlyMode = event.judging_ux_mode === 'qualitative_only_mode';

  function handleClickReport(reportName: string) {
    createAsyncReport(eventId, reportName, phase.id);
  }

  return (
    <Row>
      <Col md={6}>
        <h2>Decision-Support for Each Phase</h2>
        {!isTeamLead() && (
          <ListGroup className="mb-3">
            <ReportsListItem
              reportName="consensus_meeting"
              title="Consensus"
              description="Debate and reach consensus using relevant information across
              multiple analysis tabs that facilitate the process."
              onClick={handleClickReport}
            />
          </ListGroup>
        )}

        <ListGroup className="mb-3">
          <ReportsListItem
            reportName="matching_status"
            title="Detailed Matching Status"
            description="Manage the details of the evaluation process with this list of matches
              between Judges and Teams and the scoring progress for each."
            onClick={handleClickReport}
          />
          <ReportsListItem
            reportName="team_analysis"
            title="Team Analysis"
            description="Decide who advances with a detailed breakdown of stack-ranked Evaluations."
            onClick={handleClickReport}
            disabled={isQualitativeOnlyMode && isTeamLead()}
          />
          <ReportsListItem
            reportName="judge_analysis"
            title="Judge Analysis"
            description="Measure the strengths and weaknesses of your judges."
            onClick={handleClickReport}
            disabled={isQualitativeOnlyMode && isTeamLead()}
          />
          <ReportsListItem
            reportName="comments_phase"
            title="Comments"
            description="Comments (to both the Teams and the Selection Committee) from judges."
            onClick={handleClickReport}
          />
          <ReportsListItem
            reportName="final_results"
            title="Final Results"
            description="Final results for this Phase (only relevant after Consensus Meeting)."
            onClick={handleClickReport}
            disabled={isQualitativeOnlyMode && isTeamLead()}
          />
          <ReportsListItem
            reportName="screening_round_analysis"
            title="Screening Round Analysis"
            description="This details specific problems with applications during screening phases."
            onClick={handleClickReport}
          />
          {isPanelMatching && (
            <ReportsListItem
              reportName="panel_matcher"
              title="Panel Matcher"
              description="Panel matching template."
              onClick={handleClickReport}
            />
          )}
          {isQualitativeOnlyMode && (
            <ReportsListItem
              reportName="qualitative_results"
              title="Qualitative results"
              description="Qualitative results for this Phase."
              onClick={handleClickReport}
            />
          )}
        </ListGroup>
      </Col>
      <Col md={6}>
        <PDFReports />
      </Col>
    </Row>
  );
};

export default withRouter(connector(PhaseReports));
