import moment from 'moment-timezone';
import tlds from 'tlds';
import isUrl from 'validator/lib/isURL';

import { DATE_FORMAT, isDate } from 'utils';

export const tldExists = (value) =>
  tlds.concat('test', 'example').includes(value?.split('.').pop()?.toLowerCase());

/* eslint-disable */
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable */

export const required = (value) => {
  if (value === '0' || value === 0) return undefined; // Valid 0 as number
  if (!value || value === 'required') return 'Required';

  const isEmptyArray = Array.isArray(value) && value.length === 0;
  const isEmptyImmutableObject = value && value.isEmpty && value.isEmpty();

  return isEmptyArray || isEmptyImmutableObject ? 'Required' : undefined;
};

export const cleanRichText = (text) => {
  text = text?.replace(/<(.*?)>/gi, '');
  text = text?.replace(/&nbsp;/gi, '');
  text = text?.replace(/\s/gi, '');
  text = text?.trim();
  return text;
};

export const requiredRichText = (text) => {
  text = cleanRichText(text);
  return Boolean(text) ? undefined : 'Required';
};

export const maxLength = (max) => (value) => {
  return value && value.length > max ? `Must be ${max} characters or less` : undefined;
};

export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minLengthTrim = (min) => (value) =>
  value && value.trim?.().length < min ? `Must be ${min} characters or more` : undefined;

export const NUMBER = /^(\d{1,3}(,\d{3})*(\.\d+)?|\.\d+)$/;
export const number = (value) =>
  value && !(NUMBER.test(value) || !isNaN(Number(value))) ? 'Must be a number' : undefined;

export const wholeNumber = (value) => {
  value = value?.replace?.(/,/g, '') || value;
  return value && (isNaN(Number(value)) || Number(value) < 0 || !Number.isInteger(Number(value)))
    ? 'Must be a whole number'
    : undefined;
};

export const minValue = (min) => (value) =>
  value !== undefined && value !== null && value < min ? `Must be at least ${min}` : undefined;

export const maxValue = (max) => (value) =>
  value && value > max ? `Must be at most ${max}` : undefined;

export const email = (value) =>
  value && (!EMAIL_REGEX.test(value) || !tldExists(value)) ? 'Invalid email address' : undefined;

export const url = (value) => (value && !isUrl(value) ? 'Invalid url' : undefined);

export const date = (value) => {
  return value && !isDate(value?.substr?.(0, 10) || value) ? 'Invalid date' : undefined;
};

export const passwordStrength = (value) => {
  // contain numbers, special characters and lowercase and upercase letters
  const REGEX = /((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W)(.){8,})/;

  if (value && !value.match(REGEX)) {
    return 'Your password must contain at least eight characters and include at least one uppercase character, at least one lowercase character, at least one numeric character, and at least one special character such as !@#$%^&*';
  } else {
    return undefined;
  }
};

const ZIP_CODE = /^\d{5}(?:[-\s]\d{4})?$/;
export const zipCode = (value) => (value && !ZIP_CODE.test(value) ? 'Invalid zip code' : undefined);

const EIN_TAX_ID = /(^(\d{3})-?\d{2}-?\d{4}$)|(^(\d{2})-?\d{7}$)/i;
export const taxId = (value) => (value && !EIN_TAX_ID.test(value) ? 'Invalid Tax ID' : undefined);

export const sameAs = (fieldName, fieldLabel) => (value, all) =>
  value !== all[fieldName] ? `Must match ${fieldLabel || fieldName} value` : undefined;

const EMAIL_ALIAS_REGEX = /^(\w|\s){3,100}$/;

export const emailAlias = (fromMail) => (value) =>
  !EMAIL_ALIAS_REGEX.test(value?.replace(fromMail, '') || '')
    ? 'Must contain a minimum of at least three characters; only letters, numbers and spaces are allowed'
    : undefined;

const NOT_ALLOWED_CHARS_NAME =
  '_!¡?÷?¿/\\\\+=@#$%\\^ˆ¬&*(){}|<>;:[\\]·©"\x00-\x08\x0B\x0C\x0D-\x1F\x7F';
const NOT_ALLOWED_CHARS_TEXT = '\x00-\x08\x0B\x0C\x0E-\x1F\x7F';

const NAME_REGEX = new RegExp(`^[^${NOT_ALLOWED_CHARS_NAME}]{0,40}$`);
const TEXT_REGEX = new RegExp(`^[^${NOT_ALLOWED_CHARS_TEXT}]*$`);

export const name = (value) =>
  !NAME_REGEX.test(value)
    ? 'Names may contain a maximum of forty characters; most special characters are not allowed'
    : undefined;

export const text = (value) =>
  !TEXT_REGEX.test(value) ? 'Special characters are not allowed' : undefined;

export const sanitizeName = (value) => {
  return String(value)
    .replace(/^\s/, '')
    .replace(/\s\s+/g, ' ')
    .replace(new RegExp(`[${NOT_ALLOWED_CHARS_NAME}]`, 'g'), '');
};

export const sanitizeText = (value) => {
  return String(value)
    .replace(/^\s/, '')
    .replace(/\s\s+/g, ' ')
    .replace(new RegExp(`[${NOT_ALLOWED_CHARS_TEXT}]`, 'g'), '');
};

export const sanitizeLongText = (value) => {
  return String(value).replace(new RegExp(`[${NOT_ALLOWED_CHARS_TEXT}]`, 'g'), '');
};

export const sanitizeNumber = (value) => {
  return String(value).replace(/[^\d]/g, '');
};

export const sanitizeCommaSeparatedNumber = (value) => {
  return String(value).replace(/[^\d,]/g, '');
};

export const sanitizePipeSeparatedNumber = (value) => {
  return String(value).replace(/[^\d|]/g, '');
};

export const dateIsAfter = (date) => (value) =>
  value && date && moment(value).isBefore(moment(date))
    ? `Must be after ${date.format(DATE_FORMAT)}`
    : undefined;

export const momentDate = (value) =>
  value && (!moment.isMoment(value) || !value.isValid()) ? 'Invalid date' : undefined;

export const byType = {
  url,
  email,
  number,
  date,
  zip_code: zipCode,
  tax_id: taxId,
};
