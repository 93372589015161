import { load as loadEvaluation, loadForPhase as loadEvaluations } from 'data/actions/evaluations';

import { loadNotes } from 'data/actions/notes';
import { load as loadPhaseSummary } from 'data/actions/phase_summaries';
import { load as loadRubric } from 'data/actions/rubrics';
import { loadForEvaluation as loadScores } from 'data/actions/scores';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const usePhaseData = (phase) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (phase?.id) {
      dispatch(loadEvaluations(phase.id));
      dispatch(loadRubric(phase.id));
      dispatch(loadPhaseSummary(phase.phase_summary_id));
    }
  }, [phase?.id]);
};

export const useTeamData = (team, phase) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (team?.id && !team.judge_scores) {
      dispatch(loadEvaluation(team.id));
      dispatch(loadScores(team.id));
      dispatch(loadNotes(team.team, phase.id, null, true));
    }
  }, [team?.id, phase?.id]);
};
