import { Form } from '@valid-eval/shared-react-components';
import { Field } from 'redux-form/immutable';

import ToggleButton from 'components/Settings/ToggleButton';

type SettingsToggleFieldProps = {
  label: string;
  name: string;
  onLabel?: string | JSX.Element;
  offLabel?: string | JSX.Element;
};

const SettingsToggleField = ({ label, name, onLabel, offLabel }: SettingsToggleFieldProps) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <label>
          <b>{label}</b>
        </label>
        <Field id={name} name={name} component={ToggleButton} />
      </div>
      {(Boolean(onLabel) || Boolean(offLabel)) && (
        <Form.Text className="text-muted">
          {Boolean(onLabel) && onLabel}
          {Boolean(onLabel) && <br />}
          {Boolean(offLabel) && offLabel}
        </Form.Text>
      )}
    </>
  );
};

export default SettingsToggleField;
