import { isRejectedWithValue } from '@reduxjs/toolkit';
import { Button } from '@valid-eval/shared-react-components';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import GenericModal from 'components/GenericModal';
import * as notify from 'data/actions/notifications';
import {
  cancelApplicationRevisionRequest,
  createApplicationRevisionRequest,
  fromApplicationRevisionRequests,
} from 'data/features/applicationRevisionRequests';
import { ApplicationRevisionRequest } from 'data/features/applicationRevisionRequestsTypes';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import ApplicationRevisionRequestForm, {
  FormValues,
  entitiesToOptions,
} from './ApplicationRevisionRequestForm';

type OwnProps = {
  applicationRevisionRequest?: ApplicationRevisionRequest;
  artifacts: any[];
  close(): void;
  fields: any[];
  onSaved?(): void;
  show: boolean;
  teamIds: string[];
  onNext(submitApplicationRevisionRequest: () => Promise<void>): void;
};

const mapStateToProps = (state: any) => ({
  loading: fromApplicationRevisionRequests.getIsLoadingApplicationRevisionRequest(state.toJS()),
});

const mapDispatchToProps = {
  cancelApplicationRevisionRequest,
  createApplicationRevisionRequest,
  showError: notify.error,
  showSuccess: notify.success,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ApplicationRevisionRequestModalProps = ConnectedProps<typeof connector> & OwnProps;

function ApplicationRevisionRequestModal({
  applicationRevisionRequest,
  artifacts,
  cancelApplicationRevisionRequest,
  close,
  createApplicationRevisionRequest,
  fields,
  loading,
  onSaved,
  show,
  showError,
  showSuccess,
  teamIds,
  onNext,
}: ApplicationRevisionRequestModalProps) {
  const { t } = useTranslation();
  const [showCancelModal, openCancelModal, closeCancelModal] = useBooleanFlag(false);
  const artifactOptions = entitiesToOptions(artifacts);
  const fieldOptions = entitiesToOptions(fields, 'id', 'title');
  const formMethods = useForm<FormValues>({
    defaultValues: {
      artifacts: artifactOptions,
      deadline: null,
      fields: fieldOptions,
    },
  });

  // Reset form on show modal
  useEffect(() => {
    const formValues: FormValues = {
      artifacts: artifactOptions,
      deadline: null,
      fields: fieldOptions,
    };

    if (show && applicationRevisionRequest) {
      const artifactIds = applicationRevisionRequest.artifact_ids || [];
      const fieldKeys = applicationRevisionRequest.field_keys || [];
      const deadline = applicationRevisionRequest.deadline || null;
      formValues.artifacts = artifactOptions.filter((a) => artifactIds.includes(+a.value));
      formValues.deadline = deadline ? moment(deadline) : null;
      formValues.fields = fieldOptions.filter((f) => fieldKeys.includes(f.value));
      formMethods.reset(formValues);
    }
  }, [show, applicationRevisionRequest]);

  const handleRequestApplicationRevision = async (data: FormValues) => {
    onNext(async () => {
      const deadline =
        typeof data.deadline === 'string'
          ? moment(data.deadline, 'MM/DD/YYYY hh:mm A')
          : data.deadline;
      const result = await createApplicationRevisionRequest({
        teamIds: teamIds,
        deadline: moment.isMoment(deadline) ? deadline.toISOString() : null,
        fieldKeys: data.fields.map((field) => field.value),
        artifactIds: data.artifacts.map((artifact) => artifact.value),
      });

      if (isRejectedWithValue(result)) {
        const payload: any = result.payload;
        showError(payload?.response.message || t('application_revision_request.error.create'));
        return;
      }
      showSuccess(t('application_revision_request.success.create'));
      onSaved?.();
    });
    close();
  };

  const triggerRequestApplicationRevision = () =>
    formMethods.handleSubmit(handleRequestApplicationRevision)();

  const handleCancelClick = () => {
    openCancelModal();
    close();
  };

  const handleCancelApplicationRevisionRequest = async () => {
    if (applicationRevisionRequest) {
      try {
        const result = await cancelApplicationRevisionRequest({
          teamId: teamIds[0] || '',
          id: applicationRevisionRequest?.id,
        });

        if (isRejectedWithValue(result)) {
          const payload: any = result.payload;
          showError(payload?.response.message || t('application_revision_request.error.cancel'));
          return;
        }

        showSuccess(t('application_revision_request.success.cancel'));
      } finally {
        closeCancelModal();
      }
    }
  };

  return (
    <>
      <GenericModal
        size="md"
        body={t('application_revision_request.cancel_body')}
        name="application-revision-request-cancel"
        onCancel={closeCancelModal}
        onConfirm={handleCancelApplicationRevisionRequest}
        show={showCancelModal}
        title={t('application_revision_request.cancel_body')}
      />

      <GenericModal
        body={
          <FormProvider {...formMethods}>
            <ApplicationRevisionRequestForm
              onSubmit={handleRequestApplicationRevision}
              artifactOptions={artifactOptions}
              fieldOptions={fieldOptions}
            />
          </FormProvider>
        }
        fullscreen={'lg-down'}
        name={'application-revision-request'}
        onCancel={close}
        onConfirm={triggerRequestApplicationRevision}
        show={show}
        size={'xl'}
        title={t(
          applicationRevisionRequest
            ? 'application_revision_request.request_edit_title'
            : 'application_revision_request.request_title',
        )}
        confirmButton={t(
          applicationRevisionRequest
            ? 'application_revision_request.request_edit_confirm'
            : 'application_revision_request.request_confirm',
        )}
        isButtonDisabled={loading}
        isCancelButtonDisabled={loading}
        footerExtraContent={
          <>
            {!!applicationRevisionRequest && (
              <div className="flex-grow-1">
                <Button
                  variant="link-danger"
                  onClick={handleCancelClick}
                  disabled={loading}
                  id="application-revision-request-modal-cancel-request"
                >
                  <span className="fas fa-trash-can ms-2" />
                  &nbsp;
                  {t('application_revision_request.button_cancel')}
                </Button>
              </div>
            )}
          </>
        }
      />
    </>
  );
}

export default connector(ApplicationRevisionRequestModal);
