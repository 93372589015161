import { Button, Modal } from '@valid-eval/shared-react-components';

const NoteEditConfirmation = ({ show, onConfirmation, onCancel }) => {
  return (
    <Modal id={'signup-modal-confirmation'} show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Editing this comment will erase all existing votes on this comment.
          <br />
          <br />
          Please confirm you are OK with erasing these votes.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" id={'signup-modal-confirmation-cancel'} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="success"
          id={'signup-modal-confirmation-confirm'}
          onClick={onConfirmation}
          type="submit"
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NoteEditConfirmation;
