import { OverlayTrigger, Popover, Table } from '@valid-eval/shared-react-components';

import Arrow from './assets/arrow.png';
import DimensionBoxPlot from './../DimensionBoxPlot';
import PropTypes from 'prop-types';

import Styles from './CompareScoreSummary.module.scss';
import cx from 'classnames';

function CompareScoreSummaryTable({ dimensions, phaseSummary, teamA, teamB, teamC }) {
  // Render each row of the table
  function renderRow(dim) {
    const dimensionBoxPlotData = {
      minScore: phaseSummary.min_score[dim.id],
      maxScore: phaseSummary.max_score[dim.id],
      firstQuartile: phaseSummary.first_quartile[dim.id],
      median: phaseSummary.median[dim.id],
      thirdQuartile: phaseSummary.third_quartile[dim.id],
    };
    const sigmas = [
      {
        team: teamA?.team,
        value: teamA?.judges_score?.[dim.id]?.sigmas ?? null,
        image: DimensionBoxPlot.MarkerImageA,
        style: Styles.SigmaA,
      },
      {
        team: teamB?.team,
        value: teamB?.judges_score?.[dim.id]?.sigmas ?? null,
        image: DimensionBoxPlot.MarkerImageB,
        style: Styles.SigmaB,
      },
      {
        team: teamC?.team,
        value: teamC?.judges_score?.[dim.id]?.sigmas ?? null,
        image: DimensionBoxPlot.MarkerImageC,
        style: Styles.SigmaC,
      },
    ]
      .filter((sigma) => {
        return sigma.value !== null && sigma.value !== undefined && !isNaN(sigma.value);
      })
      .sort((a, b) => a.value - b.value);

    let overlap = 0,
      aStyleOverride = {},
      bStyleOverride = {};

    if (
      teamB?.judges_score &&
      teamC?.judges_score &&
      Math.abs(
        teamB.judges_score?.[dim.id]?.avg_dim_score - teamC.judges_score?.[dim.id]?.avg_dim_score,
      ) < 0.11
    ) {
      bStyleOverride = {
        width: 26,
        height: 30,
        top: -1,
      };
      overlap++;
    }

    if (
      (teamA?.judges_score &&
        teamB?.judges_score &&
        Math.abs(
          teamA.judges_score?.[dim.id]?.avg_dim_score - teamB.judges_score?.[dim.id]?.avg_dim_score,
        ) < 0.11) ||
      (teamA?.judges_score &&
        teamC?.judges_score &&
        Math.abs(
          teamA.judges_score?.[dim.id]?.avg_dim_score - teamC.judges_score?.[dim.id]?.avg_dim_score,
        ) < 0.11)
    ) {
      aStyleOverride = {
        width: !overlap ? 26 : 36,
        height: !overlap ? 30 : 40,
        top: !overlap ? -1 : -6,
      };
    }

    return (
      <tr
        key={`dimension-${dim.id}`}
        data-test-id={`compare-score-summary-table-dimension-${dim.id}`}
      >
        <td>{dim.name}</td>
        <td width="312">
          <DimensionBoxPlot {...dimensionBoxPlotData}>
            {teamA?.judges_score && (
              <DimensionBoxPlot.Marker
                {...aStyleOverride}
                value={teamA.judges_score?.[dim.id]?.avg_dim_score}
                src={DimensionBoxPlot.MarkerImageA}
              />
            )}
            {teamB?.judges_score && (
              <DimensionBoxPlot.Marker
                {...bStyleOverride}
                value={teamB.judges_score?.[dim.id]?.avg_dim_score}
                src={DimensionBoxPlot.MarkerImageB}
              />
            )}
            {teamC?.judges_score && (
              <DimensionBoxPlot.Marker
                value={teamC.judges_score?.[dim.id]?.avg_dim_score}
                src={DimensionBoxPlot.MarkerImageC}
              />
            )}
          </DimensionBoxPlot>
        </td>
        <td className={Styles.SigmasTD}>
          <div className={Styles.Sigmas}>
            {sigmas.map((sigma, index) => {
              return (
                <div className={sigma?.style} key={`sigma-${index}`}>
                  <img className={Styles.Marker} src={sigma?.image} alt="Sigma marker" />
                  <span
                    data-test-id={`compare-score-summary-table-dimension-${dim.id}-team-${sigma.team}`}
                  >
                    {sigma?.value?.toFixed?.(2) || 'N/A'}
                  </span>
                </div>
              );
            })}
          </div>
        </td>
      </tr>
    );
  }

  return (
    <Table responsive data-test-id="compare-score-summary-table">
      <thead>
        <tr>
          <th />
          <th>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Popover>
                  <Popover.Body>Does not meet expectations</Popover.Body>
                </Popover>
              }
            >
              <span>
                Negative <i className={cx(Styles.InfoIcon, 'fas fa-info-circle')} />
              </span>
            </OverlayTrigger>
            <img className={Styles.Arrow} src={Arrow} alt="dimension box plot arrow" />
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Popover>
                  <Popover.Body>Exceeds expectations</Popover.Body>
                </Popover>
              }
            >
              <span>
                Positive <i className={cx(Styles.InfoIcon, 'fas fa-info-circle')} />
              </span>
            </OverlayTrigger>
          </th>
          <th>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Popover>
                  <Popover.Body>
                    The number of standard deviations above or below the mean for each Team's raw
                    score on each rubric dimension
                  </Popover.Body>
                </Popover>
              }
            >
              <span>
                Valid Sigmas&trade; <i className={cx(Styles.InfoIcon, 'fas fa-info-circle')} />
              </span>
            </OverlayTrigger>
          </th>
        </tr>
      </thead>
      <tbody>{dimensions?.map(renderRow)}</tbody>
    </Table>
  );
}

CompareScoreSummaryTable.propTypes = {
  dimensions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  phaseSummary: PropTypes.shape({
    min_score: PropTypes.object.isRequired,
    max_score: PropTypes.object.isRequired,
    first_quartile: PropTypes.object.isRequired,
    third_quartile: PropTypes.object.isRequired,
    median: PropTypes.object.isRequired,
  }).isRequired,
  teamA: PropTypes.shape({
    judges_score: PropTypes.object,
    sigma: PropTypes.object,
  }),
  teamB: PropTypes.shape({
    judges_score: PropTypes.object,
    sigma: PropTypes.object,
  }),
  teamC: PropTypes.shape({
    judges_score: PropTypes.object,
    sigma: PropTypes.object,
  }),
};

export default CompareScoreSummaryTable;
