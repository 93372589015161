import { Button } from '@valid-eval/shared-react-components';
import { useCreateIntro } from 'data/actions/intros';
import { load as loadJudge } from 'data/actions/judgeProfiles';
import { getCurrentUser, getJudgeDescription, getJudgeProfile, getTeamById } from 'data/reducers';
import { useDefaultContent, useIntroOffered, useSaving, useViewed } from 'data/reducers/intros';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntroModalForm from './components/IntroModalForm';
import Styles from './components/Intros.module.scss';

const AddIntro = ({ teamId, phaseId, judgeId }) => {
  /** Load needed data */
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadJudge(judgeId));
  }, []);

  const judge = useSelector((state) => getJudgeProfile(state, judgeId))?.toJS();
  const judgeDescription = useSelector((state) => getJudgeDescription(state, judgeId));
  const team = useSelector((state) => getTeamById(state, teamId))?.toJS();
  const user = useSelector(getCurrentUser)?.toJS();
  const fullName = `${user.first_name} ${user.last_name}`;
  /** End needed data */

  const createIntro = useCreateIntro();
  const saving = useSaving();
  const viewed = useViewed();
  const defaultContent = useDefaultContent();
  const introOffered = useIntroOffered();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const toggleModal = () => setIsModalOpened(!isModalOpened);
  const handleSubmit = async (values) => {
    const { error } = await createIntro(teamId, phaseId, { ...values.toJS(), judgeId: judge.id });
    if (!error) toggleModal();
  };

  const getInitialValues = useCallback(() => {
    const content =
      defaultContent ||
      `
      <p>
        Hello
        <span class="intro-team-name">TEAM_NAME</span>,
        <br/>
        <br/>
        I am interested in connecting with you.
        <br/>
        <br/>
        Regards,
        <br/>
        <br/>
        ${fullName}
      </p>
      `;

    return {
      email: user?.email,
      isDefault: true,
      content: content.replace(
        /(<span class="intro-team-name">)(.+)(<\/span>)/,
        `$1${team?.name}$3`,
      ),
    };
  }, [defaultContent, team, user, fullName]);

  return !judge || viewed ? null : (
    <>
      <IntroModalForm
        form="AddIntroForm"
        description={judgeDescription}
        initialValues={getInitialValues()}
        loading={saving}
        name={fullName}
        onCancel={toggleModal}
        onSubmit={handleSubmit}
        show={isModalOpened}
      />
      <div className={Styles.addIntroWrapper}>
        <div className={Styles.handshakeBig}>
          <i className="fa-solid fa-handshake"></i>
        </div>
        <div className={`${Styles.addIntroDescription} ms-3`}>
          Please share my judge profile information with this company. I am interested in connecting
          with them.
        </div>
        <Button
          variant="success"
          id="add-intro-button"
          onClick={toggleModal}
          disabled={introOffered}
        >
          {introOffered ? 'Intro made!' : 'Introduce Me'}
        </Button>
      </div>
    </>
  );
};

AddIntro.propTypes = {
  classes: PropTypes.object,
};

AddIntro.displayName = 'AddIntro';
export default AddIntro;
