import { Component } from 'react';
import Styles from '../RubricScoring/RubricScoring.module.scss';
import Details from './DetailsReadOnly';

class ReadOnly extends Component {
  render() {
    const { rubric } = this.props;
    const grades = rubric.get('grades');
    const dimensions = rubric.get('dimensions');
    return (
      <div>
        <div className={Styles.container}>
          <div className={Styles.dimensionsContainer}>
            {dimensions.map((dimension, index) => {
              const dimId = String(dimension.get('id'));
              return (
                <Details
                  readOnly={true}
                  dimension={dimension}
                  grades={grades}
                  key={`dim-${dimId}`}
                  coloredBackground={index % 2 === 0}
                />
              );
            })}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

ReadOnly.displayName = 'ReadOnly';
export default ReadOnly;
