import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import * as CommonTableCells from 'components/Tables/CommonTableCells';
import * as CommonTableHeaders from 'components/Tables/CommonTableHeaders';
import { sortDates, sortStrings } from 'components/Tables/sortingHelpers';

import * as TableCells from './Cells';
import { sortStatus } from './helpers';
import Styles from './Table.module.scss';
import { JudgeInvitation } from './types';

const columnHelper = createColumnHelper<JudgeInvitation>();

export const columns: ColumnDef<JudgeInvitation, any>[] = [
  columnHelper.accessor('id', {
    header: CommonTableHeaders.Checkbox,
    cell: CommonTableCells.Checkbox,
  }),
  columnHelper.accessor('full_name', {
    id: 'Judge Name',
    header: (props) => (
      <CommonTableHeaders.SortableHeader className={Styles.JudgeNameColumn} {...props} />
    ),
    cell: (props) => <CommonTableCells.BoldText className={Styles.JudgeNameColumn} {...props} />,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('email', {
    id: 'Email',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.SmallText,
  }),
  columnHelper.accessor('last_sent_at', {
    id: 'Last Date Sent',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.DateTime,
    sortingFn: sortDates,
  }),
  columnHelper.accessor('id', {
    id: 'Invitation',
    header: (props) => (
      <CommonTableHeaders.SortableHeader className={Styles.StatusColumn} {...props} />
    ),
    cell: TableCells.Invitation,
    sortingFn: sortStatus,
  }),
  columnHelper.accessor('id', {
    id: 'Actions',
    meta: {},
    header: CommonTableHeaders.SimpleHeader,
    cell: TableCells.Actions,
  }),
];
