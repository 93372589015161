import { Form, InputGroup } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import moment from 'moment-timezone';
import { Controller } from 'react-hook-form';
// @ts-expect-error: no types available
import DateTime from 'react-datetime/dist/react-datetime.umd'; // Vite and Rollup doesn't play well with cjs modules

import { required as requiredVal } from 'components/FormFields/validations';
import { DATE_FORMAT } from 'utils';

import FieldError from './FieldError';
import HelpText from './HelpText';
import Label from './Label';
import useFormFieldState from './useFormFieldState';

export type DateProps = {
  after?: React.ReactNode;
  before?: React.ReactNode;
  id?: string;
  label?: string;
  name: string;
  placeholder?: string;
  rules?: { validate?: Record<string, any> };
  disabled?: boolean;
  required?: boolean;
  helpText?: string;
};

const Date = ({
  after,
  before,
  id,
  label,
  name,
  placeholder,
  rules,
  disabled,
  required,
  helpText,
}: DateProps) => {
  const { control, error, isInvalid, validationClassname } = useFormFieldState(name);

  rules = rules || {};
  rules = required ? { ...rules, validate: { required: requiredVal, ...rules.validate } } : rules;

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value, name, ...fieldProps } }) => {
          return (
            <Form.Group className="mb-2">
              <Label htmlFor={id || name} label={label} />
              <HelpText text={helpText} />
              <InputGroup hasValidation>
                {before}
                <DateTime
                  className={cx('w-100', validationClassname)}
                  closeOnSelect={true}
                  dateFormat={DATE_FORMAT}
                  input={true}
                  onChange={(value: moment.Moment | string) => {
                    const isValidString = typeof value === 'string' && value.length === 10;
                    const currentMoment = moment(value);
                    if (currentMoment.isValid() && isValidString) {
                      onChange(currentMoment.format(DATE_FORMAT));
                    } else if (moment.isMoment(value)) {
                      onChange(value.format(DATE_FORMAT));
                    } else {
                      onChange(value);
                    }
                  }}
                  timeFormat={false}
                  value={value ?? ''}
                  utc={true}
                  inputProps={{
                    id: id || name,
                    autoComplete: 'off',
                    disabled: disabled,
                    className: cx('form-control', validationClassname),
                    placeholder: placeholder,
                    ...fieldProps,
                  }}
                />
                {after}
                <FieldError show={isInvalid} message={String(error?.message)} />
              </InputGroup>
            </Form.Group>
          );
        }}
      />
    </>
  );
};

export default Date;
