import { Card, Col, Form, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Dispatch } from 'redux';
import { InjectedFormProps, submit } from 'redux-form';
import { reduxForm } from 'redux-form/immutable';

import { dateInTimeZone } from 'utils';
import { organizerNav } from 'utils/urls';

import Styles from './Settings.module.scss';
import SettingsToggleField from './SettingsToggleField';

type OwnProps = {};

type DispatchProps = {
  submitForm: () => void;
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  // @ts-ignore
  submitForm: () => dispatch(submit('phaseSettingsForm')),
});

const connector = connect<{}, DispatchProps, OwnProps, {}>(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PhaseSettingsFormProps = PropsFromRedux &
  Partial<InjectedFormProps<{}, DispatchProps>> &
  OwnProps;

const PhaseSettingsForm = ({ initialValues, submitForm }: PhaseSettingsFormProps) => {
  // @ts-ignore
  const phase = initialValues?.toJS?.();
  // Trigger submit form on change debounced
  const handleChangeDebouced = useCallback(
    debounce(() => submitForm(), 350),
    [submitForm],
  );

  return (
    <>
      <Form onChange={handleChangeDebouced}>
        <Card>
          <Card.Body className="px-3 pt-3 pb-0">
            <Row>
              <Col md="6" className="mb-4">
                <Form.Label>
                  <b>Judging window opens</b>
                </Form.Label>
                <Col
                  className={cx(
                    'border',
                    'rounded',
                    'text-gray02',
                    'fw-bold',
                    Styles.ReadOnlyField,
                  )}
                >
                  {dateInTimeZone(phase?.start_date)}
                </Col>
                <Form.Text className="text-muted">Please contact VE for changes.</Form.Text>
              </Col>
              <Col md="6" className="mb-4">
                <Form.Label>
                  <b>Judging window closes</b>
                </Form.Label>
                <Col
                  className={cx(
                    'border',
                    'rounded',
                    'text-gray02',
                    'fw-bold',
                    Styles.ReadOnlyField,
                  )}
                >
                  {dateInTimeZone(phase?.end_date)}
                </Col>
                <Form.Text className="text-muted">Please contact VE for changes.</Form.Text>
              </Col>
            </Row>
            <Row>
              <Col className="mb-4">
                <Form.Group>
                  <Form.Label>
                    <b>Evaluation Rubric</b>
                  </Form.Label>
                  <Col className={cx('border', ' rounded', Styles.ReadOnlyField)}>
                    <a
                      href={organizerNav.rubric(phase?.event_id, phase.id)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <b>
                        {phase.rubric_name} <i className="fa-solid fa-arrow-up-right-from-square" />
                      </b>
                    </a>
                  </Col>
                </Form.Group>
                <Form.Text className="text-muted">
                  Evaluation criteria used during this Phase. Please contact VE for changes.
                </Form.Text>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mb-4">
                <SettingsToggleField
                  name="feedback_enabled"
                  label="Feedback & scores available to teams"
                  onLabel={
                    <>
                      On: Allow Teams to access feedback for this phase{' '}
                      <span className="text-decoration-underline">right now</span>.
                    </>
                  }
                  offLabel="Off: Prevent teams from viewing feedback and scores for this phase."
                />
              </Col>
              <Col md="6" className="mb-4">
                <SettingsToggleField
                  name="individual_scores_enabled"
                  label="Individual judges' scores available to teams"
                  onLabel={
                    <>
                      On: Display both the overall scoring report(s) and an individual scoring
                      report from each Judge on the Team "Results" views{' '}
                      <span className="text-decoration-underline">right now</span>.
                    </>
                  }
                  offLabel={
                    'Off: Display only the overall scoring report(s) on the Team "Results" views.'
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mb-4">
                <SettingsToggleField
                  name="performance_enabled"
                  label="Show relative performance information to Teams"
                  onLabel="On: Show Teams a box plot displaying their scores relative to other Teams in this Phase, including in exported PDFs."
                  offLabel="Off: Hide box plots from Teams in this Phase, including in exported PDFs."
                />
              </Col>
              <Col md="6" className="mb-4">
                <SettingsToggleField
                  name="comments_enabled"
                  label="Comments: add & edit"
                  onLabel={
                    <>
                      On: Allow Judges to add and edit comments{' '}
                      <span className="text-decoration-underline">right now</span>.
                    </>
                  }
                  offLabel="Off: Prevent Judges from editing and adding comments for this phase."
                />
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mb-4">
                <SettingsToggleField
                  name="navigators_view_results_enabled"
                  label="Navigators: View results"
                  onLabel="On: Allow Navigators to view results for this Phase."
                  offLabel="Off: Prevent Navigators from viewing results for this phase."
                />
              </Col>
              <Col md="6" className="mb-4">
                <SettingsToggleField
                  name="final_rank"
                  label="Enable Program Rank"
                  onLabel="On: Drag-n-drop team rows to yield a program-selected stack-ranking in the Results view."
                  offLabel="Off: Use standard 'In/Out/Bubble' selections in the Results view."
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

export default connector(
  reduxForm<{}, PhaseSettingsFormProps & PropsFromRedux>({
    form: 'phaseSettingsForm',
  })(PhaseSettingsForm),
);
