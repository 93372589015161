import { Form, InputGroup } from '@valid-eval/shared-react-components';
import { Controller } from 'react-hook-form';

import { required as requiredVal } from 'components/FormFields/validations';

import FieldError from './FieldError';
import HelpText from './HelpText';
import Label from './Label';
import useFormFieldState from './useFormFieldState';

export type CheckboxProps = {
  after?: React.ReactNode;
  before?: React.ReactNode;
  id?: string;
  label?: string;
  name: string;
  placeholder?: string;
  rules?: { validate?: Record<string, any> };
  disabled?: boolean;
  required?: boolean;
  helpText?: string;
};

const Checkbox = ({
  after,
  before,
  id,
  label,
  name,
  placeholder,
  rules,
  disabled,
  required,
  helpText,
}: CheckboxProps) => {
  const { control, isValid, isInvalid, error, validationClassname } = useFormFieldState(name);

  rules = rules || {};
  rules = required ? { ...rules, validate: { required: requiredVal, ...rules.validate } } : rules;

  const fieldId = `${name || id}-checkbox
  `;
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value, ...fieldProps } }) => (
          <Form.Group className="mb-2">
            <Label htmlFor={fieldId} label={label} />
            <HelpText text={helpText} />
            <InputGroup hasValidation>
              {before}
              <Form.Check
                id={fieldId}
                className={validationClassname}
                onChange={onChange}
                placeholder={placeholder}
                value={value ?? ''}
                isInvalid={isInvalid}
                isValid={isValid}
                disabled={disabled}
                type={'checkbox'}
                label={label}
                checked={!!value}
                {...fieldProps}
              />
              {after}
              <FieldError show={isInvalid} message={String(error?.message)} />
            </InputGroup>
          </Form.Group>
        )}
      />
    </>
  );
};

export default Checkbox;
