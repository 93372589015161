import { ConnectedProps, connect } from 'react-redux';
import {
  getDocuments,
  getEvent,
  getTeamProfileDynamicFieldsForCurrentEvent,
  getTeamProfileInitialValues,
  getTeamProfileOrphanDetails,
} from 'data/reducers';
import { useEffect, useMemo } from 'react';

import { Container } from '@valid-eval/shared-react-components';
import PDFHeader from '../results/components/PDFHeader';
import ProfileForm from 'components/Teams/ProfileForm';
import { load as loadArtifactItems } from 'data/actions/artifactItems';
import { load as loadEvent } from 'data/actions/events';
import { load as loadTeam } from 'data/actions/teams';
import withRouter from 'routes/withRouter';

type OwnProps = {
  params: {
    event_id: string;
    team_id: string;
    artifact_id: string;
  };
};

const connector = connect(
  (state, ownProps: OwnProps) => {
    return {
      eventId: ownProps.params.event_id,
      teamId: ownProps.params.team_id,
      artifactId: ownProps.params.artifact_id,
      artifacts: getDocuments(state, ownProps.params.team_id),
      team: getTeamProfileInitialValues(state, ownProps),
      dynamicFields: getTeamProfileDynamicFieldsForCurrentEvent(state, ownProps),
      // @ts-ignore
      orphanDetails: getTeamProfileOrphanDetails(state, ownProps),
      event: getEvent(state, ownProps.params.event_id),
    };
  },
  {
    loadArtifactItems,
    loadTeam,
    loadEvent,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PrintArtifactTeamPdfReportProps = PropsFromRedux & OwnProps;

const PrintArtifactTeamPdfReport = ({
  loadArtifactItems,
  loadTeam,
  loadEvent,
  eventId,
  event,
  artifactId,
  teamId,
  artifacts,
  team,
  orphanDetails,
  dynamicFields,
}: PrintArtifactTeamPdfReportProps) => {
  useEffect(() => {
    loadArtifactItems(teamId);
    loadTeam(teamId);
    loadEvent(eventId);
  }, [loadTeam, loadArtifactItems, teamId]);

  const artifact = artifacts?.find((ar: any) => parseInt(ar.id) === parseInt(artifactId));
  const teamDetails = useMemo(
    () => artifact?.team_details?.map((td: string) => parseInt(td)) || [],
    [artifact],
  );

  // Render nothing if no team or no artifact found
  if (!team || !artifact) return null;

  return (
    <Container>
      <PDFHeader team={team} event={event} showReport={false} />
      <div dangerouslySetInnerHTML={{ __html: artifact.content }} />
      <ProfileForm
        // @ts-ignore
        filterDynamicFields={(field: any) => teamDetails.includes(parseInt(field.get('row_id')))}
        disabled={true}
        dynamicFields={dynamicFields}
        orphanDetails={orphanDetails}
        initialValues={team}
      />
    </Container>
  );
};

export default withRouter(connector(PrintArtifactTeamPdfReport));
