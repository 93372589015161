import { ConnectedProps, connect } from 'react-redux';

import GenericModal from 'components/GenericModal';
import { error as showError } from 'data/actions/notifications';
import { scheduleAutomatch } from 'data/actions/phases';
import { getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

type OwnProps = {
  show: boolean;
  close(): void;
  reload(): void;
};

const connector = connect(
  (state, ownProps) => ({ phase: getSelectedPhase(state, ownProps)?.toJS?.() }),
  {
    showError,
    scheduleAutomatch,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PanelMatchModalProps = PropsFromRedux & OwnProps;

const PanelMatchModal = ({ show, close, phase, scheduleAutomatch }: PanelMatchModalProps) => {
  const [isLoading, startLoading, finishLoading] = useBooleanFlag(false);

  const handleRunPanelMatch = async () => {
    startLoading();
    try {
      const res = await scheduleAutomatch(phase.id, { is_panel_match: true });
      // @ts-ignore
      if (res.error) throw new Error(res.error);
    } catch {
      showError('Failed to start panel match.');
    } finally {
      finishLoading();
      close();
    }
  };

  return (
    <GenericModal
      size={'default'}
      show={show}
      onCancel={close}
      onConfirm={handleRunPanelMatch}
      name={'confirm-automatch'}
      title={'Panel Match'}
      body={`Please confirm you wish to run panel match.`}
      cancelButton={'Cancel'}
      confirmButton={'Panel Match'}
      isButtonDisabled={isLoading}
      isCancelButtonDisabled={isLoading}
    />
  );
};

export default withRouter(connector(PanelMatchModal));
