import { apiHeaders, apiURL } from '../services/config';

const _queryParams = (judgeId, isResultsScreen) => {
  const query = new URLSearchParams();
  if (judgeId) query.append('judge_profile_id', judgeId);
  if (isResultsScreen) query.append('results_screen', 1);
  return query.toString();
};

export const load = (teamId, phaseId, judgeId, isResultsScreen) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(
    `/phases/${phaseId}/teams/${teamId}/notes?${_queryParams(judgeId, isResultsScreen)}`,
  ),
});

export const create = (teamId, phaseId, { content, type, judgeId, role }) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({
    note: {
      content,
      type,
      judge_profile_id: judgeId,
      role,
    },
  }),
  endpoint: apiURL(`/phases/${phaseId}/teams/${teamId}/notes`),
});

export const update = (teamId, phaseId, { id, content, type, judgeId, results_screen }) => ({
  method: 'PUT',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({
    note: {
      content,
      type,
      judge_profile_id: judgeId,
      results_screen,
    },
  }),
  endpoint: apiURL(`/phases/${phaseId}/teams/${teamId}/notes/${id}`),
});

export const del = (teamId, phaseId, id) => ({
  method: 'DELETE',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/teams/${teamId}/notes/${id}`),
});

export const vote = (teamId, phaseId, { value, judgeId, id }) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({
    note: {
      judge_profile_id: judgeId,
      vote_value: value,
    },
  }),
  endpoint: apiURL(`/phases/${phaseId}/teams/${teamId}/notes/${id}/vote`),
});

export const evaluationViewed = (evaluationId) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({}),
  endpoint: apiURL(`/evaluations/${evaluationId}/viewed`),
});
