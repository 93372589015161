import NoteEdit from './NoteEdit';
import NoteHeader from './NoteHeader';
import NoteRead from './NoteRead';

import cx from 'classnames';
import Styles from './Notes.module.scss';

const Note = ({
  commentsEnabled,
  edit = false,
  isJudge,
  isOrganizer,
  isTeam,
  judgeId,
  onCancel,
  onEdit,
  phaseId,
  teamId,
  viewed,
  isUnredacted,
  ...note
}) => {
  const { type } = note;
  return (
    <li
      id={`note-${note.id}`}
      role="article"
      className={cx(Styles.note, {
        [Styles.noteInternal]: type === 'internal',
        [Styles.noteShared]: type !== 'internal' && !note.writerIsOrganizer,
        [Styles.noteSharedOrganizer]: type !== 'internal' && note.writerIsOrganizer,
      })}
    >
      <NoteHeader {...note} isTeam={isTeam} isOrganizer={isOrganizer} isUnredacted={isUnredacted} />
      {!edit && (
        <NoteRead
          isOrganizer={isOrganizer}
          isTeam={isTeam}
          judgeId={judgeId}
          onEdit={onEdit}
          phaseId={phaseId}
          teamId={teamId}
          commentsEnabled={commentsEnabled}
          {...note}
        />
      )}
      {edit && (
        <NoteEdit
          initialValues={{ content: note.content, internal: note.type === 'internal' }}
          isJudge={isJudge}
          isOrganizer={isOrganizer}
          judgeId={judgeId}
          onCancel={onCancel}
          phaseId={phaseId}
          teamId={teamId}
          commentsEnabled={commentsEnabled}
          viewed={viewed}
          {...note}
        />
      )}
    </li>
  );
};

Note.displayName = 'Note';
export default Note;
