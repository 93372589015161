import { Form } from '@valid-eval/shared-react-components';
import { useCreateNote, useReloadNotes } from 'data/actions/notes';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, isValid, reduxForm, reset } from 'redux-form/immutable';

import { isNavigator, isTeamLead } from 'utils';

import NoteFormControls from './NoteFormControls';
import NoteFormInput from './NoteFormInput';
import Styles from './Notes.module.scss';
import { trimCommentLineBreaks } from './helpers';

const formName = 'NoteNew';

const NoteNewForm = reduxForm({ form: formName, touchOnBlur: false })(({
  handleSubmit,
  teamId,
  phaseId,
  judgeId,
  isOrganizer,
  internalCommentsEnabled,
  viewed,
}) => {
  const valid = useSelector(isValid(formName));
  const values = useSelector(getFormValues(formName));

  return (
    <Form className={'d-print-none new-note-form'} onSubmit={handleSubmit} id="new-note-form">
      <Form.Label className={Styles.label} htmlFor="new-note-content">
        Comment
      </Form.Label>
      <NoteFormInput
        internal={values?.get('internal') || (viewed && internalCommentsEnabled) || isNavigator()}
        onSubmit={handleSubmit}
        internalCommentsEnabled={internalCommentsEnabled}
        viewed={viewed}
      />
      <NoteFormControls
        viewed={viewed}
        isOrganizer={isOrganizer}
        judgeId={judgeId}
        phaseId={phaseId}
        teamId={teamId}
        valid={valid}
      />
    </Form>
  );
});

const NoteNew = (props) => {
  const { teamId, phaseId, judgeId, isOrganizer, internalCommentsEnabled, viewed, onCancel } =
    props;
  const createNote = useCreateNote();
  const reloadNotes = useReloadNotes(teamId, phaseId, judgeId, isOrganizer);
  const dispatch = useDispatch();
  const valid = useSelector(isValid(formName));

  // Detects if there is a change at the comment box
  // and asks the user for confirmation before leaving
  // the page.
  // NOTE THE DEFAULT BROWSER MESSAGE CANNOT BE OVERRIDDEN
  useEffect(() => {
    function unloadListener(e) {
      if (valid) {
        const confirmationMessage = '/o';
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Webkit, Safari, Chrome
      }
    }
    window.addEventListener('beforeunload', unloadListener);
    return () => window.removeEventListener('beforeunload', unloadListener);
  }, [valid]);

  const onSubmit = async (values) => {
    let role = isOrganizer ? 'Organizer' : 'JudgeProfile';
    role = isNavigator() ? 'Navigator' : role;
    role = isTeamLead() ? 'TeamLead' : role;

    const { error } = await createNote(teamId, phaseId, {
      content: trimCommentLineBreaks(values.get('content')),
      type:
        values.get('internal') || (viewed && internalCommentsEnabled) || isNavigator()
          ? 'internal'
          : 'shared',
      role,
      judgeId: judgeId || undefined,
    });
    if (!error) dispatch(reset(formName));
    reloadNotes();
    onCancel?.();
  };

  return <NoteNewForm {...props} onSubmit={onSubmit} />;
};

export default NoteNew;
