import { Component } from 'react';
import { changeCriteria, changeDimensionScore } from 'data/actions/scores';
import { getIsLoadingRubric, getPhase, getRubric } from 'data/reducers';
import { isValid, submit } from 'redux-form/immutable';
import { judgeNav, teamNav } from 'utils/urls';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import noop from 'lodash/noop';

import IntroAdd from 'components/Intros/IntroAdd';
import Loading from 'components/Loading';
import { Notes } from 'components/Notes';
import RubricScoring from 'components/RubricScoring';
import { connect } from 'react-redux';
import { load as loadRubric } from 'data/actions/rubrics';
import withRouter from 'routes/withRouter';

class Show extends Component {
  constructor(props) {
    super(props);
    this.state = { confirmSaveNote: false };
  }

  componentDidMount() {
    this.props.loadRubric(this.props.score.get('phase_id'));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.score.get('phase_id') !== this.props.score.get('phase_id')) {
      this.props.loadRubric(this.props.score.get('phase_id'));
    }
  }

  handleChangeScore = (dimensionId, value) => {
    const currentScore = this.props.score.getIn(['dim_scores', dimensionId]);
    if (currentScore === value) return;
    this.props.changeDimensionScore(
      this.props.selfScoring,
      this.props.params.event_id,
      this.props.score.get('id'),
      dimensionId,
      value,
    );
  };

  handleChangeCriteria = (dimensionId, subdimensionId, criterionId) => {
    return this.props.changeCriteria(
      this.props.selfScoring,
      this.props.params.event_id,
      this.props.rubric,
      this.props.score.get('id'),
      dimensionId,
      subdimensionId,
      criterionId,
    );
  };

  handleContinueTeamEvaluation = () => {
    const { team_id, event_id } = this.props.params;
    if (team_id) this.props.navigate(teamNav.profile(event_id, team_id));
  };

  handleContinueJudgeEvaluations = () => {
    const { judge_profile_id, event_id } = this.props.params;
    if (judge_profile_id) {
      // If there's not any unsaved change on the comment box save it first
      if (this.props.validNoteNotSaved) this.props.submitForm('NoteNew');
      setTimeout(() => this.props.navigate(judgeNav.evaluations(event_id, judge_profile_id)), 100);
    }
  };

  handleContinue = () => {
    if (this.props.selfScoring) this.handleContinueTeamEvaluation();
    else this.handleContinueJudgeEvaluations();
  };

  render() {
    const { loading, rubric, score, selfScoring, phase, disabled } = this.props;
    if (loading) return <Loading />;
    const criteria = score && score.get('criteria');
    const scores = score && score.get('dim_scores');

    return (
      <>
        <RubricScoring
          rubric={rubric}
          criterias={criteria}
          clickedCriteria={score.get('clicked_criteria')}
          scores={scores}
          onChangeCriteria={disabled ? noop : this.handleChangeCriteria}
          onChangeScore={disabled ? noop : this.handleChangeScore}
          score={score}
          onContinueEvaluations={disabled ? noop : this.handleContinue}
          disabled={disabled}
        />
        {!selfScoring && (
          <div style={{ maxWidth: 990, minWidth: 790 }}>
            <IntroAdd
              teamId={score.get('team_id')}
              phaseId={phase.get('id')}
              judgeId={score.get('judge')}
            />
            <Notes
              isJudge
              teamId={score.get('team_id')}
              phaseId={phase.get('id')}
              judgeId={score.get('judge')}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const { selfScoring, score } = ownProps;

      const isLoadingRubric = getIsLoadingRubric(state);
      const rubric = score && getRubric(state, score.get('phase_id')); // TODO: use memoized selector
      const phase = getPhase(state, score.get('phase_id'));
      const validNoteNotSaved = isValid('NoteNew')(state);
      return {
        loading: !rubric || !score || isLoadingRubric,
        rubric,
        selfScoring,
        phase,
        validNoteNotSaved,
      };
    },
    {
      changeDimensionScore,
      changeCriteria,
      loadRubric,
      notifyError,
      notifySuccess,
      submitForm: submit,
    },
  )(Show),
);
