import cx from 'classnames';
import { RichTextEditor } from 'components/FormFields';
import { requiredRichText } from 'components/FormFields/validations';
import { isRunningOnIE } from 'utils';
import Styles from './Notes.module.scss';

const controls = [
  'bold',
  'strikethrough',
  'underline',
  'italic',
  'ul',
  'ol',
  ...(isRunningOnIE() ? [] : ['link']),
  '|',
  'undo',
  'redo',
];

const NoteFormInput = ({ internal = false, onChange, disabled }) => {
  return (
    <div
      className={cx(Styles.input, {
        [Styles.inputInternal]: internal,
        [Styles.inputInternalNew]: internal,
        [Styles.inputShared]: !internal,
      })}
    >
      <RichTextEditor
        name={'content'}
        validate={[requiredRichText]}
        onChange={onChange}
        config={{
          buttons: controls,
          buttonsMD: controls,
          buttonsSM: controls,
          link: {
            noFollowCheckbox: false,
            openInNewTabCheckbox: false,
          },
          showCharsCounter: false,
          showWordsCounter: false,
          showXPathInStatusbar: false,
        }}
        disabled={disabled}
      />
    </div>
  );
};

NoteFormInput.displayName = 'NoteFormInput';
export default NoteFormInput;
