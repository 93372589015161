import Loading from 'components/Loading';
import { useLoadIntros, useResetIntros } from 'data/actions/intros';
import { useLoadNotes, useResetNotes } from 'data/actions/notes';
import { getPhase, getScore } from 'data/reducers';
import { useLoading, useNotes, useViewed } from 'data/reducers/notes';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import NoteNew from './components/NoteNew';
import Styles from './components/Notes.module.scss';
import NotesList from './components/NotesList';

const Notes = ({ judgeId, teamId, phaseId, isTeam, isJudge, isOrganizer, scoreId }) => {
  const resetNotes = useResetNotes();
  const resetIntros = useResetIntros();
  useLoadNotes(teamId, phaseId, judgeId, isOrganizer);
  useLoadIntros(teamId, phaseId, judgeId, isOrganizer);

  useEffect(
    () => () => {
      resetNotes();
      resetIntros();
    },
    [],
  );

  const phase = useSelector((state) => getPhase(state, phaseId));
  const score = useSelector((state) => getScore(state, scoreId));
  const loading = useLoading();
  const notes = useNotes({ judgeId: score?.get('judge'), isTeam });
  const viewed = useViewed();

  let commentsEnabled = phase.get('comments_enabled');
  let internalCommentsEnabled = phase.get('comments_enabled');
  let endDate = moment(phase.get('end_date'));
  commentsEnabled = commentsEnabled && !viewed && (moment().isBefore(endDate) || isOrganizer);
  internalCommentsEnabled = internalCommentsEnabled && (moment().isBefore(endDate) || isOrganizer);

  return (
    <div className={Styles.wrapper}>
      {!isTeam && (commentsEnabled || internalCommentsEnabled) && (
        <NoteNew
          viewed={viewed}
          internalCommentsEnabled={internalCommentsEnabled}
          isJudge={isJudge}
          isOrganizer={isOrganizer}
          judgeId={judgeId}
          phaseId={phaseId}
          teamId={teamId}
        />
      )}
      {loading && !Boolean(notes?.length) && <Loading />}
      {(!loading || Boolean(notes?.length)) && (
        <NotesList
          isTeam={isTeam}
          viewed={viewed}
          internalCommentsEnabled={internalCommentsEnabled}
          commentsEnabled={commentsEnabled}
          isJudge={isJudge}
          isOrganizer={isOrganizer}
          judgeId={judgeId}
          notes={notes}
          phaseId={phaseId}
          teamId={teamId}
        />
      )}
    </div>
  );
};

Notes.displayName = 'Notes';
export default Notes;
