import { Button, OverlayTrigger, Table, Tooltip } from '@valid-eval/shared-react-components';
import { Component, useState } from 'react';
import { isNavigator, isTeamLead } from 'utils';
import styles from '../screens/app/events/components/SuggestionsTable.module.scss';

const TableRow = ({ role, onRemoveAssignment }) => {
  const [showFullCategories, setShowFullCategories] = useState(false);

  const handleRemove = () => {
    onRemoveAssignment(role.get('assign_to_id'));
  };

  return (
    <tr data-test-id={`assigned-table-row-${role.id}`}>
      {!isNavigator() && !isTeamLead() && (
        <td>
          <Button
            variant="btn btn-link-danger py-1"
            size="xs"
            onClick={handleRemove}
            disabled={role.get('scored')}
          >
            <i className="fa-regular fa-minus-circle fa-lg m-0"></i>
          </Button>
        </td>
      )}
      <td className="text-center">{role.get('match_quality')}</td>
      <td>
        {role.get('scored') && !isNavigator() && !isTeamLead() ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                <strong>
                  Can not remove this match because the judge has begun the evaluation
                </strong>
              </Tooltip>
            }
          >
            <i className="fa-duotone fa-lock-keyhole text-muted-light"></i>
          </OverlayTrigger>
        ) : (
          <span className="pe-3" />
        )}
        <span className="ms-2">{role.get('name')}</span>
      </td>
      <td>
        <div className={styles.categoryContainer}>
          <div
            className={!showFullCategories ? styles.multilineEllipsis : styles.fullText}
            dangerouslySetInnerHTML={{
              __html: role.get('categories').join('  <span class="text-muted">|</span>  '),
            }}
          ></div>
          <i
            className={`fas fa-sm ${styles.chevron} ${!showFullCategories ? 'fa-chevron-down' : 'fa-chevron-up'}`}
            onClick={() => setShowFullCategories(!showFullCategories)}
          ></i>
        </div>
      </td>
      <td className="text-center">{role.get('assigned_count')}</td>
    </tr>
  );
};

class AssignedRolesTable extends Component {
  render() {
    const { list, onRemoveAssignment, roleType, thWidths } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            {!isNavigator() && !isTeamLead() && <th width={thWidths[0]}>Unmatch</th>}
            <th width={thWidths[1]}>Match Quality</th>
            <th className="text-start" width={thWidths[2]}>
              {roleType}
            </th>
            <th className="text-start" width={thWidths[3]}>
              Categories
            </th>
            <th width={thWidths[4]}>{roleType === 'Team' ? 'Judge Count' : 'Team Count'}</th>
          </tr>
        </thead>
        <tbody>
          {list.map((role) => (
            <TableRow role={role} key={role.get('id')} onRemoveAssignment={onRemoveAssignment} />
          ))}
        </tbody>
      </Table>
    );
  }
}

AssignedRolesTable.displayName = 'AssignedRolesTable';

export default AssignedRolesTable;
