import { Button } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useMemo } from 'react';

import NoteDeleteControl from 'components/Notes/components/NoteDeleteControl';

import Styles from './Comment.module.scss';

function Comment({
  comment,
  onEditComment,
  team,
  commentsEnabled,
  internalCommentsEnabled,
  isFirstInternalNote,
}) {
  // This view is used only on campare view, accesed only by organizers and navigators
  // this is why we just check if the user is a not navigator and if comments are enabled
  const internalComment = comment.type === 'internal';
  const editEnabled = useMemo(
    () => comment.canEdit && (internalComment ? internalCommentsEnabled : commentsEnabled),
    [commentsEnabled, comment.type],
  );

  const handleEditComment = () => {
    onEditComment?.(comment, team);
  };

  return (
    <div
      className={cx({
        [Styles.CommentInternal]: internalComment,
        [Styles.CommentOrganizer]: !internalComment && comment.writerIsOrganizer,
        [Styles.Comment]: !internalComment && !comment.writerIsOrganizer,
      })}
    >
      {isFirstInternalNote && (
        <strong className={cx('text-uppercase', Styles.noteInternalLabel)}>
          Only visible to selection committee
        </strong>
      )}
      <p className={Styles.Content} dangerouslySetInnerHTML={{ __html: comment.content }} />
      <p className={Styles.Author}>- {comment.author}</p>
      <div className={cx('justify-content-between', Styles.ActionButtons)}>
        <div className="d-flex">
          {comment.upVotes > 0 && (
            <span className={cx(Styles.voteText)}>
              <i className="fa-solid fa-circle-arrow-up" /> <strong>{comment.upVotes}</strong>
            </span>
          )}
          {comment.downVotes > 0 && (
            <span className={Styles.voteText}>
              <i className="fa-solid fa-circle-arrow-down" /> <strong>{comment.downVotes}</strong>
            </span>
          )}
        </div>
        <div className="d-flex">
          {editEnabled && (
            <Button
              className={Styles.Actions}
              variant="link"
              onClick={handleEditComment}
              data-test-id={`edit-comment-${comment.id}`}
            >
              <i className={`fa-regular fa-pen-to-square`} />
            </Button>
          )}
          {comment.canDelete && (
            <NoteDeleteControl
              id={comment.id}
              phaseId={team?.phase_id}
              teamId={team?.team}
              isOrganizer={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Comment;
