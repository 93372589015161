import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import GenericModal from 'components/GenericModal';
import Styles from './TeamCompareSelector.module.scss';
import TeamSelect from './TeamSelect';
import CommentsForm from './components/CommentsForm';

function TeamSelectorControl({
  team,
  teams,
  letter,
  onSelectTeam,
  isZScore,
  eventId,
  phaseId,
  phase,
}) {
  const [teamSelected, setTeamSelected] = useState(team);
  const [showCommentsForm, setShowCommentsForm] = useState(false);
  const viewed = team?.viewed;

  const handleRemoveClick = () => {
    setTeamSelected(null);
    onSelectTeam?.(letter, null);
  };
  const handleOnSelectTeam = (letter, team) => {
    setTeamSelected(team);
    onSelectTeam?.(letter, team);
  };

  const toggleShowCommentsForm = () => {
    setShowCommentsForm(!showCommentsForm);
  };

  useEffect(() => {
    if (team) {
      setTeamSelected(team);
    }
  });

  let commentsEnabled = phase?.comments_enabled;
  let internalCommentsEnabled = phase?.comments_enabled;
  commentsEnabled = commentsEnabled && !viewed;

  return (
    <div className={Styles.Team}>
      <TeamSelect
        isZScore={isZScore}
        teams={teams}
        team={team}
        letter={letter}
        onSelectTeam={handleOnSelectTeam}
        eventId={eventId}
        teamSelected={teamSelected}
        evalId={team ? team.eval_id : null}
        handleRemoveClick={handleRemoveClick}
        handleAddComments={toggleShowCommentsForm}
        commentsEnabled={commentsEnabled}
        internalCommentsEnabled={internalCommentsEnabled}
      />
      {teamSelected && (commentsEnabled || internalCommentsEnabled) && (
        <GenericModal
          className={Styles.AddCommentModal}
          show={showCommentsForm}
          onCancel={toggleShowCommentsForm}
          name={'add-comment'}
          title={'Add comment'}
          cancelButton={null}
          confirmButton={null}
          body={
            <CommentsForm
              letter={letter}
              viewed={viewed}
              isZScore={isZScore}
              team={teamSelected}
              phaseId={phaseId}
              onCancel={toggleShowCommentsForm}
              commentsEnabled={commentsEnabled}
              internalCommentsEnabled={internalCommentsEnabled}
            />
          }
        />
      )}{' '}
    </div>
  );
}

TeamSelectorControl.propTypes = {
  isZScore: PropTypes.bool,
  onSelectTeam: PropTypes.func.isRequired,
  team: PropTypes.object,
  letter: PropTypes.string,
  teams: PropTypes.array.isRequired,
};

export default TeamSelectorControl;
