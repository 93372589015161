import classNames from 'classnames';
import Nav from 'components/Navigation';
import PhaseNavigation from 'components/PhaseNavigation';
import { getPhaseNavigatorsViewResultsEnabled } from 'data/reducers';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';
import EventTitle from 'screens/teams/components/Title';
import { isNavigator, isTeamLead } from 'utils';
import { organizerNav } from 'utils/urls';
import styles from './Navigation.module.scss';

const INVITE_PATTERN = /judges\/invite$/;

const CohortHome = () => {
  return (
    <div className={classNames(styles.faColor, 'fas fa-home-lg-alt')}>
      <u className={styles.menuLink}>Cohort Home</u>
    </div>
  );
};

const Settings = () => {
  return isNavigator() || isTeamLead() ? null : (
    <div className={classNames(styles.faColor, 'fas fa-cog')}>
      <u className={styles.menuLink}>Settings</u>
    </div>
  );
};

const Reports = () => {
  return (
    <div className={classNames(styles.faColor, 'fas fa-folders')}>
      <u className={styles.menuLink}>Reports</u>
    </div>
  );
};

const Navigation = ({
  event,
  active,
  phases,
  onPhaseChange,
  selectedPhaseId,
  canAccessResults,
  location,
}) => {
  const isDraftsSection = location.pathname === organizerNav.teamDraft(event.get('id'));
  let subtitle = 'Organizer Section';
  let teamsUrl = organizerNav.teamAssignments(event.get('id'));
  let judgesUrl = organizerNav.judgeAssignments(event.get('id'));
  if (isNavigator()) {
    subtitle = 'Navigator Section';
  } else if (isTeamLead()) {
    subtitle = 'Team Lead';
    teamsUrl = organizerNav.teamManage(event.get('id'));
    judgesUrl = organizerNav.judgeManage(event.get('id'));
  }

  return (
    <div className={classNames('clearfix', 'd-print-none')}>
      <div className={classNames(styles.orgNavTitle, 'float-start')}>
        <EventTitle
          logo={event.get('logo_url')}
          title={event.get('name')}
          subTitle={subtitle}
          role={'org+nav'}
        />
      </div>
      <div className="float-end">
        <div className={styles.navWrapperStyles}>
          <Nav
            upperNav
            items={[
              { url: organizerNav.status(event.get('id')), title: CohortHome() },
              { url: organizerNav.settings(event.get('id')), title: Settings() },
              { url: organizerNav.reports(event.get('id')), title: Reports() },
            ]}
          />
          <PhaseNavigation
            drafts
            phases={phases}
            onPhaseChange={onPhaseChange}
            selectedId={selectedPhaseId}
            disabled={INVITE_PATTERN.test(active)}
          >
            <Nav
              pills
              subNav
              items={
                isDraftsSection
                  ? []
                  : [
                      { url: organizerNav.status(event.get('id')), title: 'Status' },
                      {
                        url: organizerNav.results(event.get('id')),
                        title: 'Results',
                        disabled: !canAccessResults,
                      },
                      {
                        url: judgesUrl,
                        title: 'Judges',
                        active: location.pathname.startsWith(
                          `/app/events/${event.get('id')}/judges`,
                        ),
                      },
                      {
                        url: teamsUrl,
                        title: 'Teams',
                      },
                    ]
              }
            />
          </PhaseNavigation>
        </div>
      </div>
    </div>
  );
};

Navigation.displayName = 'Navigation';

export default withRouter(
  connect((state, ownProps) => ({
    canAccessResults: !isNavigator() || getPhaseNavigatorsViewResultsEnabled(state, ownProps),
  }))(Navigation),
);
