import uuid from 'uuid-random';
import { useRef } from 'react';
import './styles.scss';

// Because of a bug in react-bootstrap, TogglButton is not exported (will be fixed in the next release)
import { ToggleButton, ToggleButtonGroup } from '@valid-eval/shared-react-components';

const ToggleButtonComponent = ({ input: { onChange, value, name } }) => {
  const id = useRef(uuid());
  return (
    <ToggleButtonGroup onChange={onChange} value={value} name={name} type="radio">
      <ToggleButton id={`${name}-${id.current}-true`} variant="on" value={true}>
        On
      </ToggleButton>
      <ToggleButton id={`${name}-${id.current}-false`} variant="off" value={false}>
        Off
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleButtonComponent;
