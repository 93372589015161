import cx from 'classnames';
import moment from 'moment-timezone';
import Styles from './Notes.module.scss';

const ConditionalWrapper = ({ condition, wrapper: Wrapper, children }) =>
  condition ? <Wrapper>{children}</Wrapper> : children;

const getSelectionCommiteeAttribution = (writer, isOrganizer, isYou, isUnredacted, role) => {
  if (!isOrganizer || !isUnredacted)
    return role === 'TeamLead' ? "Team Lead's Comment" : 'From Selection Committee';

  const attributionName = isYou ? 'you' : `${writer?.first_name} ${writer?.last_name}`;
  return `${role === 'TeamLead' ? "Team Lead's Comment" : 'From Selection Committee'} to Team (entered by ${attributionName})`;
};

const NoteHeader = ({ isFirstInternal, isTeam, isUnredacted, ...note }) => {
  let { edited, writer, isYou, createdAt, isOrganizer, role } = note;
  createdAt = moment(createdAt);
  const redact = !isUnredacted ? note.writerDisplay : 'Anonymous';
  const writeOrg = note.writerIsOrganizer
    ? getSelectionCommiteeAttribution(writer, isOrganizer, isYou, isUnredacted, role)
    : redact;

  return (
    <header className={Styles.noteHeader}>
      {isFirstInternal && (
        <strong className={cx('text-uppercase', Styles.noteInternalLabel)}>
          Only visible to selection committee
        </strong>
      )}
      <span>
        <ConditionalWrapper condition={!isYou} wrapper={'strong'}>
          {writer && (!note.writerIsOrganizer || note.type === 'internal') && isUnredacted
            ? `${writer.first_name} ${writer.last_name}`
            : writeOrg}
        </ConditionalWrapper>
        {isYou && (!note.writerIsOrganizer || note.type === 'internal') && (
          <strong> (this is you)</strong>
        )}
        :
      </span>
      <small className={Styles.noteDate}>
        {edited && !isTeam && <span className="text-muted">(edited) </span>}
        {createdAt.format('L [at] h:mm a')}
      </small>
    </header>
  );
};

export default NoteHeader;
