import { Button, Dropdown, TeamCompareSelect } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import DocumentLink from 'components/DocumentLink';
import { load } from 'data/actions/artifactItems';
import { getArtifactItemsLoading, getDocuments, getEvent } from 'data/reducers';
import { organizerNav } from 'utils/urls';

import Styles from './TeamSelect.module.scss';
import { isTeamLead } from 'utils';

function TeamSelect({
  commentsEnabled,
  documents,
  evalId,
  event,
  eventId,
  handleAddComments,
  handleRemoveClick,
  internalCommentsEnabled,
  isZScore,
  letter,
  load,
  loading,
  onSelectTeam,
  team,
  teams,
  teamSelected,
}) {
  const isTeamLeadAndQualitativeMode =
    isTeamLead() && event?.judging_ux_mode === 'qualitative_only_mode';
  teams = teams?.map((team) => ({
    ...team,
    letter: letter.toLowerCase(),
  }));
  team = team
    ? {
        ...team,
        letter: letter.toLowerCase(),
      }
    : team;

  useEffect(() => {
    if (teamSelected) {
      load(teamSelected?.id);
    }
  }, []);

  function handleChange(team) {
    load(team?.id);
    onSelectTeam?.(letter, team);
  }

  return (
    <div className={Styles.Wrapper}>
      <>
        <TeamCompareSelect
          value={teams.find((t) => t.id === team?.id) || null}
          options={teams}
          onChange={handleChange}
          isZScore={isZScore}
          hideRank={isTeamLeadAndQualitativeMode}
        />

        <div className={Styles.TeamLinks}>
          <Dropdown className={Styles.TeamLinksDropdown}>
            <Dropdown.Toggle variant="link" disabled={teamSelected ? false : true}>
              Team Links
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                href={teamSelected ? organizerNav.profile(eventId, 'teams', teamSelected.id) : ''}
              >
                <i className="fa-duotone fa-address-card" />
                Team profile
              </Dropdown.Item>
              <Dropdown.Item href={organizerNav.scores(eventId, evalId)}>
                <i className="fa-duotone fa-square-poll-vertical" />
                Detailed results
              </Dropdown.Item>
              {loading && (
                <Dropdown.Item disabled className="justify-content-center align-items-center my-2">
                  <i className="fa-duotone fa-spinner fa-spin fa-lg" />
                </Dropdown.Item>
              )}
              {!loading &&
                documents?.map((doc) => (
                  <DocumentLink
                    fileName={doc.file_name}
                    name={doc.name}
                    type={doc.type}
                    value={doc.value}
                    key={doc.name}
                    className="dropdown-item"
                  />
                ))}{' '}
            </Dropdown.Menu>
          </Dropdown>{' '}
          <Button
            className={cx(Styles.Actions)}
            disabled={!teamSelected}
            variant="link"
            onClick={handleRemoveClick ? handleRemoveClick : null}
            data-test-id={`team-unselect-${letter}`}
          >
            <i className={`fa-regular fa-circle-xmark`} />
          </Button>
          {(commentsEnabled || internalCommentsEnabled) && (
            <Button
              disabled={!teamSelected}
              className={cx(Styles.Actions, 'comment-icon')}
              variant="link"
              onClick={handleAddComments}
              data-test-id={`team-unselect-${letter}`}
            >
              <i className={`fa-regular fa-message-plus`} />
            </Button>
          )}
        </div>
      </>
    </div>
  );
}

TeamSelect.propTypes = {
  onSelectTeam: PropTypes.func.isRequired,
  team: PropTypes.object,
  letter: PropTypes.string,
  teams: PropTypes.array.isRequired,
  isZScore: PropTypes.bool,
};

export default connect(
  (state, { teamSelected, eventId }) => {
    const event = getEvent(state, eventId)?.toJS();
    const loading =
      teamSelected && teamSelected?.id ? getArtifactItemsLoading(state, teamSelected?.id) : null;
    const documents =
      teamSelected && teamSelected?.id ? getDocuments(state, teamSelected?.id)?.toJS?.() : null;
    return { event, documents, loading };
  },
  { load },
)(TeamSelect);
