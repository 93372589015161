import { Card, Col, Form, Row } from '@valid-eval/shared-react-components';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Dispatch } from 'redux';
import { InjectedFormProps, submit } from 'redux-form';
import { reduxForm } from 'redux-form/immutable';

import { Email } from 'components/FormFields';
import { email } from 'components/FormFields/validations';

import SettingsToggleField from './SettingsToggleField';

type OwnProps = {};

type DispatchProps = {
  submitForm: () => void;
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  // @ts-ignore
  submitForm: () => dispatch(submit('globalSettingsForm')),
});

const connector = connect<{}, DispatchProps, OwnProps, {}>(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type GlobalSettingsFormProps = PropsFromRedux &
  Partial<InjectedFormProps<{}, DispatchProps & OwnProps>>;

const GlobalSettingsForm = ({ submitForm }: GlobalSettingsFormProps) => {
  // Trigger submit form on change debounced
  const handleChangeDebouced = useCallback(
    debounce(() => submitForm(), 350),
    [submitForm],
  );

  return (
    <Form onChange={handleChangeDebouced}>
      <Card>
        <Card.Body className="px-3 pt-3 pb-0">
          <Row>
            <Col md="6" className="mb-4">
              <Email
                label="Reply-to Email"
                name="replyToEmail"
                className="m-0"
                validate={[email]}
              />
              <Form.Text className="text-muted">Notifications reply-to email address</Form.Text>
            </Col>
            <Col md="6" className="mb-4">
              <Email label="Reply-to Name" name="replyToName" className="m-0" />
              <Form.Text className="text-muted">Notifications reply-to name</Form.Text>
            </Col>
          </Row>
          <Row>
            <Col md="6" className="mb-4">
              <SettingsToggleField
                name="signup_enabled"
                label="Team sign up"
                onLabel="On: The Team Signup page is accessible."
                offLabel="Off: The Team Signup page is turned off."
              />
            </Col>
            <Col md="6" className="mb-4">
              <SettingsToggleField
                name="profile_enabled"
                label="Teams can edit profiles"
                onLabel="On: Existing teams can edit their profiles, upload revised files."
                offLabel="Off: Disable all revisions."
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default connector(
  reduxForm<{}, GlobalSettingsFormProps & PropsFromRedux>({
    form: 'globalSettingsForm',
  })(GlobalSettingsForm),
);
