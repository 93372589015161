import { useState } from 'react';
import './AuthorizedNav.scss';

import { Dropdown, Image, Nav, NavDropdown } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import LinkContainer from 'components/LinkContainer';
import { getCurrentUserEventIds } from 'data/reducers';
import HelpModal from 'screens/app/components/HelpModal';

import ErrorBoundary from '../../ErrorBoundary';
import NavbarListNotification from './NavbarListNotification';

const AuthorizedNav = ({ user, logout }) => {
  const eventsCount = useSelector(getCurrentUserEventIds)?.size || 0;
  const profileUrl = user.getIn(['user_linked_in_profile', 'profile_photo_url']);
  const [showHelpModal, setShowHelpModal] = useState(false);

  const handleToggleShowModal = () => {
    setShowHelpModal(!showHelpModal);
  };

  const navDropdownTitle = (
    <div id="account">
      {user.get('first_name')}
      <i className={cx('customArrow', 'fas fa-angle-down')}></i>
    </div>
  );

  return (
    <>
      <Nav className="ms-auto">
        {!!eventsCount && (
          <LinkContainer to="/app/dashboard">
            <Nav.Item className="main-menu-nav-item">
              <Nav.Link id="item" href="/app/dashboard" eventKey={1}>
                Dashboard
              </Nav.Link>
            </Nav.Item>
          </LinkContainer>
        )}
        <Nav.Item className="main-menu-nav-item">
          <Nav.Link id="item" eventKey={2}>
            <ErrorBoundary>
              <NavbarListNotification user={user} />
            </ErrorBoundary>
          </Nav.Link>
        </Nav.Item>

        <Nav.Item className="main-menu-nav-item pe-2">
          <Nav.Link id="item" onClick={handleToggleShowModal} eventKey={1}>
            <i className={'fa-solid fa-circle-question fa-lg'} />
          </Nav.Link>
        </Nav.Item>

        <NavDropdown className="main-menu-nav-item" title={navDropdownTitle}>
          <LinkContainer to="/app/account">
            <Dropdown.Item className="dropNotifications" eventKey="3.1">
              My Account & Profiles
              <Image className="imgContainer" src="/images/icons/account.png" />
            </Dropdown.Item>
          </LinkContainer>
          <Dropdown.Item className="dropLogout" onClick={logout} eventKey="3.2" id="logout">
            Logout
            <Image className="imgContainer" src="/images/icons/logout.png" />
          </Dropdown.Item>
        </NavDropdown>
        {profileUrl && (
          <LinkContainer to="/app/account">
            <Nav.Item eventKey={4} className="user-link">
              <Image
                role="presentation"
                src={profileUrl}
                className="linkedin-nav-image"
                roundedCircle
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/images/no-image.png';
                  e.target.style.display = 'none';
                }}
              />
            </Nav.Item>
          </LinkContainer>
        )}
      </Nav>
      <HelpModal show={showHelpModal} onCancel={handleToggleShowModal} />
    </>
  );
};

export default AuthorizedNav;
