import * as api from 'data/services/intros';

import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';

import { RSAA } from 'redux-api-middleware';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const actions = {
  INTROS_RESET: 'intros/INTROS_RESET',
  LOAD_INTROS_PENDING: 'intros/LOAD_INTROS_PENDING',
  LOAD_INTROS_FULFILLED: 'intros/LOAD_INTROS_FULFILLED',
  LOAD_INTROS_REJECTED: 'intros/LOAD_INTROS_REJECTED',
  CREATE_INTRO_PENDING: 'intros/CREATE_INTRO_PENDING',
  CREATE_INTRO_FULFILLED: 'intros/CREATE_INTRO_FULFILLED',
  CREATE_INTRO_REJECTED: 'intros/CREATE_INTRO_REJECTED',
  UPDATE_INTRO_PENDING: 'intros/UPDATE_INTRO_PENDING',
  UPDATE_INTRO_FULFILLED: 'intros/UPDATE_INTRO_FULFILLED',
  UPDATE_INTRO_REJECTED: 'intros/UPDATE_INTRO_REJECTED',
  DELETE_INTRO_PENDING: 'intros/DELETE_INTRO_PENDING',
  DELETE_INTRO_FULFILLED: 'intros/DELETE_INTRO_FULFILLED',
  DELETE_INTRO_REJECTED: 'intros/DELETE_INTRO_REJECTED',
  VIEW_INTRO_PENDING: 'intros/VIEW_INTRO_PENDING',
  VIEW_INTRO_FULFILLED: 'intros/VIEW_INTRO_FULFILLED',
  VIEW_INTRO_REJECTED: 'intros/VIEW_INTRO_REJECTED',
};

export const reset = () => ({ type: actions.INTROS_RESET });

export const loadIntros = (teamId, phaseId, judgeId, isOrganizer) => {
  return {
    [RSAA]: {
      ...api.load(teamId, phaseId, judgeId, isOrganizer),
      types: [
        actions.LOAD_INTROS_PENDING,
        actions.LOAD_INTROS_FULFILLED,
        actions.LOAD_INTROS_REJECTED,
      ],
    },
  };
};

export const createIntro = (teamId, phaseId, { content, isDefault, email, judgeId, organizer }) => {
  return {
    [RSAA]: {
      ...api.create(teamId, phaseId, { content, isDefault, email, judgeId, organizer }),
      types: [
        actions.CREATE_INTRO_PENDING,
        actions.CREATE_INTRO_FULFILLED,
        actions.CREATE_INTRO_REJECTED,
      ],
    },
  };
};

export const updateIntro = (
  teamId,
  phaseId,
  { id, content, isDefault, email, judgeId, organizer },
) => {
  return {
    [RSAA]: {
      ...api.update(teamId, phaseId, { id, content, isDefault, email, judgeId, organizer }),
      types: [
        actions.UPDATE_INTRO_PENDING,
        actions.UPDATE_INTRO_FULFILLED,
        actions.UPDATE_INTRO_REJECTED,
      ],
    },
  };
};

export const deleteIntro = (teamId, phaseId, id) => {
  return {
    [RSAA]: {
      ...api.del(teamId, phaseId, id),
      types: [
        actions.DELETE_INTRO_PENDING,
        actions.DELETE_INTRO_FULFILLED,
        actions.DELETE_INTRO_REJECTED,
      ],
    },
  };
};

export const viewIntro = (teamId, phaseId, id) => {
  return {
    [RSAA]: {
      ...api.view(teamId, phaseId, id),
      types: [
        actions.VIEW_INTRO_PENDING,
        actions.VIEW_INTRO_FULFILLED,
        actions.VIEW_INTRO_REJECTED,
      ],
    },
  };
};

export const useLoadIntros = (teamId, phaseId, judgeId, isOrganizer) => {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const { error } = await dispatch(loadIntros(teamId, phaseId, judgeId, isOrganizer));
      error && dispatch(notifyError('Failed to load the introductions', 'Error'));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, phaseId, judgeId, isOrganizer]);
};

export const useReloadIntros = (teamId, phaseId, judgeId, isOrganizer) => {
  const dispatch = useDispatch();

  return async () => {
    const { error } = await dispatch(loadIntros(teamId, phaseId, judgeId, isOrganizer));
    error && dispatch(notifyError('Failed to load the introductions', 'Error'));
  };
};

export const useCreateIntro = () => {
  const dispatch = useDispatch();
  return async (teamId, phaseId, { content, isDefault, email, judgeId, organizer }) => {
    const result = await dispatch(
      createIntro(teamId, phaseId, { content, isDefault, email, judgeId, organizer }),
    );

    result.error && dispatch(notifyError('Failed to save the intro, please try again', 'Error'));
    !result.error && dispatch(notifySuccess('The intro has been saved', 'Success'));
    return result;
  };
};

export const useUpdateIntro = () => {
  const dispatch = useDispatch();
  return async (teamId, phaseId, { id, content, isDefault, email, judgeId, organizer }) => {
    const result = await dispatch(
      updateIntro(teamId, phaseId, { id, content, isDefault, email, judgeId, organizer }),
    );

    result.error && dispatch(notifyError('Failed to save the intro, please try again', 'Error'));
    !result.error && dispatch(notifySuccess('The intro has been saved', 'Success'));
    return result;
  };
};

export const useDeleteIntro = () => {
  const dispatch = useDispatch();
  return async (teamId, phaseId, id) => {
    const result = await dispatch(deleteIntro(teamId, phaseId, id));

    result.error && dispatch(notifyError('Failed to delete the intro, please try again', 'Error'));
    !result.error && dispatch(notifySuccess('The intro has been saved', 'Success'));
    return result;
  };
};

export const useResetIntros = () => {
  const dispatch = useDispatch();
  return () => dispatch(reset());
};

export const useViewIntro = () => {
  const dispatch = useDispatch();
  return async (teamId, phaseId, id) => {
    const result = await dispatch(viewIntro(teamId, phaseId, id));

    result.error && dispatch(notifyError('Failed to reveal intro info, please try again', 'Error'));
    return result;
  };
};
