import { OverlayTrigger, Popover } from '@valid-eval/shared-react-components';

import Arrow from 'screens/app/results/components/CompareScoreSummaryTable/assets/arrow.png';
import DimensionBoxPlot from 'screens/app/results/components/DimensionBoxPlot';
import Legend from './assets/legend.png';
import SelfScoreDot from 'images/self_score_dot.png';
import Styles from './EvaluationDetails.module.scss';
import cx from 'classnames';
import { isGovEnv } from 'config';

const getDimensionSelfScore = (score, dimension) => {
  if (!score?.complete) return null;
  return score?.dim_scores?.[dimension?.id];
};

const getJudgeScore = (evaluation, dimension) => {
  return evaluation?.judges_score?.[dimension?.id] || {};
};

const getSigma = (evaluation, dimension) => {
  return getJudgeScore(evaluation, dimension)?.sigmas;
};

const getScore = (evaluation, dimension) => {
  return getJudgeScore(evaluation, dimension)?.avg_dim_score;
};

const getDimensionBoxPlotData = (phaseSummary, dimension) => {
  return {
    minScore: phaseSummary?.min_score?.[dimension.id],
    maxScore: phaseSummary?.max_score?.[dimension.id],
    firstQuartile: phaseSummary?.first_quartile?.[dimension.id],
    median: phaseSummary?.median?.[dimension.id],
    thirdQuartile: phaseSummary?.third_quartile?.[dimension.id],
  };
};

const EvaluationDetails = (props) => {
  let { rubric, evaluation, selfScore, phaseSummary, isTeam } = props;

  // Parse everything to JSON
  rubric = rubric?.toJSON?.() || rubric || {};
  evaluation = evaluation?.toJSON?.() || evaluation || {};
  selfScore = selfScore?.toJSON?.() || selfScore || {};
  phaseSummary = phaseSummary?.toJSON?.() || phaseSummary || {};

  // Get relevant data
  const dimensions = rubric?.dimensions;
  const showSelfScore = !isGovEnv() && selfScore?.complete;
  const selfScoreTrue = isTeam ? 2 : 3;
  const selfScoreFalse = isTeam ? 1 : 2;

  return (
    <table className={'table table-responsive'} data-test-id="evaluation-details-summary-table">
      <thead>
        <tr>
          <th style={{ minWidth: 300 }} colSpan={!showSelfScore ? 2 : 1} />
          {!isTeam && (
            <th style={{ minWidth: 140 }} className="text-center">
              Valid Sigmas&trade;
            </th>
          )}
          <th style={{ minWidth: 140 }} className="text-center">
            Dimension Scores
          </th>
          {showSelfScore && (
            <th style={{ minWidth: 140 }} className="text-center">
              Self evaluation
            </th>
          )}
          <th style={{ minWidth: 360 }} className="text-center">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Popover>
                  <Popover.Body>Does not meet expectations</Popover.Body>
                </Popover>
              }
            >
              <span>
                Negative <i className={cx(Styles.InfoIcon, 'fas fa-info-circle')} />
              </span>
            </OverlayTrigger>
            <img className={Styles.Arrow} src={Arrow} alt="dimension box plot arrow" />
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Popover>
                  <Popover.Body>Exceeds expectations</Popover.Body>
                </Popover>
              }
            >
              <span>
                Positive <i className={cx(Styles.InfoIcon, 'fas fa-info-circle')} />
              </span>
            </OverlayTrigger>
          </th>
        </tr>
      </thead>
      <tbody>
        {dimensions.map((dimension) => (
          <tr key={dimension?.id} data-test-id={`results-table-dimension-${dimension?.id}`}>
            <td colSpan={!showSelfScore ? 2 : 1}>
              <span className={'ps-4 pe-4 d-block'}>{dimension?.name}</span>
            </td>
            {!isTeam && (
              <td
                className={cx('score-value text-center', Styles.Sigma)}
                data-test-id={`results-table-dimension-${dimension?.id}-sigma`}
              >
                {getSigma(evaluation, dimension)?.toFixed?.(2) || 'N/A'}
              </td>
            )}
            <td className="score-value text-center">
              {getScore(evaluation, dimension)?.toFixed?.(2) || 'N/A'}
            </td>
            {showSelfScore && (
              <td className="score-value text-center">
                {getDimensionSelfScore(selfScore, dimension)?.toFixed?.(2) || 'N/A'}
              </td>
            )}
            <td>
              <DimensionBoxPlot {...getDimensionBoxPlotData(phaseSummary, dimension)}>
                <DimensionBoxPlot.Marker
                  value={getScore(evaluation, dimension)}
                  src={DimensionBoxPlot.MarkerImageBlue}
                />
                {showSelfScore &&
                  getDimensionSelfScore(selfScore, dimension) !== null &&
                  getDimensionSelfScore(selfScore, dimension) !== undefined && (
                    <DimensionBoxPlot.Marker
                      value={getDimensionSelfScore(selfScore, dimension)}
                      src={SelfScoreDot}
                    />
                  )}
              </DimensionBoxPlot>
            </td>
          </tr>
        ))}

        <tr className="legend">
          <td colSpan={showSelfScore ? 1 : 2} />
          <td
            colSpan={showSelfScore ? selfScoreTrue : selfScoreFalse}
            className={cx('text-center', Styles.BoxPlotDescription)}
          >
            This box plot visualizes your scores relative to other teams in this phase and event.
          </td>
          <td className="text-center">
            <img className={Styles.Legend} src={Legend} alt="Score Legend" />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

EvaluationDetails.displayName = 'EvaluationDetails';
export default EvaluationDetails;
