import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { childrenToOptions, staticFieldTypes } from 'components/FormFields/DynamicFields';
import { Event } from 'data/features/eventsTypes';
import { TeamDraft, TeamDraftPayload } from 'data/features/teamDraftTypes';

import { StepInfo } from '../types';
import useCalculateStepInfo from './useCalculateStepInfo';

export type DetailsFormType = Record<string, any>;

const useDetailsForm = ({
  draft,
  event,
  onInfoChange,
  user,
  isActive,
}: {
  draft?: TeamDraft;
  event?: Event;
  onInfoChange(data: StepInfo): void;
  user?: any;
  isActive: boolean;
}) => {
  const form = useForm<DetailsFormType>({
    shouldFocusError: true,
    defaultValues: {},
    mode: 'onChange',
  });

  const details = event?.team_details || [];

  const requiredFields =
    details
      .map((d) => (d.required && !staticFieldTypes.includes(d.field_type) ? d.field_key : ''))
      .filter(Boolean) || [];

  useCalculateStepInfo({ form, requiredFields, onInfoChange, user });

  // Trigger form validation on load
  useEffect(() => {
    form.trigger();
  }, []);

  // Set focus
  useEffect(() => {
    if (!isActive) return;

    const detail = event?.team_details?.find((d) => !staticFieldTypes.includes(d.field_type));
    if (!detail) return;

    form.setFocus(detail.field_key);
  }, [isActive, event?.team_details?.toString()]);

  // Load draft details into form
  useEffect(() => {
    if (!draft?.id) return;

    event?.team_details?.forEach((detail) => {
      if (staticFieldTypes.includes(detail.field_type)) return;

      let value: any = draft.details[detail.field_key];

      // checkboxes field value is an array where each value is false if not checked
      // or the value if checked
      if (detail.field_type === 'checkbox_group') {
        const options = childrenToOptions(detail.children);
        value = options.map((option) => {
          const currentValue = draft.details[detail.field_key] || [];
          return currentValue.includes(option.value) ? option.value : false;
        });
      }

      form.setValue(detail.field_key, value);
    });
    form.trigger();
  }, [draft?.id]);

  const getDraftWithDetails = (draft?: TeamDraft, data?: DetailsFormType): TeamDraftPayload => {
    if (!draft || !data) return {};

    const details = Object.keys(data).reduce<DetailsFormType>((acc, key) => {
      const field = form.getFieldState(key);
      if (field.invalid) return acc;
      if (Array.isArray(data[key])) acc[key] = data[key].filter(Boolean);
      else acc[key] = data[key];
      return acc;
    }, {});

    return { id: draft.id, name: draft.name, details };
  };

  return { form, getDraftWithDetails };
};

export default useDetailsForm;
