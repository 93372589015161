import { Alert } from '@valid-eval/shared-react-components';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { fromContents } from 'data/features/contents';
import withRouter from 'routes/withRouter';

import { StepComponentProps } from '../types';
import StepContainer from './StepContainer';

type OwnProps = StepComponentProps;

const mapStateToProps = (state: any, ownProps: OwnProps) => {
  const { event } = ownProps;
  return {
    title: fromContents.getContent(state.toJS(), event?.id || '', 'Team', 'Signup', 'Closed Title'),
    notice: fromContents.getContent(
      state.toJS(),
      event?.id || '',
      'Team',
      'Signup',
      'Closed Notice',
    ),
  };
};

const connector = connect(mapStateToProps, null);

type DisabledStepProps = OwnProps & ConnectedProps<typeof connector>;

const DisabledStep = ({ title, notice }: DisabledStepProps) => {
  const { t } = useTranslation();

  return (
    <StepContainer
      content={
        <>
          <Alert
            variant="warning"
            className="py-3 d-flex align-items-center justify-content-center"
          >
            <i className="fa-sharp fa-solid fa-circle-exclamation me-2 text-orange01" />
            <b>{t('auth.teams_sign_up.steps.disabled.notice')}</b>
          </Alert>
          <h1 dangerouslySetInnerHTML={{ __html: title?.content || '' }} />
          <div dangerouslySetInnerHTML={{ __html: notice?.content || '' }} />
        </>
      }
    />
  );
};

export default withRouter(connector(DisabledStep));
