import { Button, Modal } from '@valid-eval/shared-react-components';

type TeamFeedbackAvailableModalProps = {
  show: boolean;
  onCancel: () => void;
  onConfirm: (sendNotification: boolean) => void;
};

const TeamFeedbackAvailableModal = ({
  show,
  onCancel,
  onConfirm,
}: TeamFeedbackAvailableModalProps) => {
  return (
    <Modal size="lg" id={`team-feedback-available-modal`} show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>FEEDBACK & SCORES AVAILABLE TO TEAMS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Would you like to notify teams that they can view feedback and scores? Click “Yes” be
        redirected to the manage teams screen where you can send the notification, “No” to make
        feedback available without notifying teams, or “Cancel” to keep feedback and scores
        unavailable.
      </Modal.Body>
      <Modal.Footer>
        <Button
          id={`team-feedback-available-modal-cancel`}
          onClick={onCancel}
          variant={'link'}
          style={{ position: 'absolute', left: 0 }}
        >
          Cancel
        </Button>
        <Button
          id={`team-feedback-available-modal-confirm-false`}
          onClick={() => onConfirm(false)}
          variant={'link'}
        >
          No
        </Button>
        <Button
          type={'submit'}
          variant={'success'}
          id={`team-feedback-available-modal--confirm`}
          onClick={() => onConfirm(true)}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TeamFeedbackAvailableModal;
