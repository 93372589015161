import { combineActions, handleActions } from 'redux-actions';

import { actions } from 'data/actions/intros';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

// State and reducers
const initialState = {
  saving: false,
  loading: false,
  viewed: true,
  intros: [],
  defaultContent: null,
  introOffered: true,
};

const reducers = {
  [actions.INTROS_RESET]: () => ({ ...initialState }),
  [actions.LOAD_INTROS_PENDING]: (state) => ({ ...state, loading: true }),
  [actions.LOAD_INTROS_REJECTED]: (state) => ({ ...state, loading: false }),
  [actions.LOAD_INTROS_FULFILLED]: (state, { payload }) => ({
    ...state,
    loading: false,
    intros: payload.intros,
    viewed: payload.viewed,
    defaultContent: payload.defaultContent,
    introOffered: payload.introOffered,
  }),
  [combineActions(
    actions.CREATE_INTRO_PENDING,
    actions.UPDATE_INTRO_PENDING,
    actions.DELETE_INTRO_PENDING,
  )]: (state) => ({
    ...state,
    saving: true,
  }),
  [combineActions(
    actions.CREATE_INTRO_REJECTED,
    actions.UPDATE_INTRO_REJECTED,
    actions.DELETE_INTRO_REJECTED,
  )]: (state) => ({
    ...state,
    saving: false,
  }),
  [actions.CREATE_INTRO_FULFILLED]: (state, { payload }) => ({
    ...state,
    saving: false,
    intros: [payload.intro, ...state.intros],
    defaultContent: payload.defaultContent,
    introOffered: payload.introOffered,
  }),
  [actions.UPDATE_INTRO_FULFILLED]: (state, { payload }) => ({
    ...state,
    saving: false,
    intros: state.intros.map((intro) => (intro.id === payload.intro.id ? payload.intro : intro)),
    defaultContent: payload.defaultContent,
    introOffered: payload.introOffered,
  }),
  [actions.DELETE_INTRO_FULFILLED]: (state, { payload }) => ({
    ...state,
    saving: false,
    intros: state.intros.filter((intro) => intro.id !== payload.id),
    defaultContent: payload.defaultContent,
    introOffered: payload.introOffered,
  }),
  [actions.VIEW_INTRO_FULFILLED]: (state, { payload }) => ({
    ...state,
    intros: state.intros.map((intro) =>
      intro.id === payload.id ? { ...intro, viewed: true } : intro,
    ),
  }),
};

// Selectors
export const getIntrosState = (state) => state.get('intros');
export const getLoading = createSelector(getIntrosState, (state) => state.loading);
export const getSaving = createSelector(getIntrosState, (state) => state.saving);
export const getIntroOffered = createSelector(getIntrosState, (state) => state.introOffered);
export const getDefaultContent = createSelector(getIntrosState, (state) => state.defaultContent);
export const getViewed = createSelector(getIntrosState, (state) => state.viewed);
export const getRawIntros = createSelector(getIntrosState, (state) => state.intros);

// Selectors hooks
export const useLoading = () => useSelector(getLoading);
export const useSaving = () => useSelector(getSaving);
export const useIntroOffered = () => useSelector(getIntroOffered);
export const useDefaultContent = () => useSelector(getDefaultContent);
export const useIntros = () => useSelector(getRawIntros);
export const useViewed = () => useSelector(getViewed);

export default handleActions(reducers, initialState);
