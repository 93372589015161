import cx from 'classnames';
import { connect } from 'react-redux';

import NoteEdit from 'components/Notes/components/NoteEdit';
import NoteNew from 'components/Notes/components/NoteNew';
import { getEventFromURL } from 'data/reducers';

import DimensionBoxPlot from '../../DimensionBoxPlot';
import Styles from '../TeamCompareSelector.module.scss';
import withRouter from 'routes/withRouter';
import { isTeamLead } from 'utils';

const connector = connect((state, ownProps) => ({
  event: getEventFromURL(state, ownProps)?.toJS?.(),
}));

const CommentsForm = ({
  letter,
  team,
  phaseId,
  isZScore,
  viewed,
  comment,
  onCancel,
  commentsEnabled,
  internalCommentsEnabled,
  event,
}) => {
  if (!team) return null;

  const truncateWithEllipses = (text, max) => {
    return text.substr(0, max - 1) + (text.length > max ? '...' : '');
  };

  const isTeamLeadAndQualitativeMode =
    isTeamLead() && event?.judging_ux_mode === 'qualitative_only_mode';

  return (
    <div className={Styles.AddCommentModalForm}>
      <div className={cx(Styles.TeamInfo, 'd-flex flex-row')}>
        <img
          className={Styles.OptionTeamMarker}
          src={DimensionBoxPlot[`MarkerImage${letter}`]}
          alt="Team marker"
          width={40}
        />
        <div className={cx(Styles.OptionTitleWrapper, 'd-flex flex-column team-name')}>
          <div className={cx(Styles.OptionTitle, Styles[`OptionTitle_${team.letter}`])}>
            {truncateWithEllipses(team.name, 52)}{' '}
          </div>
          <div className={Styles.OptionDescription}>{truncateWithEllipses(team.category, 56)}</div>
        </div>
      </div>
      <div
        className={cx(
          Styles.OptionsStatsWrapper,
          'd-flex flex-row justify-content-between align-items-center mt-2 ps-4 pe-4',
        )}
      >
        {!isTeamLeadAndQualitativeMode && (
          <>
            <div className={cx('d-flex flex-row align-items-center')}>
              <div className={Styles.OptionDescriptionBig}>
                {isZScore ? team.sigma?.toFixed?.(2) : team.rank_strength}{' '}
              </div>
              <div className={cx('ms-2', Styles.OptionDescription)}>
                {isZScore ? 'Valid Sigmas\u2122' : 'Rank Strength'}{' '}
              </div>
            </div>

            <div className={Styles.OptionDescription}>
              Valid Rank&trade;:
              <b>{team.rank}</b>
            </div>
          </>
        )}
      </div>
      {!comment && (
        <NoteNew
          viewed={viewed}
          internalCommentsEnabled={internalCommentsEnabled}
          isJudge={false}
          isOrganizer={true}
          phaseId={phaseId}
          teamId={team?.id}
          onCancel={onCancel}
        />
      )}
      {comment && (
        <NoteEdit
          initialValues={{ content: comment.content, internal: comment.type === 'internal' }}
          isJudge={false}
          isOrganizer={true}
          phaseId={phaseId}
          teamId={team?.id}
          commentsEnabled={commentsEnabled}
          viewed={viewed}
          onCancel={onCancel}
          {...comment}
        />
      )}
    </div>
  );
};

export default withRouter(connector(CommentsForm));
