import { useReloadNotes, useVoteNote } from 'data/actions/notes';
import NoteBody from './NoteBody';
import NoteControls from './NoteControls';

const NoteRead = ({
  commentsEnabled,
  edit = false,
  isOrganizer,
  isTeam,
  judgeId,
  onDelete,
  onEdit,
  phaseId,
  teamId,
  ...note
}) => {
  const vote = useVoteNote();
  const reloadNotes = useReloadNotes(teamId, phaseId, judgeId, isOrganizer);
  async function handleUpVote() {
    await vote(teamId, phaseId, { id: note.id, judgeId, value: note.vote === 1 ? 0 : 1 });
    reloadNotes();
  }

  async function handleDownVote() {
    await vote(teamId, phaseId, { id: note.id, judgeId, value: note.vote === -1 ? 0 : -1 });
    reloadNotes();
  }

  return (
    <>
      <NoteBody {...note} />

      <NoteControls
        commentsEnabled={commentsEnabled}
        isTeam={isTeam}
        isOrganizer={isOrganizer}
        judgeId={judgeId}
        onDelete={onDelete}
        onDownvote={handleDownVote}
        onEdit={onEdit}
        onUpvote={handleUpVote}
        phaseId={phaseId}
        teamId={teamId}
        {...note}
      />
    </>
  );
};

NoteRead.displayName = 'NoteRead';
export default NoteRead;
