import { Button, Dropzone, Form } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// @ts-expect-error
import mime from 'mime-types';

import { required as requiredVal } from 'components/FormFields/validations';
import { getEnvVar } from 'config';
import { ArtifactItem } from 'data/features/artifactItemsTypes';

import Styles from './FileInput.module.scss';

export type FileInputProps = {
  acceptedFileType: string;
  disabled?: boolean;
  helpText?: string;
  id?: string;
  label?: string;
  name: string;
  onDelete(value: ArtifactItem): void;
  onFinishUpload(name: string, url: string): void;
  onNoSession: () => void;
  required?: boolean;
  signingUrlQueryParams: Record<string, string>;
  value?: ArtifactItem;
};

const GB = 1024 * 1024 * 1024;
const MAX_FILE_SIZE = Number(getEnvVar('REACT_APP_MAX_FILE_SIZE', (10 * GB).toString()));

const FileInput = ({
  acceptedFileType,
  disabled,
  helpText,
  id,
  label,
  name,
  onDelete,
  onFinishUpload,
  onNoSession,
  required,
  signingUrlQueryParams,
  value,
}: FileInputProps) => {
  const { t } = useTranslation();
  const { control, formState, setError, setValue } = useFormContext();

  const showValidation =
    !!formState?.touchedFields?.[name] || !!formState?.dirtyFields?.[name] || formState.isSubmitted;

  const isInvalid = !!formState?.errors?.[name] && showValidation;
  const isValid = !formState?.errors?.[name] && showValidation;
  const rules = required ? { validate: { required: requiredVal } } : {};

  const acceptedFormats = acceptedFileType
    .split(',')
    .reduce<Record<string, string[]>>((accum, format) => {
      // Patch to filter only kml files in file selector
      if (format === 'application/vnd.google-earth.kml+xml') {
        return { ...accum, 'application/vnd.google-earth.kml+xml': ['.kml'] };
      }
      // Patch to filter only geojson files in file selector
      if (format === 'application/geo+json') {
        return { ...accum, 'application/geo+json': ['.geojson'] };
      }
      // Patch for rtf files
      if (format === 'application/rtf') {
        return { ...accum, 'application/rtf,text/rtf': [] };
      }
      return { ...accum, [format]: [] };
    }, {});

  const handleError = (err: string | Error) => {
    const sessionExpired = String(err).includes('401');
    const message = sessionExpired
      ? t('auth.teams_sign_up.error.no_session')
      : t('auth.teams_sign_up.error.upload');

    if (sessionExpired) onNoSession();

    setError(name, { type: 'custom', message });
    setValue(name, value?.url, { shouldDirty: true, shouldTouch: true });
  };

  const handleDelete = () => {
    if (!value) return;

    onDelete(value);
  };

  const handleDropRejected = (rejectedFiles: any[]) => {
    const acceptedFileExtensions = mime.extensions[acceptedFileType].join(', ') || acceptedFileType;
    const maxGBSize = MAX_FILE_SIZE / GB;
    const rejectedText =
      rejectedFiles[0].size > MAX_FILE_SIZE
        ? t('auth.teams_sign_up.error.max_size', { size: maxGBSize })
        : t('auth.teams_sign_up.error.file_type', { acceptedFileExtensions });
    setError(name, { type: 'custom', message: rejectedText });
    setValue(name, value?.url || null, { shouldDirty: true });
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={() => (
          <Form.Group className="mb-2">
            {label && (
              <Form.Label className="w-100" htmlFor={id || name}>
                {label}
              </Form.Label>
            )}
            {helpText && (
              <p className="d-print-none text-pre-wrap">
                <Form.Text muted>{helpText}</Form.Text>
              </p>
            )}
            {value && (
              <div className="d-flex align-items-center justify-content-end mb-2">
                <a
                  className="btn btn-link p-0"
                  href={value.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {value.file_name}
                </a>
                <Button variant="link-danger p-0 ms-3 me-2" onClick={handleDelete}>
                  <i className="fas fa-trash-alt m-0"></i>
                </Button>
              </div>
            )}
            <div
              className={cx('form-control p-0 border-0', Styles.FileInput, {
                'is-invalid': isInvalid,
                'is-valid': isValid,
              })}
            >
              {/* @ts-expect-error */}
              <Dropzone
                disabled={disabled}
                maxSize={MAX_FILE_SIZE}
                onFinish={(result) => {
                  onFinishUpload(name, result?.signedUrl.split('?')[0]);
                }}
                accept={acceptedFormats}
                multiple={false}
                onDropRejected={handleDropRejected}
                onError={handleError}
                signingUrlQueryParams={signingUrlQueryParams}
                uploadRequestHeaders={{
                  'x-amz-acl': 'private',
                }}
                // disableDragAndDrop
              />
            </div>
            {isInvalid && (
              <Form.Control.Feedback type="invalid">
                {String(formState.errors[name]?.message)}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        )}
      />
    </>
  );
};

export default FileInput;
