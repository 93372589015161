import GenericModal from 'components/GenericModal';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Loading from 'components/Loading';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { load } from 'data/actions/roleSummaries';
import {
  getActiveTeamCount,
  getEventFromURL,
  getInactiveTeamCount,
  getPhase,
  getSelectedPhase,
  getSelectedPhaseIdForCurrentEvent,
  getTeamSummaries,
  isLoadingRoleSummaries,
  isLoadingTeamRoles,
} from 'data/reducers';
import withRouter from 'routes/withRouter';
import { isNavigator, isTeamLead } from 'utils';
import { organizerNav } from 'utils/urls';
import { teamsCommunications } from 'utils/notifications';

import TeamsLayout from './components/TeamsLayout';
import { TeamsManageTable } from './components/TeamsManageTable';
import CommunicationsNotifyDropdown from './components/communications/CommunicationsNotifyDropdown';
import Triage from './components/Triage/Triage';

function TeamManage({ params, selectedPhaseId, load, teams, teamsLoading, phase, selectedPhase }) {
  const reloading = useRef(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isEmptyTeamsModalOpened, setIsEmptyTeamsModalOpened] = useState(false);
  const [isOptionDisabledModalOpened, setIsOptionDisabledModalOpened] = useState(false);

  const handleOptionDisabledClose = () => setIsOptionDisabledModalOpened(false);
  const handleEmptyTeamsClose = () => setIsEmptyTeamsModalOpened(false);
  const reloadTeams = () => load('teams', selectedPhaseId);

  useEffect(() => {
    reloading.current = false;
    reloadTeams();
  }, [selectedPhaseId, phase]);

  return (
    <TeamsLayout
      toolbar={
        isNavigator() || isTeamLead() ? null : (
          <div className="text-end flex-grow-1 d-flex justify-content-end mb-3 me-3 align-items-center">
            <Triage entity={'teams'} selected={selectedTeams} reload={reloadTeams} />
            <CommunicationsNotifyDropdown
              afterSend={() => {
                setSelectedTeams([]);
                reloading.current = true;
                setTimeout(reloadTeams, 2e3);
              }}
              communications={teamsCommunications}
              extraData={{ team_profile_ids: selectedTeams.map((j) => j.id) }}
              sendTo="teams"
              shouldSend={(template) => {
                const isValidSelection = Boolean(selectedTeams.length);
                if (
                  isValidSelection &&
                  !phase.get('feedback_enabled') &&
                  template?.slug === 'team_feedback_available'
                ) {
                  setIsOptionDisabledModalOpened(true);
                  return false;
                }
                if (!isValidSelection) {
                  setIsEmptyTeamsModalOpened(true);
                  return false;
                }
                return true;
              }}
            />
          </div>
        )
      }
    >
      {!isNavigator() && !isTeamLead() && (
        <>
          <GenericModal
            show={isEmptyTeamsModalOpened}
            confirmButton="Accept"
            title="No teams selected"
            body="Please select some teams from the table to perform the action"
            onConfirm={handleEmptyTeamsClose}
            onCancel={handleEmptyTeamsClose}
          ></GenericModal>
          <GenericModal
            show={isOptionDisabledModalOpened}
            confirmButton="Acknowledge"
            title="Feedback not available to teams"
            body={
              <>
                First enable the <strong>Feedback & scores available to teams</strong> option on the{' '}
                <Link to={organizerNav.settings(params.event_id)}>settings screen</Link>
              </>
            }
            onConfirm={handleOptionDisabledClose}
            onCancel={handleOptionDisabledClose}
          ></GenericModal>
        </>
      )}
      {teamsLoading && !reloading.current ? (
        <Loading />
      ) : (
        <TeamsManageTable
          data={teams}
          eventId={params.event_id}
          teamsLoading={teamsLoading}
          selectedTeams={selectedTeams}
          onSelectedTeamsChange={setSelectedTeams}
          isPanelMatching={selectedPhase ? selectedPhase.matching_mode === 'panel_matching' : false}
        />
      )}
    </TeamsLayout>
  );
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const event = getEventFromURL(state, ownProps)?.toJS?.();
      const teams = getTeamSummaries(state, ownProps);
      const activeCount = getActiveTeamCount(state, ownProps);
      const inactiveCount = getInactiveTeamCount(state, ownProps);
      const loading = isLoadingRoleSummaries(state);
      const teamsLoading = isLoadingTeamRoles(state);
      const selectedPhase = getSelectedPhase(state, ownProps)?.toJS?.();
      const selectedPhaseId = getSelectedPhaseIdForCurrentEvent(state, ownProps);
      const phase = getPhase(state, selectedPhaseId);
      return {
        event,
        teams,
        activeCount,
        inactiveCount,
        loading,
        teamsLoading,
        selectedPhaseId,
        selectedPhase,
        phase,
      };
    },
    {
      load,
      notifyError,
      notifySuccess,
    },
  )(TeamManage),
);
