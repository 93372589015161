import i18n from 'config/i18n';
import { List, Map, Record } from 'immutable';
import moment from 'moment-timezone';
import { actionTypes as reduxFormActions } from 'redux-form';
import { reducer as form } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutablejs';
import { createSelector } from 'reselect';

import { isGovEnv } from 'config';
import { organizerNav } from 'utils/urls';

import artifactItemsSlice from 'data/features/artifactItems';
import authenticationSlice from 'data/features/authentication';
import teamDraftsSlice from 'data/features/teamDrafts';
import { getStatusesByValue, getStausValueWithOrder } from 'utils/statuses';
import {
  fromJudgeInvitations as judgeInvitationsActions,
  fromUsers as userActions,
} from '../actions';
import applicationRevisionRequestsSlice from '../features/applicationRevisionRequests';
import contentsSlice from '../features/contents';
import documentsSlice from '../features/documents';
import eventsSlice from '../features/events';
import judgeEvaluationsSlice from '../features/judgeEvaluations';
import { keyIn } from './_utils';
import apiKeys from './apiKeys';
import artifactItems, * as fromArtifactItems from './artifactItems';
import artifacts, * as fromArtifacts from './artifacts';
import businessDisciplines, * as fromBusinessDisciplines from './businessDisciplines';
import categories, * as fromCategories from './categories';
import communications from './communications';
import contents, * as fromContents from './contents';
import documents, * as fromDocuments from './documents';
import evaluations, * as fromEvaluations from './evaluations';
import events, * as fromEvents from './events';
import intros from './intros';
import invitationTemplates, * as fromInvitationTemplates from './invitationTemplates';
import jobFunctions, * as fromJobFunctions from './jobFunctions';
import judgeInvitations, * as fromJudgeInvitations from './judgeInvitations';
import judgeProfiles, * as fromJudgeProfiles from './judgeProfiles';
import notes from './notes';
import notifications, * as fromNotifications from './notifications';
import phaseSummaries, * as fromPhaseSummaries from './phase_summaries';
import phases, * as fromPhases from './phases';
import recommendations, * as fromRecommendations from './recommendations';
import reports from './reports';
import * as fromRoleAssignments from './roleAssignments';
import roleSummaries, * as fromRoleSummaries from './roleSummaries';
import rubrics, * as fromRubrics from './rubrics';
import scores, * as fromScores from './scores';
import selectedPhases, * as fromSelectedPhases from './selectedPhases';
import { support } from './support';
import surveys from './surveys';
import teamDrafts, * as fromTeamDrafts from './teamDrafts';
import teams, * as fromTeams from './teams';
import userNotifications, * as fromUserNotifications from './userNotifications';
import users, * as fromUsers from './users';

const { teamAssignments, judgeAssignments } = fromRoleAssignments;

const reduxFormExtensions = {
  updatePassword: (state, { type }) => {
    // blow away updatePasssword form data
    return type === userActions.LOAD_SUCCESS ? undefined : state;
  },
  addJudgeForm: (state, { type }) => {
    // blow away addJudgeForm form data
    return type === judgeInvitationsActions.SAVE_SUCCESS ? undefined : state;
  },
  addJudgesListForm: (state, { type }) => {
    // blow away addJudgesListForm form data
    return type === judgeInvitationsActions.SAVE_SUCCESS ? undefined : state;
  },
  /*
   * Horrible hack that unchecks all checkboxes from the emails fields.
   * This is needed because the `onChange` function inside a FieldsArray component
   * does not get fired.
   */
  profileForm: (state, action) => {
    if (
      action.type === reduxFormActions.CHANGE &&
      action.meta.field.indexOf('.primary_email') >= 0 &&
      action.payload === true
    ) {
      const fieldIndex = Number(action.meta.field.match(/\d+/)[0]);
      const newEmails = state.getIn(['values', 'user_emails']).map((email, index) => {
        if (index === fieldIndex) {
          return email.set('primary_email', true);
        } else {
          return email.set('primary_email', false);
        }
      });
      return state.setIn(['values', 'user_emails'], newEmails);
    }
    return state;
  },
};

const reducer = () =>
  combineReducers({
    users,
    events,
    teams,
    contents,
    documents,
    jobFunctions,
    businessDisciplines,
    judgeProfiles,
    judgeInvitations,
    invitationTemplates,
    notifications,
    userNotifications,
    teamAssignments,
    judgeAssignments,
    scores,
    recommendations,
    evaluations,
    phases,
    selectedPhases,
    rubrics,
    phaseSummaries,
    categories,
    roleSummaries,
    reports,
    artifactItems,
    artifacts,
    form: form.plugin(reduxFormExtensions),
    notes,
    intros,
    communications,
    surveys,
    support,
    teamDrafts,
    apiKeys,
    // RTK slices
    authenticationSlice,
    judgeEvaluationsSlice,
    applicationRevisionRequestsSlice,
    eventsSlice,
    contentsSlice,
    documentsSlice,
    teamDraftsSlice,
    artifactItemsSlice,
  });

const emptyList = List();
const emptyMap = Map();

const AssignedRole = Record({
  id: '',
  name: '',
  categories: List(),
  match_quality: null,
  assigned_count: 0,
  assign_to_id: '',
  scored: false,
  match_method: '',
});

const getUsersState = (state) => state.get('users');
const getTeamsState = (state) => state.get('teams');
const getPhasesState = (state) => state.get('phases');
const getJudgesState = (state) => state.get('judgeProfiles');
const getSelectedPhasesState = (state) => state.get('selectedPhases');
const getRecommendationsState = (state) => state.get('recommendations');
const getEvaluationsState = (state) => state.get('evaluations');
const getArtifactItemsState = (state) => state.get('artifactItems');
const getArtifactsState = (state) => state.get('artifacts');
export const getScoresState = (state) => state.get('scores');
export const getTeamDrafts = (state) => state.get('teamDrafts');

const getEventsData = (state) => fromEvents.getList(state.get('events'));

const getCategoriesLookup = (state) => fromCategories.getLookup(state.get('categories'));

const getArtifactsLookup = (state) => fromArtifacts.getLookup(state.get('artifacts'));

const getEventIdFromURL = (_, { params }) => params.event_id;
const getTeamIdFromURL = (_, { params }) => params.team_id;
const getJudgeIdFromURL = (_, { params }) => params.judge_profile_id;
const getEvaluationIdFromURL = (_, { params }) => params.evaluation_id;
const getEventSlugFromURL = (_, { params }) => params.slug;
const getTeamIdFromURLOrQuery = createSelector(
  getTeamIdFromURL,
  (_, props) => props.query,
  (teamId, query) => teamId || query?.team_id,
);

const dynamicFieldName = (id, prefix) => (prefix ? `${prefix}.${id}` : id);

const buildMatchingCategories = (catIds, categoriesLookup, prefix = '', event) => {
  const categoriesList = catIds.map((catId) => categoriesLookup.get(catId));
  const byParentCategory = categoriesList && categoriesList.groupBy((cat) => cat.get('parent'));
  return (
    byParentCategory &&
    byParentCategory
      .map((childrenCategories, parentId) => {
        const parent = categoriesLookup.get(parentId);
        let title = parent.get('name');

        if (title === 'Stage of Venture' && event)
          title = i18n.t('event.stage_of_venture_label', title);
        if (title === 'Technology Type' && event) title = i18n.t('event.tech_type_label', title);

        return Map({
          title,
          required: true,
          id: parentId,
          children: childrenCategories,
          name: dynamicFieldName(parentId, prefix),
        });
      })
      .valueSeq()
  );
};

export const getEventFromURL = createSelector(
  (state) => state.get('events'),
  getEventIdFromURL,
  fromEvents.getEvent,
);

export const getCurrentEventIsActive = createSelector(getEventFromURL, (event) =>
  event.get('active'),
);

const getRoleDetailsForProfile = (allDetails, allowReadOnly) => {
  const details = allowReadOnly
    ? allDetails
    : allDetails.filter((detail) => detail.get('field_type') !== 'read_only_content');

  return details
    .map((detail) => detail.set('name', dynamicFieldName(detail.get('id'), 'details')))
    .sortBy((jd) => jd.get('sort_order'));
};

const isJudgeSignUpScreen = (state, ownProps) => {
  return ownProps.judge_sign_up_screen;
};

export const getJudgeProfileDynamicFieldsForCurrentEvent = createSelector(
  getEventFromURL,
  getCategoriesLookup,
  isJudgeSignUpScreen,
  (rawEvent, categoriesLookup, isJudgeSignUp) => {
    if (!rawEvent || !rawEvent.get('judge_details')) return { data: List(), byName: {} };
    const categoryIds = rawEvent && rawEvent.get('matching_categories', emptyList);
    const matchingCategories =
      categoryIds && buildMatchingCategories(categoryIds, categoriesLookup, 'categories', rawEvent);
    const judge_matching_categories =
      matchingCategories &&
      matchingCategories.map((cat) => {
        const newCat = cat.set('field_type', 'checkbox_group');
        return newCat.set('sort_options', true);
      });

    const judgeDetails = getRoleDetailsForProfile(rawEvent.get('judge_details'), isJudgeSignUp);

    const allFields = judgeDetails.concat(judge_matching_categories);

    const dynamicFields = {
      data: allFields,
      byName: allFields.reduce((memo, field) => ({ ...memo, [field.get('name')]: field }), {}),
    };

    return dynamicFields;
  },
);

const JUDGE_SIGN_UP_REQUIRED_FIELDS = [
  'first_name',
  'last_name',
  'email',
  'password',
  'password_confirmation',
  'primary_job_function',
  'business_discipline',
  'early_stage_investing_experience',
  'fund_raising_experience',
  'exits',
  'failures',
];

export const getJudgeSignUpFields = createSelector(
  getJudgeProfileDynamicFieldsForCurrentEvent,
  (fields) => {
    if (fields.data.count()) {
      JUDGE_SIGN_UP_REQUIRED_FIELDS.forEach(
        (field) => (fields.byName[field] = Map({ required: true })),
      );
    }

    return fields;
  },
);

export const getJudgesLookup = (state) => state.getIn(['judgeProfiles', 'data']);

export const getJudgeProfileFromURL = createSelector(
  getJudgeIdFromURL,
  getJudgesLookup,
  (judgeId, judges) => {
    return judges.get(judgeId);
  },
);

export const getJudgeProfileInitialValues = createSelector(
  getJudgeProfileFromURL,
  getCategoriesLookup,
  (judge, categoriesLookup) => {
    const categoriesList = judge?.get('categories');
    let panels = judge?.get('panels')?.toJS?.() || {};
    panels = Object.keys(panels).reduce((p, key) => ({ ...p, [key]: panels[key]?.join('|') }), {});
    return (
      categoriesList &&
      Map({
        business_discipline_uuid: judge.get('business_discipline_uuid'),
        primary_job_function_uuid: judge.get('primary_job_function_uuid'),
        details: judge.get('details'),
        categories: categoriesList.groupBy((catId) => {
          const category = categoriesLookup.get(catId);
          return category && category.get('parent');
        }),
        panels,
      })
    );
  },
);

export const getSelectedPhaseIdForCurrentEvent = createSelector(
  getSelectedPhasesState,
  getEventIdFromURL,
  fromSelectedPhases.getSelectedPhaseId,
);

export const getSelectedPhaseIdForCurrentTeam = createSelector(
  getSelectedPhasesState,
  getTeamIdFromURL,
  fromSelectedPhases.getSelectedPhaseIdForTeam,
);

export const getSelectedPhase = createSelector(
  getSelectedPhaseIdForCurrentEvent,
  (state) => state,
  (phaseId, state) => getPhase(state, phaseId),
);

const getURLPathname = (state, ownProps) => {
  return ownProps.location && ownProps.location.pathname;
};

export const getPhasesForCurrentTeam = createSelector(
  getPhasesState,
  getTeamIdFromURL,
  getURLPathname,
  (phasesList, teamId, location) => {
    const inSelfEvaluation = location.includes('self_evaluation');
    const getPhases = inSelfEvaluation
      ? fromPhases.getPhasesForTeamSelfEvaluation
      : fromPhases.getPhasesForTeam;
    return getPhases(phasesList, teamId);
  },
);

export const getScoreForCurrentTeamSelfEvaluation = createSelector(
  getScoresState,
  getTeamIdFromURL,
  getSelectedPhaseIdForCurrentTeam,
  fromScores.getSelfScoreForPhase,
);

export const getAuthCredentials = createSelector(getUsersState, (usersList) =>
  fromUsers.getAuthInfo(usersList).filter(keyIn('userId')),
);

export const getLoginError = (state) => fromUsers.getAuthInfo(state.get('users')).get('error');

export const getLoginLoading = (state) => fromUsers.getAuthInfo(state.get('users')).get('loading');

export const getUserById = (state, id) => fromUsers.getUserById(state.get('users'), id);

export const getCurrentUser = createSelector(getUsersState, (state) =>
  fromUsers.getCurrentUser(state),
);

export const getCurrentUserId = createSelector(
  getAuthCredentials,
  (cred) => cred && cred.get('userId'),
);

export const getInitialValuesFromUser = createSelector(getCurrentUser, (user) => ({
  ...user.toJS(),
}));

export const getUserFullName = (user, lastNameFirst = false) => {
  if (!user) return;
  return lastNameFirst
    ? `${user.get('last_name')}, ${user.get('first_name')}`
    : `${user.get('first_name')} ${user.get('last_name')}`;
};

const emptyEmail = Map({ email: '' });

export const getUserPrimaryEmail = (user) => {
  if (!user) return '';
  const primary_email = user
    .get('user_emails')
    .find((e) => e.get('primary_email'), emptyEmail)
    .get('email');

  return primary_email || user.get('email');
};

export const getUsersLookup = (state) => fromUsers.getUsersLookup(state.get('users'));

const getTeamsLookup = (state) => fromTeams.getLookup(state.get('teams'));

export const getTeamProfileFromURL = createSelector(
  getTeamIdFromURL,
  getTeamsLookup,
  (teamId, teamsList) => {
    return teamsList.get(teamId);
  },
);

export const getJudgesFromCurrentEvent = createSelector(
  getEventIdFromURL,
  getJudgesState,
  (eventId, judgesState) => {
    const judges = fromJudgeProfiles.getByEvent(judgesState, eventId);
    return judges.map((judge) => judge && judge.set('name', getUserFullName(judge)));
  },
);

export const getTeamPrimaryContact = createSelector(getTeamProfileFromURL, (team) => {
  if (!team || !team.get('members')) return Map();

  const primaryContact = team.get('members').find((m) => m.get('team_primary'));

  if (!primaryContact) return Map();
  return Map({
    fullName: getUserFullName(primaryContact),
    email: primaryContact.get('email'),
  });
});

export const getTeamWithCategories = (team, categoriesLookup) => {
  if (team && team.get('name') && team.get('details') && team.get('categories')) {
    const categoriesList = team.get('categories');
    return (
      categoriesList &&
      Map({
        id: team.get('id'),
        details: team.get('details'),
        first_name: team.get('first_name'),
        last_name: team.get('last_name'),
        members: team.get('members'),
        name: team.get('name'),
        orphan_details: team.get('orphan_details'),
        prefix: team.get('prefix'),
        panels: team.get('panels'),
        categories: categoriesList.reduce((memo, categoryId) => {
          const category = categoriesLookup.get(categoryId);
          return memo.set(category.get('parent'), categoryId);
        }, Map()),
      })
    );
  }
};

export const getTeamProfileInitialValues = createSelector(
  getTeamProfileFromURL,
  getCategoriesLookup,
  getTeamWithCategories,
);

const isTeamSignUpScreen = (state, ownProps) => {
  return ownProps.team_sign_up_screen;
};

export const getTeamProfileDynamicFieldsForCurrentEvent = createSelector(
  getEventFromURL,
  getCategoriesLookup,
  isTeamSignUpScreen,
  (rawEvent, categoriesLookup, teamSignUpScreen) => {
    // TODO: Refactor similar code on getJudgeProfile...
    if (!rawEvent || !rawEvent.get('team_details')) return { data: List(), byName: {} };
    const categoryIds = rawEvent && rawEvent.get('matching_categories', emptyList);
    const matchingCategories =
      categoryIds && buildMatchingCategories(categoryIds, categoriesLookup, 'categories', rawEvent);
    const team_matching_categories =
      matchingCategories &&
      matchingCategories.map((cat) => {
        const newCat = cat.set('field_type', 'dropdown');
        return newCat.set('sort_options', true);
      });

    const teamDetails = getRoleDetailsForProfile(rawEvent.get('team_details'), teamSignUpScreen);

    const allFields = teamDetails.concat(team_matching_categories);

    const dynamicFields = {
      data: allFields,
      byName: allFields.reduce((memo, field) => ({ ...memo, [field.get('name')]: field }), {}),
    };

    return dynamicFields;
  },
  {
    memoizeOptions: {
      equalityCheck: () => false,
    },
  },
);

export const getApplicationRevisionFields = createSelector(
  getTeamProfileDynamicFieldsForCurrentEvent,
  (fields) => {
    return {
      ...fields,
      data: fields?.data?.unshift(
        Map({
          title: i18n.t('event.team_profile_name_label', 'Name'),
          id: 'name',
        }),
      ),
    };
  },
);

const getProfileOrphanDetails = (roleSelectorFn) =>
  createSelector(roleSelectorFn, (role) => {
    if (!role || !role.get('orphan_details')) return Map();

    return role.get('orphan_details').filter((detail) => !!detail.get('value'));
  });

export const getJudgeProfileOrphanDetails = getProfileOrphanDetails(getJudgeProfileFromURL);
export const getTeamProfileOrphanDetails = getProfileOrphanDetails(getTeamProfileFromURL);

export const getTeamsFromCurrentEvent = createSelector(
  getEventIdFromURL,
  getTeamsState,
  (eventId, teamsList) => fromTeams.getByEvent(teamsList, eventId),
);

const byRoleInEvent = (roleName, roleId, eventId) => (role) =>
  role.get('name') === roleName &&
  role.get('event_id') === eventId &&
  role.get('role_specific_id') === roleId;

export const userIsJudge = createSelector(
  getCurrentUser,
  getEventIdFromURL,
  getJudgeIdFromURL,
  (user, eventId, judgeId) => {
    return (
      !!user && user.get('event_roles', emptyList).some(byRoleInEvent('Judge', judgeId, eventId))
    );
  },
);

export const getJudgeRole = createSelector(
  getCurrentUser,
  getEventIdFromURL,
  getJudgeIdFromURL,
  (user, eventId, judgeId) => {
    return (
      user && user.get('event_roles', emptyList).find(byRoleInEvent('Judge', judgeId, eventId))
    );
  },
);

export const userIsTeamMember = createSelector(
  getCurrentUser,
  getEventIdFromURL,
  getTeamIdFromURLOrQuery,
  getEventSlugFromURL,
  (state) => state,
  (user, eventId, roleId, slug, state) => {
    if (!user) return false;
    eventId = eventId || getEventBySlug(state, slug)?.get('id');
    return user.get('event_roles', emptyList).find(byRoleInEvent('Team', roleId, eventId));
  },
);

export const userIsTeamEditorOrPrimary = createSelector(userIsTeamMember, (role) =>
  ['editor', 'primary'].includes(role?.get('role')),
);

export const userIsOrganizerOrNavigator = createSelector(
  getCurrentUser,
  getEventIdFromURL,
  (user, eventId) => {
    return (
      !!user &&
      user
        .get('event_roles', emptyList)
        .some(
          (role) =>
            (role.get('name') === 'Organizer' || role.get('name') === 'Navigator') &&
            role.get('event_id') === eventId,
        )
    );
  },
);

export const userIsOrganizerNavigatorOrTeamLead = createSelector(
  getCurrentUser,
  getEventIdFromURL,
  (user, eventId) => {
    return (
      !!user &&
      user
        .get('event_roles', emptyList)
        .some(
          (role) =>
            (role.get('name') === 'Organizer' ||
              role.get('name') === 'Navigator' ||
              role.get('name') === 'TeamLead') &&
            role.get('event_id') === eventId,
        )
    );
  },
);

export const getUsersLoading = (state) => Boolean(state.getIn(['users', 'loading']));

export const getCurrentUserEventIds = createSelector(getCurrentUser, (user) =>
  user
    ? user
        .get('event_roles', emptyList)
        .map((role) => role.get('event_id'))
        .toSet()
        .toList()
    : List(),
);

export const getCurrentUserEvents = createSelector(
  getCurrentUserEventIds,
  getEventsData,
  (ids, eventsMap) => ids.map((id) => eventsMap.get(String(id))).filter((event) => !!event),
);

const byNameAlpha = (a, b) =>
  a.get('name').toLowerCase().localeCompare(b.get('name').toLowerCase());

export const getCurrentUserActiveEvents = createSelector(getCurrentUserEvents, (eventsList) =>
  eventsList.filter((ev) => ev.get('active')).sort(byNameAlpha),
);
export const getCurrentUserArchivedEvents = createSelector(getCurrentUserEvents, (eventsList) =>
  eventsList.filter((ev) => !ev.get('active')).sort(byNameAlpha),
);

export const isLoadingEvents = (state) => fromEvents.isLoading(state.get('events'));

export const getEvent = (state, eventId) => fromEvents.getEvent(state.get('events'), eventId);

export const getEventBySlug = (state, slug) => fromEvents.getEventBySlug(state.get('events'), slug);

export const getEventCookiesConsent = (state, id) =>
  fromEvents.getCookiesConsent(state.get('events'), id);

export const getEventContent = (state, eventId, pageGroup, pageName, fieldName) =>
  fromContents.getEventContent(state.get('contents'), eventId, pageGroup, pageName, fieldName);

const getDocumentsState = (state) => state.get('documents');

export const getDocumentsForJudges = createSelector(
  getDocumentsState,
  getEventIdFromURL,
  fromDocuments.getActiveDocumentsForJudges,
);

export const getDocumentsForTeams = createSelector(
  getDocumentsState,
  getEventIdFromURL,
  fromDocuments.getActiveDocumentsForTeams,
);

export const getTeamById = (state, id) => fromTeams.getTeamById(state.get('teams'), id);

export const getTeamBySlug = (state, slug) => fromTeams.getTeamBySlug(state.get('teams'), slug);

export const isLoadingTeam = (state) => fromTeams.isLoading(state.get('teams'));

export const getMissingTeamIds = (state, ids) => {
  const teamInStore = fromTeams.hasTeam(state.get('teams'));
  return ids.filter((id) => !teamInStore(id));
};

export const getJobFunctions = (state) => fromJobFunctions.getArray(state.get('jobFunctions'));

export const getBusinessDisciplines = (state) =>
  fromBusinessDisciplines.getArray(state.get('businessDisciplines'));

export const getCurrentUserProfiles = createSelector(
  getCurrentUser,
  getEventsData,
  (user, events) => {
    const roles = user && user.get('event_roles');
    const roleList =
      roles && events && events.count() > 0
        ? roles.map((role) =>
            role
              .set('event_name', events.getIn([role.get('event_id'), 'name']))
              .set('event_active', events.getIn([role.get('event_id'), 'active'])),
          )
        : List();
    return roleList;
  },
);

const byEventNameAlpha = (a, b) =>
  a.get('event_name').toLowerCase().localeCompare(b.get('event_name').toLowerCase());

export const getCurrentUserProfilesGrouped = createSelector(getCurrentUserProfiles, (profiles) => {
  return profiles
    .filter(
      (profile) =>
        profile.get('name') !== 'Organizer' &&
        profile.get('name') !== 'Navigator' &&
        profile.get('name') !== 'TeamLead',
    )
    .sort(byEventNameAlpha)
    .groupBy((profile) => (profile.get('event_active') ? 'active' : 'inactive'));
});

export const isLoadingJudgeRoles = (state) =>
  fromRoleSummaries.isLoadingJudgeRoles(state.get('roleSummaries'));

export const isLoadingTeamRoles = (state) =>
  fromRoleSummaries.isLoadingTeamRoles(state.get('roleSummaries'));

export const isLoadingJudgeAssignments = (state) =>
  fromRoleAssignments.isLoadingRoleAssignments(state.get('judgeAssignments'));

export const isLoadingTeamAssignments = (state) =>
  fromRoleAssignments.isLoadingRoleAssignments(state.get('teamAssignments'));

const getTeamAssignments = (state) => state.get('teamAssignments');
const getJudgeAssignments = (state) => state.get('judgeAssignments');

const getAssignedTeamsByPhaseForJudge = createSelector(
  getJudgeAssignments,
  getSelectedPhaseIdForCurrentEvent,
  getJudgeIdFromURL,
  (assignments, phaseId, judgeId) =>
    fromRoleAssignments.getAssignmentsByPhaseForJudge(assignments, { judgeId, phaseId }),
);

const getAssignedJudgesByPhaseForTeam = createSelector(
  getTeamAssignments,
  getSelectedPhaseIdForCurrentEvent,
  getTeamIdFromURL,
  (assignments, phaseId, teamId) =>
    fromRoleAssignments.getAssignmentsByPhaseForTeam(assignments, { teamId, phaseId }),
);

export const getJudgesAssignedToCurrentTeam = createSelector(
  getJudgesLookup,
  getTeamIdFromURL,
  getCategoriesLookup,
  getAssignedJudgesByPhaseForTeam,
  (judges, teamId, categoriesLookup, teamAssignmentsList) => {
    if (!teamAssignmentsList.length || !judges.count()) {
      return emptyList;
    }

    return teamAssignmentsList.map((assignment) => {
      const judgeId = assignment.get('role');

      const judge = judges.get(judgeId);

      const categoriesList = assignment
        .get('categories', emptyList)
        .map((catId) => categoriesLookup.getIn([catId, 'name']));

      return new AssignedRole({
        id: assignment.get('id'),
        name: getUserFullName(judge),
        assign_to_id: assignment.get('role'),
        match_quality: assignment.get('match_quality'),
        assigned_count: assignment.get('assigned_count'),
        categories: categoriesList,
        scored: judge.get('scored'),
        match_method: judge.get('match_method'),
      });
    });
  },
);

export const getTeamsAssignedToCurrentJudge = createSelector(
  getTeamsLookup,
  getJudgeIdFromURL,
  getCategoriesLookup,
  getAssignedTeamsByPhaseForJudge,
  (teamsLookup, judgeId, categoriesLookup, judgeAssignmentsList) => {
    if (!judgeAssignmentsList.length || !teamsLookup.count()) {
      return emptyList;
    }

    return judgeAssignmentsList.map((assignment) => {
      const teamId = assignment.get('role');

      const team = teamsLookup.get(teamId);

      const categoriesList = assignment
        .get('categories', emptyList)
        .map((catId) => categoriesLookup.getIn([catId, 'name']));

      return new AssignedRole({
        id: assignment.get('id'),
        name: team && team.get('name'),
        assign_to_id: assignment.get('role'),
        match_quality: assignment.get('match_quality'),
        assigned_count: assignment.get('assigned_count'),
        categories: categoriesList,
        scored: team.get('scored'),
        match_method: team.get('match_method'),
      });
    });
  },
);

const getTopFifteenSuggestionsInPhaseForJudge = createSelector(
  getSelectedPhaseIdForCurrentEvent,
  getJudgeIdFromURL,
  getJudgeAssignments,
  (phaseId, judgeId, assignments) =>
    fromRoleAssignments.getTopFifteenSuggestionsInPhaseForJudge(assignments, { phaseId, judgeId }),
);

const getTopFifteenSuggestionsInPhaseForTeam = createSelector(
  getSelectedPhaseIdForCurrentEvent,
  getTeamIdFromURL,
  getTeamAssignments,
  (phaseId, teamId, assignments) =>
    fromRoleAssignments.getTopFifteenSuggestionsInPhaseForTeam(assignments, { phaseId, teamId }),
);

export const getJudgeSuggestionsForCurrentTeam = createSelector(
  getJudgesLookup,
  getCategoriesLookup,
  getTopFifteenSuggestionsInPhaseForTeam,
  (judgesLookup, categoriesLookup, top15) => {
    return top15.map((suggestion) => {
      const judge = judgesLookup.get(suggestion.get('role'), emptyMap);

      const categoriesList = suggestion
        .get('categories')
        .map((catId) => categoriesLookup.getIn([catId, 'name']));

      return {
        id: suggestion.get('id'),
        name: getUserFullName(judge),
        categories: categoriesList,
        assign_to_id: suggestion.get('role'),
        matches_count: suggestion.get('matches_count'),
        match_quality: suggestion.get('match_quality'),
      };
    });
  },
);

export const getTeamSuggestionsForCurrentJudge = createSelector(
  getTeamsLookup,
  getCategoriesLookup,
  getTopFifteenSuggestionsInPhaseForJudge,
  (teamsLookup, categoriesLookup, top15) => {
    return top15.map((suggestion) => {
      const team = teamsLookup.get(suggestion.get('role'), emptyMap);
      const categoriesList = suggestion
        .get('categories')
        .map((catId) => categoriesLookup.getIn([catId, 'name']));
      return {
        id: suggestion.get('id'),
        name: team.get('name'),
        categories: categoriesList,
        assign_to_id: suggestion.get('role'),
        matches_count: suggestion.get('matches_count'),
        match_quality: suggestion.get('match_quality'),
      };
    });
  },
);

const getCategoriesFromRole = (role) =>
  createSelector(
    role,
    getCategoriesLookup,
    getEventFromURL,
    (roleFromUrl, categoriesLookup, event) => {
      if (!roleFromUrl || !categoriesLookup.count()) return {};

      const categoryNames = roleFromUrl.get('categories', emptyList).map((id) => {
        const category = categoriesLookup.get(id);
        const parent = categoriesLookup.get(category.get('parent'), emptyMap);

        let parentName = parent.get('name');

        if (parentName === 'Stage of Venture' && event)
          parentName = i18n.t('event.stage_of_venture_label', parentName);
        if (parentName === 'Technology Type' && event)
          parentName = i18n.t('event.tech_type_label', parentName);

        return { name: category.get('name'), parent: parentName };
      });

      const byParent = categoryNames.groupBy((category) => category.parent);

      return byParent.toJS();
    },
    {
      memoizeOptions: {
        equalityCheck: () => false,
      },
    },
  );

export const getTeamCategories = getCategoriesFromRole(getTeamProfileFromURL);
export const getJudgeCategories = getCategoriesFromRole(getJudgeProfileFromURL);

export const getCurrentJudgeMiniProfile = createSelector(
  getJudgeProfileFromURL,
  getUsersLookup,
  (judge, usersLookup) => {
    if (!judge) return {};
    const user = usersLookup.get(judge.get('user'));
    if (!user) return {};

    return { fullName: getUserFullName(user), email: getUserPrimaryEmail(user) };
  },
);

const getRoleSummariesState = (state) => state.get('roleSummaries');

const getTeamSummariesBySelectedPhase = createSelector(
  getRoleSummariesState,
  getSelectedPhaseIdForCurrentEvent,
  fromRoleSummaries.getTeamSummariesBySelectedPhase,
);

const getJudgeSummariesBySelectedPhase = createSelector(
  getRoleSummariesState,
  getSelectedPhaseIdForCurrentEvent,
  fromRoleSummaries.getJudgeSummariesBySelectedPhase,
);

const SHOW_LAST_NAME_FIRST = true;

const getTeamStatus = (teamRole, statuses) => {
  if (
    teamRole.applicationRevisionRequest &&
    (teamRole.applicationRevisionRequest.deadline === null ||
      moment(teamRole.applicationRevisionRequest.deadline).isAfter(moment()))
  )
    return 'Revising Materials';
  if (teamRole.assignedJudges === 0) return 'Unmatched';
  const originalStatus = teamRole.status;
  const status = statuses[originalStatus]?.label;
  return (teamRole.progress === 0 || teamRole.progress < 100) && originalStatus === null
    ? 'Judging'
    : status;
};

export const getTeamSummaries = createSelector(
  getTeamSummariesBySelectedPhase,
  getJudgesLookup,
  getTeamsLookup,
  getEventIdFromURL,
  getSelectedPhase,
  (teamRoleSummaries, judgeProfilesMap, teamsMap, eventId, phase) => {
    if (!teamRoleSummaries) return null;

    const statusesWithOrder =
      getStausValueWithOrder(phase?.get('status_framework')?.toJS?.()) || {};
    const statuses = getStatusesByValue(phase?.get('status_framework')?.toJS?.()) || {};

    return teamRoleSummaries.map((summary) => {
      const roleId = summary.get('role');
      const judgeNames = summary.get('original_assigned_roles').map((judge) => {
        return {
          profileId: judge.get('id'),
          name: getUserFullName(judge, SHOW_LAST_NAME_FIRST),
          scored: judge.get('scored'),
          matchMethod: judge.get('match_method'),
          locked: judge.get('scored') || judge.get('match_method') === 'Manual',
        };
      });

      const panels = summary.get('panels')?.toJS() || {};
      const keys = Object.keys(panels);

      let panel = '';
      if (keys.length > 0 && panels[keys[0]]) {
        panel = panels[keys[0]];
        panel = `P${panel.padStart(3, '0')}`;
      }

      const teamRole = {
        id: roleId,
        name: teamsMap.getIn([roleId, 'name']),
        active: summary.get('active'),
        prefix: summary.get('prefix'),
        profile: roleId,
        assignedRolesCount: summary.get('assigned_roles').count(),
        assignedRoles: judgeNames.toArray(),
        matchQuality: summary.get('match_quality'),
        teamScores: summary.get('team_scores'),
        assignedJudges: summary.get('assigned_judges'),
        missingScores: summary.get('missing_scores'),
        progress: summary.get('progress'),
        latestMessage: summary.get('latest_message')?.toJS(),
        status: summary.get('status'),
        statusOrder: statusesWithOrder[summary.get('status')],
        viewed: summary.get('viewed'),
        panel,
        categories: summary.get('categories'),
        finalRank: summary.get('final_rank'),
        validRank: summary.get('valid_score_valid_rank'),
        applicationRevisionRequest: summary.get('application_revision_request')?.toJS(),
        url: organizerNav.profile(eventId, 'teams', roleId),
      };

      teamRole.status = getTeamStatus(teamRole, statuses);
      return teamRole;
    });
  },
);

export const getActiveJudgeCount = createSelector(
  getJudgeSummariesBySelectedPhase,
  (judgeRoleSummaries) =>
    judgeRoleSummaries.filter((roleSummary) => roleSummary.get('active')).length,
);

export const getInactiveJudgeCount = createSelector(
  getJudgeSummariesBySelectedPhase,
  (judgeRoleSummaries) =>
    judgeRoleSummaries.filter((roleSummary) => !roleSummary.get('active')).length,
);

const getJudgeStatus = (judgeRole) => {
  if (judgeRole.assignedTeams === 0) return 'Unmatched';
  if (judgeRole.progress === 0) return 'Not Started';
  return judgeRole.progress < 100 ? 'Judging' : 'Finished';
};

export const getJudgeSummaries = createSelector(
  getJudgeSummariesBySelectedPhase,
  getJudgesLookup,
  getTeamsLookup,
  (judgeRoleSummaries, judgeProfilesMap, teamsMap) => {
    if (!judgeRoleSummaries) return null;

    return judgeRoleSummaries.map((summary) => {
      const roleId = summary.get('role');
      const teamRoles = summary.get('original_assigned_roles').map((team) => {
        return {
          profileId: team.get('id'),
          name: team.get('name'),
          scored: team.get('scored'),
          matchMethod: team.get('match_method'),
          locked: team.get('scored') || team.get('match_method') === 'Manual',
        };
      });

      const panels = summary.get('panels')?.toJS() || {};
      const keys = Object.keys(panels);

      let panel = '';
      if (keys.length > 0 && panels[keys[0]]) {
        panel = panels[keys[0]]?.map?.((p) => `P${p.padStart(3, '0')}`).join(' | ');
      }

      const judgeRole = {
        id: roleId,
        name:
          judgeProfilesMap && getUserFullName(judgeProfilesMap.get(roleId), SHOW_LAST_NAME_FIRST),
        active: summary.get('active'),
        profile: roleId,
        assignedRolesCount: summary.get('assigned_roles').count(),
        assignedRoles: teamRoles.toArray(),
        matchQuality: summary.get('match_quality'),
        assignedTeams: summary.get('assigned_teams'),
        judgedTeams: summary.get('judged_teams'),
        missingTeams: summary.get('missing_teams'),
        progress: summary.get('progress'),
        latestMessage: summary.get('latest_message')?.toJS(),
        panel,
      };

      judgeRole.status = getJudgeStatus(judgeRole);

      return judgeRole;
    });
  },
);

export const getActiveTeamCount = createSelector(getTeamSummaries, (summaries) => {
  if (summaries) return summaries.filter((summary) => summary.active).length;
});

export const getInactiveTeamCount = createSelector(getTeamSummaries, (summaries) => {
  if (summaries) return summaries.filter((summary) => !summary.active).length;
});

export const isLoadingRoleSummaries = (state) =>
  fromRoleSummaries.isLoadingRoleSummaries(state.get('roleSummaries'));

export const getJudgeProfile = (state, id) =>
  fromJudgeProfiles.getJudgeProfileById(state.get('judgeProfiles'), id);

const getTeamClassificationName = (classifications, selectedValues) => {
  return (classifications || [])
    .reduce((acc, classification) => {
      const id = String(classification.get('id'));
      if (selectedValues && selectedValues.has(id)) {
        let value;
        if (classification.has('children')) {
          value = classification
            .get('children')
            .find((c) => c.get('id') === selectedValues.get(id))
            .get('name');
        } else {
          value = selectedValues.get(id);
        }

        if (value) {
          return [...acc, value];
        }
      }
      return acc;
    }, [])
    .join(', ');
};

export const getTeamClassificationInEvent = (teamId, eventId) => (state) => {
  const team = getTeamById(state, teamId);
  const event = getEvent(state, eventId);
  return (
    team &&
    event &&
    getTeamClassificationName(event.get('team_classifications'), team.get('classifications'))
  );
};

export const artifactsToDocumentList = (artifactsList, event) =>
  artifactsList
    .filter((value) => !!value)
    .map((value, artifact_id) => {
      const artifactFound = event
        .get('artifacts')
        .find((artifact) => artifact.get('id') === Number(artifact_id));
      return Map({
        name: artifactFound.get('judge_title'),
        value,
      });
    })
    .toList();

export const getEvaluationFromURL = createSelector(
  getEvaluationsState,
  getEvaluationIdFromURL,
  fromEvaluations.getById,
);

export const getTeamIdFromEvaluationSelectedInURL = createSelector(
  getEvaluationFromURL,
  (evaluation) => evaluation && evaluation.get('team'),
);

const getEvaluationsForSelectedPhase = createSelector(
  getEvaluationsState,
  getSelectedPhaseIdForCurrentEvent,
  fromEvaluations.getByPhase,
);

const getTeamIdsFromEvaluationsInSelectedPhase = createSelector(
  getEvaluationsState,
  getSelectedPhaseIdForCurrentEvent,
  fromEvaluations.getTeamIdsByPhase,
);

export const getSelectOptionsForTeamsWithCompletedEvaluations = createSelector(
  getTeamIdsFromEvaluationsInSelectedPhase,
  getTeamsLookup,
  getCategoriesLookup,
  getTeamIdFromEvaluationSelectedInURL,
  getSelectedPhaseIdForCurrentEvent,
  getEvaluationsState,
  (
    teamIdsFromEvaluations,
    teamsLookup,
    categoriesLookup,
    teamIdFromURL,
    phaseId,
    evaluationsState,
  ) => {
    const teamIds = teamIdsFromEvaluations;
    const teamFromUrlLoaded = teamIdFromURL && teamsLookup.get(teamIdFromURL) != null;

    if (teamFromUrlLoaded && !teamIds.includes(teamIdFromURL)) {
      teamIds.push(teamIdFromURL);
    }
    return teamIds
      .map((teamId) => {
        const team = teamsLookup.get(teamId);
        const category = team
          .get('categories')
          .map((catId) => categoriesLookup.getIn([catId, 'name']))
          .join(', ');
        const evaluation = fromEvaluations.getByPhaseAndTeam(evaluationsState, phaseId, teamId);
        let overallScore = evaluation && evaluation.get('overall_score');
        overallScore = overallScore && overallScore.toFixed(2);
        return {
          ...evaluation?.toJSON?.(),
          id: teamId,
          name: team.get('name'),
          category,
          overallScore,
        };
      })
      .sort((a, b) => b.overallScore - a.overallScore);
  },
);

export const getTeamApplicationsInEvent = (state, eventId) => {
  const event = getEvent(state, eventId);
  const eventTeams = fromTeams.getListByEvent(state.get('teams'), eventId);

  return eventTeams.map((team) =>
    Map({
      name: team.get('name'),
      id: team.get('id'),
      category:
        event &&
        getTeamClassificationName(event.get('team_classifications'), team.get('classifications')),
      documents: event && artifactsToDocumentList(team.get('artifacts'), event),
    }),
  );
};

export const isLoadingJudgeProfiles = (state) =>
  fromJudgeProfiles.isLoading(state.get('judgeProfiles'));

export const isLoadingJudgeInvitations = (state) =>
  fromJudgeInvitations.isLoading(state.get('judgeInvitations'));

export const getJudgeInvitations = (state, eventId) =>
  fromJudgeInvitations.getList(state.get('judgeInvitations'), eventId);

export const getSelectedJudgeInvitation = (state, index) => {
  fromJudgeInvitations.getSingle(state.get('judgeInvitations'), index);
};

export const getJudgeInvitationsPreview = (state) =>
  fromJudgeInvitations.getListPreview(state.get('judgeInvitations'));

export const getInvitationTemplate = (state) =>
  fromInvitationTemplates.getInvitationTemplate(state.get('invitationTemplates'));

export const getNotificationsArray = (state) =>
  fromNotifications.getSeq(state.get('notifications')).toArray();

export const getScoreEvent = (state, scoreId) => {
  const score = fromScores.getSingle(state.get('scores'), scoreId);
  return score && getEvent(state, score.get('event_id'));
};

export const getRubric = (state, phaseId) => {
  const phase = fromPhases.getById(getPhasesState(state), phaseId);
  return phase && fromRubrics.getById(state.get('rubrics'), phase.get('rubric_id'));
};

export const getIsLoadingRubric = (state) => {
  return fromRubrics.getIsLoading(state.get('rubrics'));
};

const byPriorityRank = (a, b) => a.get('priority_rank') - b.get('priority_rank');
const byUpdatedAtDate = (a, b) => new Date(a.get('updated_at')) - new Date(b.get('updated_at'));

export const getActiveRecommendations = createSelector(
  getRecommendationsState,
  (state, teamId) => teamId,
  (recState, teamId) => {
    const recs = fromRecommendations.getByTeam(recState, teamId);
    return recs && recs.filter((r) => r.get('status') === 'active').sort(byPriorityRank);
  },
);

export const getArchivedRecommendations = createSelector(
  getRecommendationsState,
  (state, teamId) => teamId,
  (recState, teamId) => {
    const recs = fromRecommendations.getByTeam(recState, teamId);
    return recs && recs.filter((r) => r.get('status') !== 'active').sort(byUpdatedAtDate);
  },
);

export const getIsLoadingRecommendations = (state) =>
  fromRecommendations.isLoading(state.get('recommendations'));

export const getSelectedPhaseId = (state, eventId) =>
  fromSelectedPhases.getSelectedPhaseId(state.get('selectedPhases'), eventId);

export const getPhase = (state, phaseId) => fromPhases.getById(getPhasesState(state), phaseId);

export const getPhaseSummary = (state, phaseId) => {
  const phase = getPhase(state, phaseId);
  return (
    phase && fromPhaseSummaries.getById(state.get('phaseSummaries'), phase.get('phase_summary_id'))
  );
};

export const getUserNotifications = createSelector(
  (state) => state.get('userNotifications'),
  getCurrentUser,
  (state, user) => user && fromUserNotifications.getAll(state, user.get('id')),
);

export const getUserNotificationsCount = (state) =>
  state.getIn(['userNotifications', 'total_count']);
export const getUserUnseenNotificationsCount = (state) =>
  state.getIn(['userNotifications', 'unseen_count']);
export const getUserNotificationsOffset = (state) => state.getIn(['userNotifications', 'offset']);

export const getIsLoadingEvaluations = createSelector(
  getEvaluationsState,
  fromEvaluations.isLoading,
);

export const getResultsForSelectedPhase = createSelector(
  getEventIdFromURL,
  getEvaluationsForSelectedPhase,
  getTeamsLookup,
  getCategoriesLookup,
  getSelectedPhase,
  (eventId, evaluationsList, teamsLookup, categoryLookup, phase) => {
    const statuses = getStausValueWithOrder(phase?.get('status_framework')?.toJS?.()) || {};

    return evaluationsList.map((ev) => {
      const team = teamsLookup.get(ev.get('team'));
      const categoryIds = team?.get('categories');
      const categoryName =
        categoryIds && categoryIds.map((id) => categoryLookup.getIn([id, 'name'], '')).join('\n');

      const panel = team?.get('panel') ? `P${team?.get('panel').padStart(3, '0')}` : '';

      return {
        id: ev.get('id'),
        team_name: team?.get('name'),
        team_prefix: team?.get('prefix'),
        team_url: organizerNav.scores(eventId, ev.get('id')),
        team_description: team.get('description'),
        category: categoryName,
        ...ev.toJSON(),
        overall_score: ev.get('overall_score'),
        status: ev.get('status'),
        statusOrder: statuses[ev.get('status')],
        panel,
      };
    });
  },
);

export const getEvaluationsCountForSelectedPhase = createSelector(
  getEventIdFromURL,
  getEvaluationsForSelectedPhase,
  getTeamsLookup,
  getCategoriesLookup,
  (eventId, evaluationsList, teamsLookup, categoryLookup) => {
    return evaluationsList.length;
  },
);

export const getEvaluation = (state, id) => fromEvaluations.getById(state.get('evaluations'), id);

export const getEvaluationByPhaseAndTeam = (state, phaseId, teamId) =>
  fromEvaluations.getByPhaseAndTeam(state.get('evaluations'), phaseId, teamId);

export const getPhasesByEventId = (state, eventId) =>
  fromPhases.getPhasesByEventId(getPhasesState(state), eventId);

export const getEventPhasesList = createSelector(getPhasesByEventId, (phasesList) =>
  phasesList ? phasesList.map((p) => ({ ...p, status_framework: p.status_framework.toJS() })) : [],
);

export const isLoadingPhases = (state) => fromPhases.isLoading(getPhasesState(state));

export const isLoadingResultsStatus = (state, phaseId) => {
  return fromPhases.isLoadingResultsStatus(getPhasesState(state), phaseId);
};

export const isLoadingTeamsStatus = (state, phaseId) => {
  return fromPhases.isLoadingTeamsStatus(getPhasesState(state), phaseId);
};

export const isLoadingJudgesStatus = (state, phaseId) => {
  return fromPhases.isLoadingJudgesStatus(getPhasesState(state), phaseId);
};

export const getResultsStatus = (state, phaseId) => {
  return fromPhases.getResultsStatus(getPhasesState(state), phaseId);
};

export const getTeamsStatus = (state, phaseId) => {
  return fromPhases.getTeamsStatus(getPhasesState(state), phaseId);
};

export const getJudgesStatus = (state, phaseId) => {
  return fromPhases.getJudgesStatus(getPhasesState(state), phaseId);
};

export const getPhasesAvailableForTeams = (state, eventId) => {
  const phasesList = fromPhases.getPhasesByEventId(getPhasesState(state), eventId);
  const available = phasesList.filter((phase) => {
    const phaseResults = getResultsStatus(state, phase.get('id'));
    return (
      phase.get('feedback_enabled') && phaseResults && phaseResults.get('evaluations_completed') > 0
    );
  });
  return available
    ? available.map((phase) => ({ id: phase.get('id'), name: phase.get('name') })).toArray()
    : [];
};

export const getSelfScoreForPhase = (state, teamId, phaseId) => {
  return fromScores.getSelfScoreForPhase(state.get('scores'), teamId, phaseId);
};

export const getEvaluationScoresAsOptions = createSelector(
  getScoresState,
  getEvaluationIdFromURL,
  fromScores.getEvaluationScoresAsOptions,
);

export const getEvaluationScores = createSelector(
  getScoresState,
  getEvaluationIdFromURL,
  fromScores.getEvaluationScores,
);

export const getEvaluationScoresByPhaseAndTeam = createSelector(
  getScoresState,
  (state, props) => {
    const evaluation = getEvaluationByPhaseAndTeam(
      state,
      props.selectedPhaseId,
      props.params.team_id,
    );
    return evaluation && evaluation.get('id');
  },
  fromScores.getEvaluationScores,
);

export const getIsLoadingEvaluationScoresByPhaseAndTeam = createSelector(
  getScoresState,
  (state, props) => {
    const evaluation = getEvaluationByPhaseAndTeam(
      state,
      props.selectedPhaseId,
      props.params.team_id,
    );
    return evaluation && evaluation.get('id');
  },
  fromScores.getIsLoadingEvaluationScores,
);

export const getEvaluationScoresAsOptionsByPhaseAndTeam = createSelector(
  getEvaluationScoresByPhaseAndTeam,
  (scoresList) =>
    scoresList.map((score) => ({ value: score.get('id'), label: `Judge #${score.get('index')}` })),
);

export const getScore = createSelector(
  getScoresState,
  (_, id) => id,
  (scoresList, id) => fromScores.getSingle(scoresList, id),
);

export const getScoreFromURL = createSelector(
  getScoresState,
  (_, { params: { score_id } }) => score_id,
  fromScores.getSingle,
);

const getTypeForArtifactDocument = (fileType) => {
  switch (fileType) {
    case 'rtf':
    case 'docx':
      return 'doc';
    case 'mp4':
    case 'mov':
      return 'video';
    case 'pptx':
      return 'ppt';
    case 'xlsx':
      return 'xls';
    default:
      return fileType;
  }
};

const mimeTypeForFileType = (fileType) => {
  switch (fileType) {
    case 'pdf':
      return 'application/pdf';
    case 'rtf':
      return 'application/rtf,text/rtf';
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'mp4':
      return 'video/mp4';
    case 'mov':
      return 'video/quicktime';
    case 'pptx':
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    case 'xlsx':
      return 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    default:
      return fileType;
  }
};

const VALID_URL_PREFIX = /^https?:\/\//i;

const buildDocument = (nameField) => (artifactItem, artifact) => {
  const type =
    artifact.get('type') === 'url' ? 'web' : getTypeForArtifactDocument(artifact.get('file_type'));
  let urlValue = artifactItem?.get('url');

  if (
    urlValue &&
    !VALID_URL_PREFIX.test(urlValue) &&
    artifact.get('type') !== 'pdf_team_report' &&
    artifact.get('artifact_type') !== 2
  ) {
    urlValue = 'http://' + urlValue;
  }

  return {
    id: artifact.get('id'),
    type,
    name: artifact.get(nameField),
    required: artifact.get('required'),
    instructions: artifact.get('instructions'),
    file_type: mimeTypeForFileType(artifact.get('file_type')),
    file_name: artifactItem?.get('file_name'),
    value: urlValue,
    team_details: artifact.get('team_details')?.toJS?.(),
    content: artifact.get('content'),
    active: artifact.get('active'),
  };
};

const buildDocumentForTeamsAndOrganizers = buildDocument('teams_and_organizers_name');

export const getTeamDocumentsFromEvaluationSelectedInURL = createSelector(
  getTeamIdFromEvaluationSelectedInURL,
  getArtifactsLookup,
  getArtifactItemsState,
  (teamId, artifactsLookup, artifactItemsState) => {
    const items = fromArtifactItems.byTeam(artifactItemsState, teamId);

    return items.map((item) => {
      const artifact = artifactsLookup.get(String(item.get('artifact')));
      return buildDocumentForTeamsAndOrganizers(item, artifact);
    });
  },
);

export const getDocuments = (state, teamId) => {
  const items = fromArtifactItems.byTeam(getArtifactItemsState(state), teamId);

  return items.map((item) => {
    const artifact = getArtifactsLookup(state).get(String(item.get('artifact')));
    return buildDocumentForTeamsAndOrganizers(item, artifact);
  });
};

export const getDocumentsByEvaluation = (state, evaluationId) => {
  const items = fromArtifactItems.byJudgeEvaluation(getArtifactItemsState(state), evaluationId);

  return items.map((item) => {
    const artifact = getArtifactsLookup(state).get(String(item.get('artifact')));
    return buildDocumentForTeamsAndOrganizers(item, artifact);
  });
};

export const getArtifactItemsLoading = (state, teamId) => {
  return getArtifactItemsState(state).getIn(['loading', teamId]);
};

export const getItemFromArtifact = (artifact, items) => {
  return items.find((item) => item.get('artifact') === artifact.get('id'));
};

export const getTeamDocumentsForEvent = createSelector(
  getEventIdFromURL,
  getTeamIdFromURL,
  getArtifactsLookup,
  getArtifactsState,
  getArtifactItemsState,
  (_, props) => props.teamId,
  (eventId, teamId, artifactsLookup, artifactsState, artifactItemsState) => {
    const artifactsList = fromArtifacts.byEvent(artifactsState, eventId);
    const items = fromArtifactItems.byTeam(artifactItemsState, teamId);

    return artifactsList.map((artifact) => {
      const artifactItem = getItemFromArtifact(artifact, items);
      return buildDocumentForTeamsAndOrganizers(artifactItem, artifact);
    });
  },
);

export const getEventArtifacts = createSelector(
  getEventFromURL,
  getArtifactsState,
  (event, state) => {
    const artifactsList = event.get('artifacts') || [];
    return artifactsList
      .map((artifact) => buildDocumentForTeamsAndOrganizers(null, artifact))
      .filter((a) => a.active);
  },
);

export const getIsLoadingTeams = (state) => fromTeams.isLoading(state);

const getTeamIdFromCurrentScore = (state, props) => {
  const score = getScoreFromURL(state, props);
  return score && score.get('team_id');
};

const getTeamWithCategoryName = (
  teamsList,
  categoriesList,
  teamId,
  parentCategoryNameFilter = null,
) => {
  const team = teamId && teamsList.get(teamId);
  const categoryIds = team && team.get('categories');

  const categoryParentName = (category) => {
    return (
      category.get('parent') &&
      categoriesList.find((c) => c.get('id') === category.get('parent')).get('name')
    );
  };

  const filteredCategories = parentCategoryNameFilter
    ? categoriesList.filter((c) => categoryParentName(c) === parentCategoryNameFilter)
    : categoriesList;

  const categoryName =
    categoryIds && categoryIds.map((id) => filteredCategories.getIn([id, 'name'], '')).join(', ');
  return team && team.set('category', categoryName);
};

export const getTeamWithCategoryNameForCurrentScore = createSelector(
  getTeamsLookup,
  getCategoriesLookup,
  getTeamIdFromCurrentScore,
  getTeamWithCategoryName,
);

export const getTeamWithCategoryNameFromURL = createSelector(
  getTeamsLookup,
  getCategoriesLookup,
  getTeamIdFromURL,
  (state, props, parentCategoryNameFilter) => parentCategoryNameFilter,
  getTeamWithCategoryName,
);

export const getSelectedPhaseFromCurrentTeam = createSelector(
  getPhasesState,
  getSelectedPhaseIdForCurrentTeam,
  fromPhases.getById,
);

export const getSelectedPhasePerformanceEnabledForCurrentTeam = createSelector(
  getSelectedPhaseFromCurrentTeam,
  (phase) => (phase ? phase.get('performance_enabled') : null),
);

export const getUnavailableScoreContentForTeam = createSelector(
  (state) => state.get('contents'),
  getEventIdFromURL,
  (contentsState, eventId) =>
    fromContents.getEventContent(contentsState, eventId, 'Team', 'Scores Unavailable', 'Notice'),
);

export const getTeamCanEditProfile = createSelector(
  getEventFromURL,
  (event) => event.get('profile_enabled') && event.get('active'),
);

export const getCurrentUserLinkedinProfile = createSelector(getUsersState, (state) =>
  fromUsers.getCurrentUserLinkedinProfile(state),
);

export const getTeamIsActive = createSelector(
  getTeamSummariesBySelectedPhase,
  getTeamIdFromURL,
  (teamRoleSummaries, teamId) => {
    const team = teamRoleSummaries.find((teamSummary) => teamSummary.get('role') === teamId);
    if (!team) {
      return false;
    }

    return !!team.get('active');
  },
);

export const getJudgeIsActive = createSelector(
  getJudgeSummariesBySelectedPhase,
  getJudgeIdFromURL,
  (judgeRoleSummaries, judgeId) => {
    const judge = judgeRoleSummaries.find((teamSummary) => teamSummary.get('role') === judgeId);
    if (!judge) {
      return false;
    }

    return !!judge.get('active');
  },
);

export const getIsLoadingArtifacts = (state) =>
  fromArtifacts.getIsLoadingArtifacts(state.get('artifacts'));

export const getFirstActivePhaseIdForCurrentTeam = createSelector(
  getPhasesForCurrentTeam,
  (phasesList = []) => {
    if (phasesList.length === 0) return null;

    const activePhases = phasesList.filter((p) => p.active);

    if (activePhases.length > 0) return activePhases[0].id;

    return phasesList[0].id;
  },
);

const UNKNOW_BUSINESS = 'Unknown business discipline';
const UNKNOW_EARLY_STAGE = 'Unknown early stage investing experience';
const UNKNOW_FUND_RAISING = 'Unknown fund raising experience';
const UNKNOW_JOB = 'Unknown job function';

export const getJudgeDescription = (state, id) => {
  const judge = getJudgeProfile(state, id)?.toJS();
  const jobFunction = judge?.primary_job_function || UNKNOW_JOB;
  const business = judge?.business_discipline || UNKNOW_BUSINESS;
  let description = `${jobFunction}, ${business}`;
  if (!isGovEnv()) {
    const earlyStage = judge?.early_stage_investing_experience || UNKNOW_EARLY_STAGE;
    const fundRaising = judge?.fund_raising_experience || UNKNOW_FUND_RAISING;
    description = `${description}, ${earlyStage}, ${fundRaising}`;
  }
  return description;
};

export const getIsLoadingEvaluationScores = createSelector(
  getScoresState,
  (_, evaluationId) => evaluationId,
  fromScores.getIsLoadingEvaluationScores,
);

export const getScoresByEvaluation = createSelector(
  (state) => state,
  (_, evaluationId) => evaluationId,
  (state, evaluationId) => {
    const scoreState = getScoresState(state);
    const scores = fromScores.getEvaluationScores(scoreState, evaluationId)?.map((score) => {
      score = score.toJSON();
      const judge = getJudgeProfile(state, score.judge)?.toJSON() || {};
      score.judge_name = [judge.first_name, judge.last_name].join(' ');
      return score;
    });
    return scores;
  },
);

export const getRolesInEvent = createSelector(
  getCurrentUser,
  getEventIdFromURL,
  (user, eventId) => {
    const roles =
      user
        .get('event_roles')
        .filter((role) => role.get('event_id') === eventId)
        .map((role) => [role.get('name'), true])
        .toJS() || [];
    return Object.fromEntries(roles);
  },
);

export const getPhaseNavigatorsViewResultsEnabled = createSelector(
  getSelectedPhaseIdForCurrentEvent,
  (state) => state,
  (phaseId, state) => getPhase(state, phaseId)?.get('navigators_view_results_enabled'),
);

export const getMembersByTeam = createSelector(
  getTeamsState,
  (_, teamId) => teamId,
  fromTeams.getMembers,
);

export const getTeamDraftsFromCurrentEvent = createSelector(
  getEventIdFromURL,
  getTeamDrafts,
  (eventId, teamDraftsState) => {
    const teamDraftsRecords = fromTeamDrafts.getByEvent(teamDraftsState, eventId);
    return teamDraftsRecords.map((teamDraft) => {
      const details = teamDraft.get('team_details_count')?.toJS();
      const artifacts = teamDraft.get('artifacts_count')?.toJS();

      return {
        id: teamDraft.get('id'),
        name: teamDraft.get('name'),
        email: teamDraft.get('email'),
        teamDetailsCount: details,
        artifactsCount: artifacts,
        latestMessage: teamDraft.get('latest_message')?.toJS(),
        updatedAt: teamDraft.get('updated_at'),
        createdAt: teamDraft.get('created_at'),
      };
    });
  },
);

export const getTeamDraftsIsLoading = createSelector(getTeamDrafts, (teamDraftsState) =>
  teamDraftsState?.get?.('loading'),
);

export const getCurrentEventIsQualitativeMode = createSelector(
  getEventFromURL,
  (event) => event.get('judging_ux_mode') === 'qualitative_only_mode',
);

export const getCurrentEventQualitativeMethodology = createSelector(getEventFromURL, (event) =>
  event.get('qualitative_methodology')?.toJS?.(),
);

export const getEventArtifactsAsOptions = createSelector(getEvent, (event) => {
  const artifacts = event.get('artifacts')?.toJS?.();
  return artifacts?.map((artifact) => ({
    label: artifact.teams_and_organizers_name,
    value: artifact.id,
  }));
});

export const getTeamsAsOptions = createSelector(getTeamSummaries, (teams) => {
  return teams?.map((teams) => ({
    label: teams.name,
    value: teams.id,
  }));
});

export default reducer;
