import { Col, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { round } from 'lodash';
import { compose, setDisplayName } from 'recompose';
import Title from '../Title';
import Styles from './Heat.module.scss';

const enhance = compose(setDisplayName('Criterion'));

const Criterion = enhance((props) => {
  const { text, className } = props;
  return <td className={className} dangerouslySetInnerHTML={{ __html: text }} />;
});

const DetailsReadOnly = (props) => {
  const { dimension, grades, readOnly } = props;
  const name = dimension.get('name');
  const dimensionWeight = dimension.get('weight');
  const subDimensions = dimension.get('subdimensions');
  return (
    <div
      data-test-id={`dimension-container-${dimension.get('id')}`}
      className={cx(Styles.dimension, Styles.noMarginRight)}
    >
      <Row>
        <Col md={8}>
          <Title component="h2" className={Styles.title}>
            {name}
          </Title>
        </Col>
        <Col md={4}>
          <h5 className={Styles.smallTitle}>
            Weight:{' '}
            <span className={'weight-number'}>{round(dimensionWeight * 100).toFixed(0)}%</span>
          </h5>
        </Col>
      </Row>
      <table>
        <thead>
          <tr className={Styles.columns}>
            <th />
            {grades.map((grade) => (
              <th key={grade.get('id')} className={cx(Styles.grade, Styles.innerCell)}>
                {grade.get('name')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {subDimensions.map((subD) => {
            const subdimensionId = subD.get('id');
            return (
              <tr key={`subD-${subdimensionId}`}>
                <td
                  className={cx(Styles.subDimension, Styles.boldLabel)}
                  dangerouslySetInnerHTML={{ __html: subD.get('name') }}
                />
                {subD.get('criteria').map((criterion) => {
                  return (
                    <Criterion
                      key={criterion.get('id')}
                      criteriaId={criterion.get('id')}
                      subdimensionId={subdimensionId}
                      text={criterion.get('description')}
                      className={cx(
                        {
                          [Styles.innerCell]: true,
                          'read-only': readOnly,
                        },
                        'criteria',
                        'criteria-color',
                      )}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

DetailsReadOnly.displayName = 'DetailsReadOnly';
export default DetailsReadOnly;
