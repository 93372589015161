import { isNavigator } from 'utils';
import { apiHeaders, apiURL } from '../services/config';

const _queryParams = (judgeId, isOrganizer) => {
  let query = '';
  query = judgeId ? `judge_profile_id=${judgeId}` : query;
  const nav = isNavigator() ? `navigator=1` : `organizer=1`;
  query = isOrganizer ? nav : query;
  return query;
};

export const load = (teamId, phaseId, judgeId, isOrganizer) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(
    `/phases/${phaseId}/teams/${teamId}/intros?${_queryParams(judgeId, isOrganizer)}`,
  ),
});

export const create = (teamId, phaseId, { content, isDefault, email, judgeId, organizer }) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({
    intro: {
      content,
      email,
      isDefault,
      judge_profile_id: judgeId,
      organizer,
    },
  }),
  endpoint: apiURL(`/phases/${phaseId}/teams/${teamId}/intros`),
});

export const update = (teamId, phaseId, { id, content, isDefault, email, judgeId, organizer }) => ({
  method: 'PUT',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({
    intro: {
      content,
      email,
      isDefault,
      judge_profile_id: judgeId,
      organizer,
    },
  }),
  endpoint: apiURL(`/phases/${phaseId}/teams/${teamId}/intros/${id}`),
});

export const del = (teamId, phaseId, id) => ({
  method: 'DELETE',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/teams/${teamId}/intros/${id}`),
});

export const view = (teamId, phaseId, id) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/teams/${teamId}/intros/${id}/view`),
});
