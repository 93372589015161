import PropTypes from 'prop-types';

import Comment from './Comment';

function Comments({ comments, onEditComment, team, phase, viewed }) {
  comments ??= [];

  let commentsEnabled = phase.comments_enabled;
  let internalCommentsEnabled = phase.comments_enabled;
  commentsEnabled = commentsEnabled && !viewed;

  const firstNoteInternal = comments.find((note) => note.type === 'internal');

  return comments.map((comment) => (
    <Comment
      key={`comment-${comment.id}`}
      commentsEnabled={commentsEnabled}
      internalCommentsEnabled={internalCommentsEnabled}
      comment={comment}
      onEditComment={onEditComment}
      team={team}
      isFirstInternalNote={firstNoteInternal?.id === comment.id}
    />
  ));
}

Comments.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
    }),
  ),
  commentsEnabled: PropTypes.bool,
};

export default Comments;
