import * as notificationsAPI from '../services/userNotifications';
import * as schemas from '../schema';
import * as usersAPI from '../services/users';

import { RSAA, getJSON } from 'redux-api-middleware';

import { normalize } from 'normalizr';

export const LOAD_RESULT = 'UserNotifications/LOAD_RESULT';
export const LOAD_MORE_RESULT = 'UserNotifications/LOAD_MORE_RESULT';
export const TOGGLE_SEEN_RESULT = 'UserNotifications/TOGGLE_SEEN_RESULT';
export const MARK_ALL_AS_SEEN_RESULT = 'UserNotifications/MARK_ALL_AS_SEEN_RESULT';

export const actions = {
  ADD: 'UserNotifications/ADD',
  LOAD_MORE: 'UserNotifications/LOAD_MORE',
  LOAD: 'UserNotifications/LOAD',
  MARK_ALL_AS_SEEN: 'UserNotifications/MARK_ALL_AS_SEEN',
  TOGGLE_SEEN: 'UserNotifications/TOGGLE_SEEN',
  REMOVE: 'UserNotifications/REMOVE',
  SEEN: 'UserNotifications/SEEN',
  LOAD_RESULT,
  LOAD_MORE_RESULT,
  TOGGLE_SEEN_RESULT,
  MARK_ALL_AS_SEEN_RESULT,
};

export const load = (userId) => ({
  [RSAA]: {
    ...usersAPI.loadUserNotifications(userId),
    types: [
      actions.LOAD,
      {
        type: LOAD_RESULT,
        payload: (_, __, res) =>
          getJSON(res).then((payload) => ({
            userId,
            total_count: payload.total_count,
            unseen_count: payload.unseen_count,
            offset: payload.offset,
            ...normalize(payload.notifications, [schemas.userNotification]),
          })),
      },
      LOAD_RESULT,
    ],
  },
});

export const loadMore = (userId, offset) => ({
  [RSAA]: {
    ...usersAPI.loadUserNotifications(userId, offset),
    types: [
      actions.LOAD_MORE,
      {
        type: LOAD_MORE_RESULT,
        payload: (_, __, res) =>
          getJSON(res).then((payload) => ({
            userId,
            total_count: payload.total_count,
            unseen_count: payload.unseen_count,
            offset: payload.offset,
            ...normalize(payload.notifications, [schemas.userNotification]),
          })),
      },
      LOAD_MORE_RESULT,
    ],
  },
});

export const add = (userId, data, totalCount, unseenCount) => {
  return {
    type: actions.ADD,
    payload: {
      userId,
      data: data.notification,
      totalCount,
      unseenCount,
    },
  };
};

export const seen = (userId, data, totalCount, unseenCount) => {
  return {
    type: actions.SEEN,
    payload: {
      userId,
      data: data.notification,
      totalCount,
      unseenCount,
    },
  };
};

export const remove = (userId, data, totalCount, unseenCount) => {
  return {
    type: actions.REMOVE,
    payload: {
      userId,
      data: data.notification,
      totalCount,
      unseenCount,
    },
  };
};

export const toggleSeen = (notificationId) => ({
  [RSAA]: {
    ...notificationsAPI.toggleSeen(notificationId),
    types: [
      actions.TOGGLE_SEEN,
      {
        type: TOGGLE_SEEN_RESULT,
        payload: (_, __, res) =>
          getJSON(res).then((payload) => ({
            userId: payload.notification.user_id,
            seen: payload.notification.seen,
            ...normalize(payload.notification, schemas.userNotification),
          })),
      },
      TOGGLE_SEEN_RESULT,
    ],
  },
});

export const markAllAsSeen = (userId) => ({
  [RSAA]: {
    ...notificationsAPI.markAllAsSeen(userId),
    types: [
      actions.MARK_ALL_AS_SEEN,
      {
        type: MARK_ALL_AS_SEEN_RESULT,
        payload: (_, __, res) =>
          getJSON(res).then(() => ({
            userId,
          })),
      },
      MARK_ALL_AS_SEEN_RESULT,
    ],
  },
});
