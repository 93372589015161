import { Button, Modal } from '@valid-eval/shared-react-components';

const NoteDeleteConfirmation = ({ show, onConfirmation, onCancel }) => {
  return (
    <Modal id={'delete-modal-confirmation'} show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please confirm you want to delete this comment.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" id={'delete-modal-confirmation-cancel'} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="success"
          id={'delete-modal-confirmation-confirm'}
          onClick={onConfirmation}
          type="submit"
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NoteDeleteConfirmation;
