import { Button, Col, Form, Modal, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { Email, Toggle } from 'components/FormFields';
import { email, required } from 'components/FormFields/validations';
import NoteFormInput from 'components/Notes/components/NoteFormInput';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isValid, reduxForm } from 'redux-form/immutable';
import Styles from './Intros.module.scss';

const formName = 'IntroForm';

const IntroModalForm = ({ name, description, show, onCancel, handleSubmit, loading, reset }) => {
  const isFormValid = useSelector(isValid(formName));

  useEffect(() => {
    reset();
  }, [show]);

  return (
    <Modal size="lg" show={show} onHide={onCancel} enforceFocus={false}>
      <Form onSubmit={handleSubmit} id={'intro-form'}>
        <Modal.Header className={cx(Styles.modalHeader, 'text-center')} closeButton>
          <span>Introduce Me</span>
        </Modal.Header>
        <Modal.Body className={Styles.body}>
          <Row className="mt-4 mb-4">
            <Col xs={6}>
              <strong>{name}</strong>
              <p>{description}</p>
            </Col>
            <Col xs={6}>
              <Email
                id={'intro-form-email'}
                label={'Email address, only visible to Team'}
                name={'email'}
                validate={[email, required]}
              />
            </Col>
          </Row>
          <Form.Label className={Styles.label} htmlFor="intro-content">
            Intro from you, visible to Selection Committee
          </Form.Label>
          <NoteFormInput onSubmit={handleSubmit} />
        </Modal.Body>
        <Modal.Footer className={Styles.modalFooter}>
          <div className={'d-flex flex-row align-items-center'} style={{ flex: 1 }}>
            <div className="me-3 ms-3 d-flex flex-row align-items-center">
              <Toggle name={'isDefault'} offColor="#666" />
            </div>
            <span className={Styles.modalFooterToggleLabel}>
              Save as my default <strong>Introduce Me</strong> message
            </span>
          </div>
          <div>
            <Button variant={'link'} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type={'submit'}
              variant={'success'}
              className="pe-4 ps-4"
              disabled={!isFormValid || loading}
            >
              SEND
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

IntroModalForm.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
};

IntroModalForm.displayName = 'IntroModalForm';

export default reduxForm({ enableReinitialize: true })(IntroModalForm);
