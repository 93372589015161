import { Button } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useEffect } from 'react';

import withRouter from 'routes/withRouter';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import TeamsAddModal from './TeamsAddModal';

type TeamsAddButtonProps = {
  phaseId: string;
  onTeamsAdded: () => void;
  activeTeams: number;
  areTeamsLoaded: boolean;
  query?: { showAddModal: boolean };
};

const TeamsAddButton = ({
  phaseId,
  onTeamsAdded,
  activeTeams,
  areTeamsLoaded,
  query,
}: TeamsAddButtonProps) => {
  const [isAddTeamsModalOpen, openAddTeamsModal, closeAddTeamsModal] = useBooleanFlag(false);

  useEffect(() => {
    if (activeTeams === 0 && areTeamsLoaded && query?.showAddModal) {
      openAddTeamsModal();
    }
  }, [areTeamsLoaded, activeTeams, query?.showAddModal]);

  function handleTeamsAdded() {
    closeAddTeamsModal();
    if (onTeamsAdded) onTeamsAdded();
  }

  return (
    <>
      <Button
        variant="success"
        className="me-2 d-flex flex-nowrap"
        onClick={openAddTeamsModal}
        data-test-id="add-teams-button"
      >
        <i className={cx('fas fa-plus')} />
        Add
      </Button>
      {isAddTeamsModalOpen && (
        <TeamsAddModal show={isAddTeamsModalOpen} onDismiss={handleTeamsAdded} phaseId={phaseId} />
      )}
    </>
  );
};

export default withRouter(TeamsAddButton);
