import { Dropdown } from '@valid-eval/shared-react-components';
import { noop } from 'lodash';
import { useRef, useState } from 'react';
import { connect } from 'react-redux';

import {
  getApplicationRevisionFields,
  getEventArtifacts,
  getSelectedPhaseIdForCurrentEvent,
} from 'data/reducers';
import { useCommunicationTemplates } from 'data/reducers/communications';
import withRouter from 'routes/withRouter';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';
import { allNotifications } from 'utils/notifications';

import ApplicationRevisionRequestModal from '../ApplicationRevisionRequest/ApplicationRevisionRequestModal';
import CommunicationsNotify from './CommunicationsNotify';

function CommunicationsNotifyDropdown({
  afterSend,
  children,
  communications,
  eventId,
  extraData,
  icon = 'fa-bullhorn',
  label = 'Notify',
  phaseId,
  sendTo,
  shouldSend,
  fields,
  artifacts,
}) {
  const [showModalForm, openModalForm, closeModalForm] = useBooleanFlag(false);
  const [
    showApplicationRevisionModal,
    openApplicationRevisionModal,
    closeApplicationRevisionModal,
  ] = useBooleanFlag(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const submitApplicationRevisionRequest = useRef(noop);

  const isApplicationRevisionRequestTemplate = (t) => t?.slug === 'team_revision_application';

  const templates = useCommunicationTemplates({
    templates: Object.keys(communications),
    eventId,
    phaseId,
  });

  const makeHandleEditTemplate = (template) => (e) => {
    e?.preventDefault();
    setCurrentTemplate(template);
    if (isApplicationRevisionRequestTemplate(template)) {
      submitApplicationRevisionRequest.current = noop;
      openApplicationRevisionModal();
    } else {
      openModalForm();
    }
  };

  const handleNext = (submit) => {
    submitApplicationRevisionRequest.current = submit;
    openModalForm();
  };

  const beforeSend = async () => {
    if (submitApplicationRevisionRequest.current) {
      await submitApplicationRevisionRequest.current();
      submitApplicationRevisionRequest.current = noop;
    }
  };

  const selectedTeams = extraData?.team_profile_ids?.length || 0;
  const selectedInvitations = extraData?.invitations_ids?.length || 0;
  const selectedJudges = extraData?.judge_profile_ids?.length || 0;
  const selectedDrafts = extraData?.drafts_ids?.length || 0;

  return (
    <>
      <ApplicationRevisionRequestModal
        artifacts={artifacts}
        close={closeApplicationRevisionModal}
        fields={fields}
        show={showApplicationRevisionModal}
        teamIds={extraData?.team_profile_ids}
        onNext={handleNext}
      />
      <CommunicationsNotify
        afterSend={afterSend}
        beforeSend={beforeSend}
        closeModalForm={(canceled = false) => {
          if (canceled && isApplicationRevisionRequestTemplate(currentTemplate)) {
            openApplicationRevisionModal();
            setCurrentTemplate(() => templates.find(isApplicationRevisionRequestTemplate));
          }
          closeModalForm();
        }}
        communications={communications}
        currentTemplate={currentTemplate}
        extraData={extraData}
        sendTo={sendTo}
        setCurrentTemplate={setCurrentTemplate}
        showModalForm={showModalForm}
        shouldSend={shouldSend}
        disableTemplateSelection
      />
      <Dropdown>
        <Dropdown.Toggle
          className="pe-3"
          disabled={
            !templates?.length ||
            (!selectedTeams && !selectedInvitations && !selectedJudges && !selectedDrafts)
          }
          id="notify-dropdown"
          variant="success"
        >
          <i className={`fa-solid ${icon}`} />
          {label}
          <i className="fa-solid fa-angle-down ms-3" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="mt-1" renderOnMount={true} style={{ width: 250 }}>
          <div className="pt-2 pt-1">
            {templates.map((t, index) => (
              <Dropdown.Item
                key={t.id}
                id={[`communication-${t.slug}`]}
                eventKey={index}
                onClick={makeHandleEditTemplate(t)}
              >
                {allNotifications[t.slug]?.displayName}
              </Dropdown.Item>
            ))}
            {children}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default withRouter(
  connect((state, ownProps) => {
    return {
      eventId: ownProps.params.event_id,
      phaseId: getSelectedPhaseIdForCurrentEvent(state, ownProps),
      artifacts: getEventArtifacts(state, ownProps)?.toJS?.() || [],
      fields: getApplicationRevisionFields(state, ownProps)?.data?.toJS(),
    };
  })(CommunicationsNotifyDropdown),
);
