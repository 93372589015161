import { getValidScoresFromClickedCriteria } from 'data/reducers/scores';
import { Component } from 'react';
import RubricDimension from '../RubricDimension';
import ScoreInfo from '../ScoreInfo';
import Styles from './RubricScoring.module.scss';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';
import {
  getCurrentEventIsQualitativeMode,
  getCurrentEventQualitativeMethodology,
  getEventFromURL,
} from 'data/reducers';

class RubricScoring extends Component {
  render() {
    const {
      rubric,
      score,
      scores,
      onContinueEvaluations,
      disabled,
      qualitativeMethodology,
      isQualitativeMode,
    } = this.props;
    const grades = rubric.get('grades');
    const dimensions = rubric.get('dimensions');
    const clickedCriteria = score.get('clicked_criteria');
    const scoresCount = scores.filter((value, dimensionId) => value && value !== 0).size;
    const dimensionCount = dimensions.size;

    return (
      <div>
        <div className={Styles.container}>
          <div className={Styles.dimensionsContainer}>
            {dimensions.map((dimension, index) => {
              const dimId = String(dimension.get('id'));
              return (
                <RubricDimension
                  dimension={dimension}
                  grades={grades}
                  clickedCriteria={clickedCriteria}
                  selectedCriteria={this.props.criterias.get(dimId)}
                  onChangeCriteria={this.props.onChangeCriteria.bind(this, dimId)}
                  onChangeScore={this.props.onChangeScore.bind(this, dimId)}
                  score={this.props.scores.get(dimId)}
                  key={`dim-${dimId}`}
                  coloredBackground={index % 2 === 0}
                  validScores={getValidScoresFromClickedCriteria(
                    grades,
                    dimension,
                    clickedCriteria,
                  )}
                  isQualitativeMode={isQualitativeMode}
                  qualitativeMethodology={qualitativeMethodology}
                />
              );
            })}
            {this.props.children}
          </div>
          <div className={Styles.infoContainer}>
            {!disabled && (
              <ScoreInfo
                scoresCount={scoresCount}
                totalScoresCount={dimensionCount}
                criteriaCount={clickedCriteria.count()}
                scoreValue={score.get('score')}
                complete={score.get('complete')}
                onContinue={onContinueEvaluations}
                clicksPercent={score.get('clicks_percent')}
                completePercent={score.get('complete_percent')}
                isQualitativeMode={isQualitativeMode}
                qualitativeMethodology={qualitativeMethodology}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

RubricScoring.displayName = 'RubricScoring';

export default withRouter(
  connect((state, props) => {
    return {
      event: getEventFromURL(state, props)?.toJS(),
      isQualitativeMode: getCurrentEventIsQualitativeMode(state, props),
      qualitativeMethodology: getCurrentEventQualitativeMethodology(state, props),
    };
  })(RubricScoring),
);
