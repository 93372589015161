import { Button, Modal } from '@valid-eval/shared-react-components';

const CommunicationsSendConfirmation = ({ show, onHide, onConfirm, sendTo = 'judges' }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please confirm you want to send this notification to the selected {sendTo}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={'link'} onClick={onHide} id="send-communication-cancel-button">
          Cancel
        </Button>
        <Button variant={'success'} onClick={onConfirm} id="send-communication-confirm-button">
          Send notification
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CommunicationsSendConfirmation;
