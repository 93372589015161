import { Button } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { isGovEnv } from 'config';
import { useSaving } from 'data/reducers/intros';
import { useState } from 'react';
import IntroDeleteConfirmation from './IntroDeleteConfirmation';
import IntroModalForm from './IntroModalForm';
import Styles from './Intros.module.scss';

const IntroControls = ({ onEditClick, onDeleteClick, isJudge, isOrganizer, ...intro }) => {
  const saving = useSaving();
  const [isConfirmOpened, setIsConfirmOpened] = useState(false);
  const [isEditOpened, setIsEditOpened] = useState(false);
  const toggleIsConfirmOpened = () => setIsConfirmOpened((isConfirmOpened) => !isConfirmOpened);
  const toggleIsEditOpened = () => setIsEditOpened((isEditOpened) => !isEditOpened);

  const handleConfirmation = () => onDeleteClick(toggleIsConfirmOpened);
  const handleEditClick = (values) => onEditClick(values.toJS(), toggleIsEditOpened);
  const judgeName = `${intro.judge.first_name} ${intro.judge.last_name}`;
  let judgeDescription = `${intro.judge.primary_job_function} ${intro.judge.business_discipline}`;
  judgeDescription = !isGovEnv()
    ? ` ${intro.judge.early_stage_investing_experience} ${intro.judge.fund_raising_experience}`
    : judgeDescription;

  return (
    <>
      <IntroDeleteConfirmation
        show={isConfirmOpened}
        onCancel={toggleIsConfirmOpened}
        onConfirmation={handleConfirmation}
      />
      <IntroModalForm
        form={`EditIntroForm${intro.id}`}
        show={isEditOpened}
        onCancel={toggleIsEditOpened}
        name={judgeName}
        description={judgeDescription}
        initialValues={intro}
        loading={saving}
        onSubmit={handleEditClick}
        {...intro}
      />
      <div className={cx('d-print-none', Styles.buttons)}>
        <Button
          name="edit"
          className={cx('d-print-none', Styles.button, { 'me-4': isJudge })}
          onClick={toggleIsEditOpened}
          variant="link"
        >
          <i className="fa-regular fa-pen-to-square" />
          <strong>Edit</strong>
        </Button>
        {isJudge && (
          <Button
            name="delete"
            variant="link"
            className={cx('d-print-none', Styles.buttonDelete)}
            onClick={toggleIsConfirmOpened}
          >
            <i className="fa-solid fa-trash-can" />
            <strong>Delete</strong>
          </Button>
        )}
      </div>
    </>
  );
};

IntroControls.propTypes = {};
IntroControls.displayName = 'IntroControls';
export default IntroControls;
