import GenericModal from 'components/GenericModal';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { load, updateManyRoleStatuses, updateRoleStatus } from 'data/actions/roleSummaries';
import {
  getActiveJudgeCount,
  getInactiveJudgeCount,
  getJudgeSummaries,
  getSelectedPhase,
  getSelectedPhaseIdForCurrentEvent,
  isLoadingJudgeRoles,
  isLoadingRoleSummaries,
} from 'data/reducers';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';
import { isNavigator, isTeamLead } from 'utils';
import { judgesCommunications } from 'utils/notifications';
import CommunicationsNotifyDropdown from './components/communications/CommunicationsNotifyDropdown';
import JudgesLayout from './components/JudgesLayout';
import { JudgesManageTable } from './components/JudgesManageTable';
import Loading from 'components/Loading';
import Triage from './components/Triage/Triage';

function JudgeManage({ params, selectedPhaseId, load, judges, judgesLoading, selectedPhase }) {
  const reloading = useRef(false);
  const [selectedJudges, setSelectedJudges] = useState([]);
  const [isEmptyJudgesModalOpened, setIsEmptyJudgesModalOpened] = useState(false);

  const handleEmptyJudgesClose = () => setIsEmptyJudgesModalOpened(false);

  const reloadJudges = () => load('judges', selectedPhaseId);

  useEffect(() => {
    reloading.current = false;
    reloadJudges();
  }, [selectedPhaseId]);

  return (
    <JudgesLayout
      toolbar={
        isNavigator() || isTeamLead() ? null : (
          <div className="text-end flex-grow-1 d-flex justify-content-end mb-3 me-3 align-items-center">
            <Triage entity={'judges'} selected={selectedJudges} reload={reloadJudges} />
            <CommunicationsNotifyDropdown
              afterSend={() => {
                setSelectedJudges([]);
                reloading.current = true;
                setTimeout(reloadJudges, 2e3);
              }}
              communications={judgesCommunications}
              extraData={{ judge_profile_ids: selectedJudges.map((j) => j.id) }}
              sendTo="judges"
              shouldSend={() => {
                const isValidSelection = Boolean(selectedJudges.length);
                if (!isValidSelection) setIsEmptyJudgesModalOpened(true);
                return isValidSelection;
              }}
            />
          </div>
        )
      }
    >
      {!isNavigator() && !isTeamLead() && (
        <GenericModal
          show={isEmptyJudgesModalOpened}
          confirmButton="Accept"
          title="No judges selected"
          body="Please select some judges from the table to perform the action"
          onConfirm={handleEmptyJudgesClose}
          onCancel={handleEmptyJudgesClose}
        ></GenericModal>
      )}
      {judgesLoading && !reloading.current ? (
        <Loading />
      ) : (
        <JudgesManageTable
          data={judges}
          eventId={params.event_id}
          judgesLoading={judgesLoading}
          selectedJudges={selectedJudges}
          onSelectedJudgesChange={setSelectedJudges}
          isPanelMatching={selectedPhase?.matching_mode === 'panel_matching'}
        />
      )}
    </JudgesLayout>
  );
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const judges = getJudgeSummaries(state, ownProps);
      const activeCount = getActiveJudgeCount(state, ownProps);
      const inactiveCount = getInactiveJudgeCount(state, ownProps);
      const selectedPhaseId = getSelectedPhaseIdForCurrentEvent(state, ownProps);
      const selectedPhase = getSelectedPhase(state, ownProps)?.toJS?.();
      return {
        loading: isLoadingRoleSummaries(state),
        judgesLoading: isLoadingJudgeRoles(state),
        judges,
        activeCount,
        inactiveCount,
        selectedPhaseId,
        selectedPhase,
      };
    },
    {
      load,
      updateRoleStatus,
      updateManyRoleStatuses,
      notifyError,
      notifySuccess,
    },
  )(JudgeManage),
);
