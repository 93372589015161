import { Col, Row } from '@valid-eval/shared-react-components';
import GenericModal from 'components/GenericModal';
import Loading from 'components/Loading';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { load as loadTeamDrafts } from 'data/actions/teamDrafts';
import {
  getEventFromURL,
  getSelectedPhaseId,
  getTeamDraftsFromCurrentEvent,
  getTeamDraftsIsLoading,
  getTeamsStatus,
} from 'data/reducers';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';
import { isNavigator } from 'utils';
import { teamProfileDraftsCommunications } from 'utils/notifications';
import { TeamDraftsTable } from './components/TeamDraftsTable';
import { TeamDraft as TeamDraftObject } from './components/TeamDraftsTable/components/types';
import CommunicationsNotifyDropdown from './components/communications/CommunicationsNotifyDropdown';

export type TeamDraftProps = {
  event: {
    id: string;
  };
  loadTeamDrafts(eventId: string): void;
  teamDrafts: TeamDraftObject[];
  isLoading: boolean;
  info: { pending_drafts: number };
};

const TeamDraft = (props: TeamDraftProps) => {
  const {
    event: { id: eventId },
    loadTeamDrafts,
    info,
  } = props;
  const reloading = useRef(false);
  const [selectedTeams, setSelectedTeams] = useState([] as TeamDraftObject[]);
  const [isEmptyTeamsModalOpened, setIsEmptyTeamsModalOpened] = useState(false);

  useEffect(() => {
    reloading.current = false;
    loadTeamDrafts(eventId);
  }, []);

  const handleEmptyTeamsClose = () => setIsEmptyTeamsModalOpened(false);
  return (
    <Row>
      <Col md={12}>
        <div className="d-flex align-items-center my-4">
          <h1 className="p-0 m-0">Drafts ({info?.pending_drafts})</h1>
          {isNavigator() ? null : (
            <div className="text-end flex-grow-1">
              <CommunicationsNotifyDropdown
                afterSend={() => {
                  setSelectedTeams([]);
                  reloading.current = true;
                  loadTeamDrafts(eventId);
                }}
                communications={teamProfileDraftsCommunications}
                extraData={{
                  drafts_ids: selectedTeams.map((j) => j.id),
                }}
                sendTo="teams"
                shouldSend={() => {
                  const isValidSelection = Boolean(selectedTeams.length);
                  if (!isValidSelection) {
                    setIsEmptyTeamsModalOpened(true);
                    return false;
                  }
                  return true;
                }}
              />
            </div>
          )}
          {!isNavigator() && (
            <>
              {/* @ts-ignore */}
              <GenericModal
                show={isEmptyTeamsModalOpened}
                confirmButton="Accept"
                title="No teams selected"
                body="Please select some teams from the table to perform the action"
                onConfirm={handleEmptyTeamsClose}
                onCancel={handleEmptyTeamsClose}
              />
            </>
          )}
        </div>
      </Col>
      {props.isLoading && <Loading text="" />}
      {!props.isLoading && (
        <TeamDraftsTable
          data={props.teamDrafts}
          selectedTeams={selectedTeams}
          onSelectedTeamsChange={setSelectedTeams}
        />
      )}
    </Row>
  );
};

export default withRouter(
  connect(
    (state, ownProps) => {
      // @ts-ignore
      const event = getEventFromURL(state, ownProps)?.toJS?.();
      // @ts-ignore
      const teamDrafts = getTeamDraftsFromCurrentEvent(state, ownProps);
      const isLoading = getTeamDraftsIsLoading(state);
      // @ts-ignore
      const selectedPhaseId = getSelectedPhaseId(state, ownProps.params.event_id);
      const info = getTeamsStatus(state, selectedPhaseId)?.toJS?.();
      return { event, teamDrafts, isLoading, info };
    },
    { notifyError, notifySuccess, loadTeamDrafts },
  )(TeamDraft),
);
