import * as api from 'data/services/notes';

import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';

import { RSAA } from 'redux-api-middleware';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const actions = {
  NOTES_RESET: 'notes/NOTES_RESET',
  LOAD_NOTES_PENDING: 'notes/LOAD_NOTES_PENDING',
  LOAD_NOTES_FULFILLED: 'notes/LOAD_NOTES_FULFILLED',
  LOAD_NOTES_REJECTED: 'notes/LOAD_NOTES_REJECTED',
  CREATE_NOTE_PENDING: 'notes/CREATE_NOTE_PENDING',
  CREATE_NOTE_FULFILLED: 'notes/CREATE_NOTE_FULFILLED',
  CREATE_NOTE_REJECTED: 'notes/CREATE_NOTE_REJECTED',
  UPDATE_NOTE_PENDING: 'notes/UPDATE_NOTE_PENDING',
  UPDATE_NOTE_FULFILLED: 'notes/UPDATE_NOTE_FULFILLED',
  UPDATE_NOTE_REJECTED: 'notes/UPDATE_NOTE_REJECTED',
  DELETE_NOTE_PENDING: 'notes/DELETE_NOTE_PENDING',
  DELETE_NOTE_FULFILLED: 'notes/DELETE_NOTE_FULFILLED',
  DELETE_NOTE_REJECTED: 'notes/DELETE_NOTE_REJECTED',
  VOTE_NOTE_PENDING: 'notes/VOTE_NOTE_PENDING',
  VOTE_NOTE_FULFILLED: 'notes/VOTE_NOTE_FULFILLED',
  VOTE_NOTE_REJECTED: 'notes/VOTE_NOTE_REJECTED',
  VIEW_EVALUATION_PENDING: 'notes/VIEW_EVALUATION_PENDING',
  VIEW_EVALUATION_FULFILLED: 'notes/VIEW_EVALUATION_FULFILLED',
  VIEW_EVALUATION_REJECTED: 'notes/VIEW_EVALUATION_REJECTED',
};

export const reset = () => ({ type: actions.NOTES_RESET });

export const loadNotes = (teamId, phaseId, judgeId, isOrganizer) => {
  return {
    [RSAA]: {
      ...api.load(teamId, phaseId, judgeId, isOrganizer),
      types: [
        actions.LOAD_NOTES_PENDING,
        {
          type: actions.LOAD_NOTES_FULFILLED,
          meta: {
            teamId,
            phaseId,
            judgeId,
            isOrganizer,
          },
        },
        actions.LOAD_NOTES_REJECTED,
      ],
    },
  };
};

export const createNote = (teamId, phaseId, { content, type, judgeId, role }) => {
  return {
    [RSAA]: {
      ...api.create(teamId, phaseId, { content, type, judgeId, role }),
      types: [
        actions.CREATE_NOTE_PENDING,
        actions.CREATE_NOTE_FULFILLED,
        actions.CREATE_NOTE_REJECTED,
      ],
    },
  };
};

export const updateNote = (teamId, phaseId, { id, content, type, judgeId, organizer }) => {
  return {
    [RSAA]: {
      ...api.update(teamId, phaseId, { id, content, type, judgeId, organizer }),
      types: [
        actions.UPDATE_NOTE_PENDING,
        actions.UPDATE_NOTE_FULFILLED,
        actions.UPDATE_NOTE_REJECTED,
      ],
    },
  };
};

export const deleteNote = (teamId, phaseId, id) => {
  return {
    [RSAA]: {
      ...api.del(teamId, phaseId, id),
      types: [
        actions.DELETE_NOTE_PENDING,
        actions.DELETE_NOTE_FULFILLED,
        actions.DELETE_NOTE_REJECTED,
      ],
    },
  };
};

export const voteNote = (teamId, phaseId, id) => {
  return {
    [RSAA]: {
      ...api.vote(teamId, phaseId, id),
      types: [actions.VOTE_NOTE_PENDING, actions.VOTE_NOTE_FULFILLED, actions.VOTE_NOTE_REJECTED],
    },
  };
};

export const evaluationViewed = (teamId, phaseId, id) => {
  return {
    [RSAA]: {
      ...api.evaluationViewed(teamId, phaseId, id),
      types: [
        actions.VIEW_EVALUATION_PENDING,
        actions.VIEW_EVALUATION_FULFILLED,
        actions.VIEW_EVALUATION_REJECTED,
      ],
    },
  };
};

export const useLoadNotes = (teamId, phaseId, judgeId, isOrganizer) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const { error } = await dispatch(loadNotes(teamId, phaseId, judgeId, isOrganizer));
      error && dispatch(notifyError(t('notes.error.load'), t('error')));
    })();
  }, [teamId, phaseId, judgeId, isOrganizer]);
};

export const useReloadNotes = (teamId, phaseId, judgeId, isOrganizer) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return async () => {
    const { error } = await dispatch(loadNotes(teamId, phaseId, judgeId, isOrganizer));
    error && dispatch(notifyError(t('notes.error.load'), t('error')));
  };
};

export const useCreateNote = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return async (teamId, phaseId, { content, type, judgeId, role }) => {
    const result = await dispatch(createNote(teamId, phaseId, { content, type, judgeId, role }));

    result.error &&
      dispatch(notifyError(result.payload?.response?.error || t('notes.error.save'), t('error')));
    !result.error && dispatch(notifySuccess(t('notes.success.save'), t('success')));
    return result;
  };
};

export const useUpdateNote = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return async (teamId, phaseId, { id, content, type, judgeId, organizer }) => {
    const result = await dispatch(
      updateNote(teamId, phaseId, { id, content, type, judgeId, organizer }),
    );

    result.error &&
      dispatch(notifyError(result.payload?.response?.error || t('notes.error.save'), t('error')));
    !result.error && dispatch(notifySuccess(t('notes.success.save'), t('success')));
    return result;
  };
};

export const useDeleteNote = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return async (teamId, phaseId, id) => {
    const result = await dispatch(deleteNote(teamId, phaseId, id));

    result.error &&
      dispatch(notifyError(result.payload?.response?.error || t('notes.error.delete'), t('error')));

    !result.error && dispatch(notifySuccess(t('notes.success.delete'), t('success')));
    return result;
  };
};

export const useVoteNote = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return async (teamId, phaseId, { id, value, judgeId }) => {
    const result = await dispatch(voteNote(teamId, phaseId, { id, value, judgeId }));

    result.error &&
      dispatch(notifyError(result.payload?.response?.error || t('notes.error.save'), t('error')));
    !result.error && dispatch(notifySuccess(t('notes.success.save'), t('success')));
    return result;
  };
};

export const useEvaluationViewed = () => {
  const dispatch = useDispatch();
  return (evaluationId) => dispatch(evaluationViewed(evaluationId));
};

export const useResetNotes = () => {
  const dispatch = useDispatch();
  return () => dispatch(reset());
};
