import { Button, ProgressBar } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useLayoutEffect, useState } from 'react';
import Styles from './ScoreInfo.module.scss';
import { getBucketFromScore } from './helpers';

const NoCellClicked = () => {
  return (
    <>
      <i className={cx(Styles.arrow, 'fas fa-arrow-left fa-lg')} />
      <section className={Styles.noStats}>Click a box that best describes this team</section>
      <section>
        <div className={Styles.squaresWrapper}>
          <div className={Styles.square} />
          <div className={Styles.square} />
          <div className={Styles.square} />
          <div className={Styles.square} />
        </div>
      </section>
    </>
  );
};

const NoScoresMade = ({ isQualitativeMode }) => {
  return (
    <>
      <i className={cx(Styles.arrow, 'fas fa-arrow-left fa-lg')} />
      <section className={Styles.stats}>
        Now click a {isQualitativeMode ? ' ' : ' numbered'} circle to score this dimension
      </section>
      <section className={isQualitativeMode ? Styles.scoringCirclesEmpty : Styles.scoringCircles} />
    </>
  );
};

const NumericFeedbackAvailable = ({
  allRegistered,
  scoreValue,
  scoresCount,
  totalScoresCount,
  disabledBtn,
  onContinue,
  clicksPercent,
  completePercent,
  isQualitativeMode,
  qualitativeMethodology,
}) => {
  const score = isQualitativeMode
    ? getBucketFromScore(scoreValue, qualitativeMethodology?.buckets)?.label
    : scoreValue?.toFixed?.(2);
  const header = allRegistered ? (
    <div>
      {isQualitativeMode ? (
        <>
          My Rating
          <span className={Styles.scoreValueLabel}>{score}</span>
        </>
      ) : (
        <>
          Total Score
          <span className={Styles.scoreValue}>{score}</span>
        </>
      )}
    </div>
  ) : (
    'Auto Saved'
  );
  const clickProgress = Math.round(clicksPercent * 100);
  const completeProgress = Math.round(completePercent * 100);
  return (
    <>
      <header>{header}</header>
      <section>
        <header>Scores</header>
        <ProgressBar className={Styles.progressBar} variant="success" now={completeProgress} />
        <div className={Styles.scores}>
          <span className={Styles.colored}>{scoresCount}</span> of {totalScoresCount}
        </div>
      </section>
      <section>
        <header>Feedback Clicks</header>
        <ProgressBar className={Styles.progressBar} variant="success" now={clickProgress} />
        <div className={Styles.scores}>
          <span className={Styles.colored}>{clickProgress}%</span>
        </div>
      </section>
      <Button
        className={cx({ disabled: disabledBtn })}
        variant="secondary"
        onClick={onContinue}
        disabled={disabledBtn}
        data-test-id="score-continue-button"
      >
        Continue
      </Button>
    </>
  );
};

const getFirstRubricDimensionSelected = () =>
  document.querySelectorAll('div[class*="ScorePicker__mark"][class*="available"]')[0];

const getFirstRubricDimensions = () =>
  document.querySelectorAll('div[class*="RubricScoring__dimensions"]')[0];

const getRubricInfoFirstTime = () =>
  document.querySelectorAll('div[class*="RubricScoring__infoContainer"]')[0];

const ScoreInfo = ({
  complete,
  scoresCount,
  totalScoresCount,
  criteriaCount,
  scoreValue,
  onContinue,
  clicksPercent,
  completePercent,
  isQualitativeMode,
  qualitativeMethodology,
}) => {
  const noCriterias = criteriaCount === 0;
  const noScoresSelected = scoresCount === 0;
  const [offset, setOffset] = useState(null);
  const [stuck, setStuck] = useState(false);

  useLayoutEffect(() => {
    let initMargin = 140;
    const el = getFirstRubricDimensionSelected();
    const rd = getFirstRubricDimensions();
    const ri = getRubricInfoFirstTime();

    ri?.classList?.add(Styles.initMargin);

    if (scoresCount === 0 && el) {
      let offset = window.pageYOffset + el.getBoundingClientRect().top - rd.offsetTop;
      setOffset(offset - initMargin);
      setStuck(true);
    } else {
      setOffset(null);
      setStuck(false);
      if (scoresCount > 0) ri?.classList?.remove(Styles.initMargin);
    }
  }, [scoresCount, criteriaCount]);

  useLayoutEffect(() => {
    const onScroll = () => {
      const el = getFirstRubricDimensionSelected();
      if (el && el.getBoundingClientRect().top > 0) {
        setStuck(true);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  let content = null;
  if (noCriterias) {
    content = <NoCellClicked />;
  } else if (noScoresSelected) {
    content = <NoScoresMade isQualitativeMode={isQualitativeMode} />;
  } else {
    content = (
      <NumericFeedbackAvailable
        allRegistered={complete}
        scoreValue={scoreValue}
        scoresCount={scoresCount}
        totalScoresCount={totalScoresCount}
        disabledBtn={!complete}
        onContinue={onContinue}
        clicksPercent={clicksPercent}
        completePercent={completePercent}
        isQualitativeMode={isQualitativeMode}
        qualitativeMethodology={qualitativeMethodology}
      />
    );
  }

  return (
    <div style={offset !== null && stuck ? { marginTop: offset } : {}} className={Styles.wrapper}>
      {content}
    </div>
  );
};

ScoreInfo.displayName = 'ScoreInfo';
export default ScoreInfo;
