import { Button } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import NoteDeleteControl from './NoteDeleteControl';
import Styles from './Notes.module.scss';

const NoteControls = ({
  commentsEnabled,
  downVotes,
  isOrganizer,
  isTeam,
  judgeId,
  onDownvote,
  onEdit,
  onUpvote,
  phaseId,
  teamId,
  upVotes,
  vote,
  ...note
}) => {
  const votingDisabled = isTeam || isOrganizer || note.isYou || !commentsEnabled;
  return (
    <div className={`${Styles.buttons} justify-content-between`}>
      <div className="d-flex">
        {/** UPVOTE */}
        {votingDisabled && upVotes > 0 && (
          <span className={cx(Styles.voteText)}>
            <i className="fa-solid fa-circle-arrow-up" /> <strong>{upVotes}</strong>
          </span>
        )}
        {!votingDisabled && (
          <Button
            name="up-vote"
            className={cx('me-2', Styles.button, { [Styles.buttonVoted]: vote === 1 })}
            variant="link"
            onClick={onUpvote}
          >
            <i className="fa-solid fa-circle-arrow-up" />
            <strong>{upVotes > 0 ? upVotes : 'Upvote'}</strong>
          </Button>
        )}
        {/** DOWNVOTE */}
        {votingDisabled && downVotes > 0 && (
          <span className={Styles.voteText}>
            <i className="fa-solid fa-circle-arrow-down" /> <strong>{downVotes}</strong>
          </span>
        )}
        {!votingDisabled && (
          <Button
            name="down-vote"
            className={cx(Styles.button, { [Styles.buttonVoted]: vote === -1 })}
            variant="link"
            onClick={onDownvote}
            disabled={votingDisabled}
          >
            <i className="fa-solid fa-circle-arrow-down" />
            <strong>{downVotes > 0 ? downVotes : 'Downvote'}</strong>
          </Button>
        )}
      </div>
      <div className="d-flex">
        {/** EDIT/DELETE */}
        {note.canEdit && commentsEnabled && (
          <Button
            name="edit"
            className={cx('d-print-none', Styles.button)}
            onClick={onEdit}
            variant="link"
          >
            <i className="fa-regular fa-pen-to-square" />
          </Button>
        )}
        {note.canDelete && commentsEnabled && (
          <NoteDeleteControl
            id={note.id}
            phaseId={phaseId}
            teamId={teamId}
            isOrganizer={isOrganizer}
            judgeId={judgeId}
          />
        )}
      </div>
    </div>
  );
};

NoteControls.displayName = 'NoteControls';
export default NoteControls;
