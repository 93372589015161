import { Button, Form, ListGroup, ListGroupItem } from '@valid-eval/shared-react-components';
/* eslint no-mixed-operators: 0 */
import { useEffect, useRef, useState } from 'react';
import { change, formValueSelector } from 'redux-form/immutable';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { useDispatch, useSelector } from 'react-redux';

import AddEmailModal from './AddEmailModal';
import ConfirmPrimaryEmailModal from './ConfirmPrimaryEmailModal';
import ConfirmRemoveEmailModal from './ConfirmRemoveEmailModal';
import classes from './Emails.module.scss';
import cx from 'classnames';
import { getCurrentUserId } from 'data/reducers';
import { save } from 'data/actions/users';

const Emails = ({ fields: emails }) => {
  const values = useSelector((state) => formValueSelector('profileForm')(state, 'user_emails'));
  const userId = useSelector(getCurrentUserId);
  const dispatch = useDispatch();
  const shouldUpdateEmails = useRef(false);
  const [isAddModalOpened, setIsAddModalOpened] = useState(false);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false);
  const [isPrimaryModalOpened, setIsPrimaryModalOpened] = useState(false);
  const [activeEmailIndex, setActiveEmailIndex] = useState(null);
  const handleAddClick = () => {
    shouldUpdateEmails.current = false;
    setIsAddModalOpened(true);
  };
  const handleCancelAdd = () => {
    shouldUpdateEmails.current = true;
    setIsAddModalOpened(false);
  };

  useEffect(() => {
    if (shouldUpdateEmails.current) {
      shouldUpdateEmails.current = false;
      (async () => {
        const { error } = await dispatch(save({ id: userId, user_emails: values?.toJS() }));
        if (error) dispatch(notifyError('Failed to save, please try again', 'Error'));
        else dispatch(notifySuccess('All changes saved', 'Success'));
      })();
    } else {
      shouldUpdateEmails.current = true;
    }
  }, [values]);

  const makeHandlePrimaryClick = (index) => () => {
    setActiveEmailIndex(index);
    setIsPrimaryModalOpened(true);
  };

  const handlePrimaryCancel = () => {
    setActiveEmailIndex(null);
    setIsPrimaryModalOpened(false);
  };

  const handleConfirmPrimary = () => {
    dispatch(change('profileForm', `user_emails[${activeEmailIndex}].primary_email`, true));
    setActiveEmailIndex(null);
    setIsPrimaryModalOpened(false);
  };

  const makeHandleRemoveClick = (index) => () => {
    if (emails.length > 1) {
      setActiveEmailIndex(index);
      setIsRemoveModalOpened(true);
    } else {
      alert('Your profile must have at least one email address');
    }
  };

  const handleRemoveCancel = () => {
    setActiveEmailIndex(null);
    setIsRemoveModalOpened(false);
  };

  const handleConfirmRemove = () => {
    const wasPrimary = emails.get(activeEmailIndex).get('primary_email');
    emails.remove(activeEmailIndex);
    wasPrimary && dispatch(change('profileForm', `user_emails[0].primary_email`, true));
    setActiveEmailIndex(null);
    setIsRemoveModalOpened(false);
  };

  return (
    <>
      <AddEmailModal show={isAddModalOpened} onCancel={handleCancelAdd} />
      <ConfirmRemoveEmailModal
        show={isRemoveModalOpened}
        onCancel={handleRemoveCancel}
        onConfirm={handleConfirmRemove}
        emails={emails}
        index={activeEmailIndex}
      />
      <ConfirmPrimaryEmailModal
        show={isPrimaryModalOpened}
        onCancel={handlePrimaryCancel}
        onConfirm={handleConfirmPrimary}
        emails={emails}
        index={activeEmailIndex}
      />
      <Form.Group>
        <label>
          <h2>Emails</h2>
        </label>
        <div className="clearfix">
          <ListGroup>
            {values?.map((email, index) => {
              return (
                <ListGroupItem key={email.get('email')}>
                  <div className={cx('clearfix', classes.email)}>
                    {email.get('email')}
                    <Button
                      variant="link"
                      className={cx('float-end', classes.noPadding, classes.delete)}
                      disabled={values.size <= 1}
                      onClick={makeHandleRemoveClick(index)}
                      data-remove={email.get('email')}
                    >
                      <i className={'fas fa-trash-alt'} />
                      Delete
                    </Button>
                  </div>
                  {email.get('primary_email') && (
                    <div className={cx(classes.primary, classes.noPadding)}>
                      <strong className={cx(classes.content, 'text-success')}>Primary email</strong>
                    </div>
                  )}
                  {!email.get('primary_email') && (
                    <Button
                      variant="link"
                      className={classes.noPadding}
                      onClick={makeHandlePrimaryClick(index)}
                      data-set-primary={email.get('email')}
                    >
                      Set as primary email
                    </Button>
                  )}
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </div>
        <Button
          className={cx(classes.btnEmail, classes.marginbtn)}
          id="add-email"
          onClick={handleAddClick}
        >
          <i className="fa-solid fa-plus"></i> Add email address
        </Button>
      </Form.Group>
    </>
  );
};

Emails.displayName = 'Emails';

export default Emails;
