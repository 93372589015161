import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { Overlay, Popover } from 'react-bootstrap';
import Styles from './Heat.module.scss';

interface SubDimensionCellProps {
  appPrompt: string;
  name: string;
}

const SubDimensionCell: React.FC<SubDimensionCellProps> = ({ appPrompt, name }) => {
  const [show, setShow] = useState(false);
  const target = useRef<HTMLSpanElement>(null);

  const handleClickOutside = () => setShow(false);

  const handleIconClick = () => setShow(!show);

  const shouldDisplayIcon = appPrompt && appPrompt.trim() !== '';

  return (
    <td className={Styles.subdimensionCell}>
      <div className={cx(Styles.subDimensionContainer, Styles.boldLabel)}>
        <span>{name}</span>
        {shouldDisplayIcon && (
          <i
            className={cx(
              'fa-regular fa-circle-question fa-md',
              Styles.questionCircleIcon,
              Styles.cursorOnHover,
            )}
            ref={target}
            onClick={handleIconClick}
          />
        )}
        {shouldDisplayIcon && (
          <Overlay
            target={target.current}
            show={show}
            placement="right"
            rootClose={true}
            onHide={handleClickOutside}
          >
            {(props) => (
              <Popover id="overlay-popover" {...props} arrowProps={{ style: { display: 'none' } }}>
                <Popover.Body className={Styles.popoverBody}>
                  <div className={Styles.popoverContent}>
                    <div className={Styles.popoverText}>{appPrompt}</div>
                    <div className={Styles.popoverClose} onClick={handleClickOutside}>
                      <i className="fas fa-times fa-lg"></i>
                    </div>
                  </div>
                </Popover.Body>
              </Popover>
            )}
          </Overlay>
        )}
      </div>
    </td>
  );
};

export default SubDimensionCell;
