import { useRef } from 'react';
import { getFormValues, isValid, reduxForm } from 'redux-form/immutable';
import { useReloadNotes, useUpdateNote } from 'data/actions/notes';

import { Form } from '@valid-eval/shared-react-components';
import NoteFormControls from './NoteFormControls';
import NoteFormInput from './NoteFormInput';
import { trimCommentLineBreaks } from './helpers';
import { useSelector } from 'react-redux';
import { isTeamLead } from 'utils';

const formName = 'NoteEdit';

const NoteEdit = ({
  handleSubmit,
  id,
  isOrganizer,
  judgeId,
  onCancel,
  phaseId,
  teamId,
  commentsEnabled,
  viewed,
  ...note
}) => {
  const formRef = useRef(null);
  const updateNote = useUpdateNote();
  const reloadNotes = useReloadNotes(teamId, phaseId, judgeId, isOrganizer);
  const values = useSelector(getFormValues(formName));
  const valid = useSelector(isValid(formName));

  async function onSubmit(values) {
    const { error } = await updateNote(teamId, phaseId, {
      id,
      content: trimCommentLineBreaks(values.get('content')),
      type: values.get('internal') ? 'internal' : 'shared',
      organizer: isOrganizer ? 1 : undefined,
      judgeId: judgeId || undefined,
    });
    if (!error) onCancel();
    reloadNotes();
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <NoteFormInput internal={values?.get('internal')} disabled={isTeamLead() && !note.isYou} />
        <NoteFormControls
          edit={true}
          id={id}
          isOrganizer={isOrganizer}
          viewed={viewed}
          judgeId={judgeId}
          onCancel={onCancel}
          phaseId={phaseId}
          teamId={teamId}
          valid={valid}
          {...note}
        />
      </Form>
    </>
  );
};

export default reduxForm({ form: formName, enableReinitialize: true })(NoteEdit);
