import { Col, Row, Tab, TabsGroup } from '@valid-eval/shared-react-components';
import GlobalReports from './components/Reports/GlobalReports';
import PhaseReports from './components/Reports/PhaseReports';

const Reports = () => {
  return (
    <>
      <Row>
        <Col xs={10}>
          <h1>Reports</h1>
          <p>
            Below is a list of useful reports. Clicking these link will create and download an
            .xls-formatted spreadsheet file. These files will land in your “Downloads” folder. Some
            browsers will display the file(s) at the bottom of your browser window.
          </p>
        </Col>
      </Row>

      <TabsGroup className="mb-4" mountOnEnter unmountOnExit>
        <Tab eventKey="global" title="Global Reports">
          <GlobalReports />
        </Tab>
        <Tab eventKey="phase" title="Phase Reports">
          <PhaseReports />
        </Tab>
      </TabsGroup>
    </>
  );
};

export default Reports;
