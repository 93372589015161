import { Button, Modal } from '@valid-eval/shared-react-components';

const GenericModal = ({
  show = false,
  onCancel,
  onConfirm,
  name,
  title,
  body,
  cancelButton = 'Cancel',
  confirmButton = 'Confirm',
  className = '',
  size = 'lg',
  isButtonDisabled = false,
  isCancelButtonDisabled = false,
  fullscreen = '',
  ...props
}) => {
  const { footerExtraContent } = props;
  return (
    <Modal
      id={`${name}-modal`}
      show={show}
      onHide={onCancel}
      className={className}
      size={size}
      fullscreen={fullscreen}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {(cancelButton || confirmButton || footerExtraContent) && (
        <Modal.Footer>
          {footerExtraContent ? footerExtraContent : null}
          {cancelButton && (
            <Button
              id={`${name}-modal-cancel`}
              onClick={onCancel}
              variant={'link'}
              disabled={isCancelButtonDisabled}
            >
              {cancelButton}
            </Button>
          )}
          {confirmButton && (
            <Button
              type={'submit'}
              variant={'success'}
              id={`${name}-modal-confirm`}
              onClick={onConfirm}
              disabled={isButtonDisabled}
            >
              {confirmButton}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default GenericModal;
