import { Button } from '@valid-eval/shared-react-components';
import copy from 'copy-to-clipboard';
import { useDeleteIntro, useUpdateIntro, useViewIntro } from 'data/actions/intros';
import { useViewed } from 'data/reducers/intros';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import IntroControls from './components/IntroControls';
import Styles from './components/Intros.module.scss';

const Intro = ({ isTeam, isJudge, isOrganizer, ...intro }) => {
  const copiedTimeout = useRef(null);
  const [copied, setCopied] = useState(false);
  const createdAt = moment(intro.createdAt);
  const isViewed = useViewed();
  const updateIntro = useUpdateIntro();
  const deleteIntro = useDeleteIntro();
  const viewIntro = useViewIntro();

  useEffect(() => () => clearTimeout(copiedTimeout));

  const handleDelete = async (closeModal) => {
    const { error } = await deleteIntro(intro.teamId, intro.phaseId, intro.id);
    if (!error) closeModal();
  };

  const handleEdit = async (values, closeModal) => {
    const { error } = await updateIntro(intro.teamId, intro.phaseId, {
      ...values,
      judgeId: isJudge ? intro.judge.id : undefined,
      organizer: isOrganizer ? 1 : undefined,
    });
    if (!error) closeModal();
  };

  const handleView = async () => {
    await viewIntro(intro.teamId, intro.phaseId, intro.id);
    copy(intro.email);
    setCopied(true);
    copiedTimeout.current = setTimeout(() => setCopied(false), 2000);
  };

  return (
    <li id={`intro-${intro.id}`} className={Styles.introWrapper} role="article">
      <div className={Styles.intro}>
        <div className={Styles.content}>
          <div className="d-flex flex-row align-items-center justify-content-between mb-3">
            <strong className={Styles.introLabel}>INTRO</strong>
            <div className={Styles.handshake}>
              <i className="fa-solid fa-handshake"></i>
            </div>
          </div>
          <div className={Styles.contentTop}>
            <div className={Styles.contentTopJudgeInfo}>
              <strong>
                {intro.judge.first_name} {intro.judge.last_name}
                {intro.isYou && ' (this is you)'}:
              </strong>
              {/* <div className="mt-1">
                <p>
                  {intro.judge.primary_job_function}
                  {', '}
                  {intro.judge.business_discipline}
                  {!isGovEnv() && (
                    <>
                      {', '}
                      {intro.judge.early_stage_investing_experience}
                      {', '}
                      {intro.judge.fund_raising_experience}
                    </>
                  )}
                </p>
              </div> RE-ENABLE THIS UNDER VEP-547 */}
            </div>
            <div className={Styles.contentTopEmail}>
              {(isOrganizer || (isJudge && intro.isYou)) && (
                <>
                  <strong>Email</strong>
                  <div className="mt-1">
                    <a href={`mailto:${intro.email}`}>{intro.email}</a>
                  </div>
                </>
              )}

              {isTeam && (
                <>
                  <strong>Email</strong>
                  <br />
                  {!intro.viewed && (
                    <Button name={'view-intro'} variant={'success'} onClick={handleView}>
                      SHOW AND COPY TO THE CLIPBOARD
                    </Button>
                  )}
                  {intro.viewed && (
                    <>
                      <a href={`mailto:${intro.email}`}>
                        <strong>{intro.email}</strong>
                      </a>
                      {copied && (
                        <>
                          <br />
                          <br />
                          <span className={Styles.copiedLabel}>COPIED!</span>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <small className={Styles.introDate}>{createdAt.format('L [at] h:mm a')}</small>
          </div>
          {intro.isYou || isOrganizer || isTeam ? (
            <div className={Styles.introBody} dangerouslySetInnerHTML={{ __html: intro.content }} />
          ) : (
            <div className={Styles.introBody}>
              <em>Introduction details viewable to Selection Committee</em>
            </div>
          )}
          {intro.canEdit && (isJudge || isOrganizer) && !isViewed && (
            <IntroControls
              onDeleteClick={handleDelete}
              onEditClick={handleEdit}
              isJudge={isJudge}
              isOrganizer={isOrganizer}
              {...intro}
            />
          )}
        </div>
      </div>
    </li>
  );
};

Intro.propTypes = {};
Intro.displayName = 'Intro';
export default Intro;
