import { useState } from 'react';
import Intro from '../../Intros/Intro';
import Note from './Note';
import NoteEditConfirmation from './NoteEditConfirmation';
import Styles from './Notes.module.scss';
import NotesListEmpty from './NotesListEmpty';

const useIsUnredacted = (isJudge) => {
  return window.location.href.includes('unredacted') || isJudge;
};

const NotesList = ({
  internalCommentsEnabled,
  commentsEnabled,
  isJudge,
  isOrganizer,
  isTeam,
  judgeId,
  notes,
  phaseId,
  teamId,
  viewed,
}) => {
  const isUnredacted = useIsUnredacted(isJudge);
  const [editingId, setEditingId] = useState(null);
  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);

  const handleCancelEdit = () => {
    setEditingId(null);
    setIsConfirmationOpened(false);
  };

  const handleConfirmEdit = () => {
    setIsConfirmationOpened(false);
  };

  const makeHandleEdit = (note) => () => {
    setEditingId(null);
    if (note.downVotes || note.upVotes) {
      setIsConfirmationOpened(true);
    }
    setEditingId(note.id);
  };
  const handleCancel = () => setEditingId(null);

  const filteredNotes = notes.filter((note) => (!isUnredacted ? note.type !== 'internal' : true));

  return (
    <section>
      <NoteEditConfirmation
        show={isConfirmationOpened}
        onCancel={handleCancelEdit}
        onConfirmation={handleConfirmEdit}
      />
      <h3 className={Styles.listLabel}>Comments</h3>
      {notes?.length ? (
        <ol className={Styles.list}>
          {filteredNotes.map((record) =>
            record.isIntro ? (
              <Intro
                key={record.id}
                isJudge={isJudge}
                isOrganizer={isOrganizer}
                isTeam={isTeam}
                {...record}
              />
            ) : (
              <Note
                isUnredacted={isUnredacted}
                commentsEnabled={
                  record.type === 'internal' ? internalCommentsEnabled : commentsEnabled
                }
                viewed={viewed}
                edit={record.id === editingId}
                isJudge={isJudge}
                isOrganizer={isOrganizer}
                isTeam={isTeam}
                judgeId={judgeId}
                key={record.id}
                onCancel={handleCancel}
                onEdit={makeHandleEdit(record)}
                phaseId={phaseId}
                teamId={teamId}
                {...record}
              />
            ),
          )}
        </ol>
      ) : (
        <NotesListEmpty />
      )}
    </section>
  );
};

NotesList.displayName = 'NotesList';
export default NotesList;
