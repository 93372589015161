import { useState } from 'react';
import { useDeleteNote, useReloadNotes } from 'data/actions/notes';

import { Button } from '@valid-eval/shared-react-components';
import NoteDeleteConfirmation from './NoteDeleteConfirmation';
import Styles from './Notes.module.scss';
import cx from 'classnames';

const NoteDeleteControl = ({ disabled, id, phaseId, teamId, judgeId, isOrganizer }) => {
  const reloadNotes = useReloadNotes(teamId, phaseId, judgeId, isOrganizer);
  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);
  const deleteNote = useDeleteNote();
  const handleCancel = () => setIsConfirmationOpened(false);
  const handleOpen = () => setIsConfirmationOpened(true);

  function handleConfirmation() {
    deleteNote(teamId, phaseId, id);
    reloadNotes();
    setIsConfirmationOpened(false);
  }

  return (
    <>
      <NoteDeleteConfirmation
        show={isConfirmationOpened}
        onCancel={handleCancel}
        onConfirmation={handleConfirmation}
      />
      <Button
        name="delete"
        variant="link"
        className={cx('d-print-none', Styles.buttonDelete)}
        disabled={disabled}
        onClick={handleOpen}
      >
        <i className="fa-regular fa-trash-can"></i>
      </Button>
    </>
  );
};

NoteDeleteControl.displayName = 'NoteDeleteControl';
export default NoteDeleteControl;
